/* eslint-disable camelcase */
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useGetSpecialtyListQuery } from 'src/api/createFeed'
import { Speciality } from 'src/api/createFeed/types'
import { Specialty } from 'src/api/feed/types'
import SearchBar from 'src/Component/SearchBar'
import Header from 'src/Layout/HomeLayout/Header'
import SpecialityModal from 'src/Page/Home/FilterModal'
import PublicFeed from 'src/Page/Home/PublicFeed'
import styles from './index.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { setSelectedSpecialitiesFilter, setSortBy } from 'src/store/features/common/commonSlice';

const Home = () => {

  const [searchValue, setSearchValue] = useState<string>('');

  const [selectedSpecialities, setSelectedSpecialities] = useState<Specialty[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { selectedSpecialitiesFilter, sortBy } = useSelector((state: RootState) => state.common);


  const { data: fetchedSpecialities = [], isLoading, isError } = useGetSpecialtyListQuery('');

  useEffect(() => {
    if (!isLoading && !isError) {
      setSelectedSpecialities(fetchedSpecialities);
    }
  }, [fetchedSpecialities, isLoading, isError]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleApply = (specialities: Speciality[], sortByFilter: string) => {
    dispatch(setSelectedSpecialitiesFilter(specialities));
    dispatch(setSortBy(sortByFilter));
  };





  const tabs = ['Public', 'Private'];

  const [selectedFocusArea, setSelectedFocusArea] = useState([]);
  useEffect(() => {
    const focusArea = selectedSpecialitiesFilter.flatMap(speciality => speciality.sub_specialities);
    setSelectedFocusArea(focusArea);
  }, [selectedSpecialitiesFilter]);

  const filterBoxRef = useRef(null);
  const [filterBoxHeight, setFilterBoxHeight] = useState(0);

  useEffect(() => {
    if (filterBoxRef.current) {
      setFilterBoxHeight(filterBoxRef.current.offsetHeight);
    }else{
      setFilterBoxHeight(0);
    }
  }, [selectedSpecialitiesFilter, selectedFocusArea]);

  return (
    <Box>
      <Header>
        <Box width="100%" p={3} pb={0}>
          <Grid container justifyContent="center">
            <Box className={styles.wrapper}>
              <SearchBar
                isFilterApplied={selectedSpecialitiesFilter.length > 0 || sortBy.length > 0 && sortBy !== 'most_recent'}
                onChangeWithDebounce={setSearchValue}
                onFilterClick={handleOpenModal}
              />
            </Box>
          </Grid>
        </Box>
        <Box width="100%" pr={3} pl={3}>
          <Box mt={1}>
            <Typography variant="subtitle1" fontSize="24px">
              Feed
            </Typography>
          </Box>

          <SpecialityModal
            open={modalOpen}
            onClose={handleCloseModal}
            onApply={handleApply}
            specialities={selectedSpecialities}
            appliedSpecialty={selectedSpecialitiesFilter}
            appliedSortBy={sortBy}
          />
        </Box>
      </Header>
      <Box>
        <Grid container mt={4} justifyContent="center">
          {selectedSpecialitiesFilter.length > 0 && (
            <Box
              ref={filterBoxRef}
              className={styles.wrapper}
              sx={{
                border: 'solid 1px white',
                position: 'fixed',
                backgroundColor: 'gray',
                zIndex: 10,
                display: 'flex',
                padding: 0.5,
                flexDirection: 'column',
                maxHeight: 150,
                overflowY: 'auto',
              }}
            >
              <Typography variant="subtitle1">
                <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{'Subspecialty Channel: '}</span>
                <span style={{ fontWeight: 600 }}>
                  {selectedSpecialitiesFilter.map(speciality => {
                    return <a key={speciality.id}> #{speciality.name} </a>;
                  })}
                </span>
              </Typography>
              {selectedFocusArea.length > 0 && (
                <Typography variant="subtitle1">
                  <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{'Focus Area: '}</span>
                  <span style={{ fontWeight: 600 }}>
                    {selectedFocusArea.map(sub => (
                      <a key={sub.id}> #{sub.name} </a>
                    ))}
                  </span>
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ marginTop: `${filterBoxHeight}px` }} className={styles.wrapper}>
              <PublicFeed
                // key={JSON.stringify(selectedSpecialitiesFilter + sortBy)}
                searchValue={searchValue}
                specialities={selectedSpecialitiesFilter}
                sortBy={sortBy}
              />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default Home
