import { useState } from 'react';
import styles from './index.module.scss';
import { Box, Checkbox, Typography } from '@mui/material';
const CustomItem = ({ item, onParentClick, selectedValue, isMultiple, setter }) => {
  const [open, setOpen] = useState(false);
  const isItemInSelected = selectedValue.find(selectedItem => selectedItem.value == item.value);
  let isSelected = false;
  if (isItemInSelected) {
    item.subspecialtyList = isItemInSelected.subspecialtyList;
    isSelected = true;
  }

  const focusClick = (focusItem: any) => {
    let temp = [...selectedValue];
    const selectedItem = temp.find(x => x.value == item.value);
    if (selectedItem) {
      const updatedSubspecialtyList = selectedItem.subspecialtyList.map((subItem: any) =>
        subItem.id == focusItem.id ? { ...subItem, selected: !subItem.selected } : subItem,
      );

      const updatedSelectedItem = { ...selectedItem, subspecialtyList: updatedSubspecialtyList };

      if (!updatedSubspecialtyList.some((x: any) => x.selected)) {
        temp = temp.filter(x => x.value !== item.value);
      } else {
        const existingIndex = temp.findIndex(x => x.value == item.value);
        if (existingIndex !== -1) {
          temp[existingIndex] = updatedSelectedItem;
        } else {
          temp.push(updatedSelectedItem);
        }
      }
    } else {
      const updatedSubspecialtyList = item.subspecialtyList.map(subItem =>
        subItem.id === focusItem.id ? { ...subItem, selected: !subItem.selected } : subItem,
      );
      const updatedItem = { ...item, subspecialtyList: updatedSubspecialtyList };

      temp.push(updatedItem);
    }
    setter(temp);
  };

  return (
    <Box>
      <div
        onClick={event => {
          onParentClick(item);
          setOpen(!open);
          event.stopPropagation();
        }}
        className={styles.itemFirstRow}
      >
        {isMultiple && (
          <Checkbox size="medium" color="success" checked={isSelected} onChange={() => onParentClick(item)} />
        )}
        <Typography  sx={{color:'white', fontSize:'1rem'}} className={styles.itemCount}>
          {item?.label}
        </Typography>
      </div>
      {item.haveSubspecialty && open && !isSelected && (
        <div className={styles.subspecialtyInfo}>
          <svg width={14} height={14} className={styles.marginHorizontal}>
            {/* Add your SVG content here */}
          </svg>
          <Typography  fontWeight={700} color="gray"variant='subtitle1'>Must select specialty-focus for this sub-specialty</Typography>
        </div>
      )}
      {item.haveSubspecialty && open && (
        <Box sx={{ overflowY: 'scroll', maxHeight: 150, width: '100%', scrollbarWidth:'none', '&::-webkit-scrollbar': {
        display: 'none', /* For Chrome, Safari, and Opera */
        boxShadow: 'inset 0px 4px 6px rgba(0, 0, 0, 0.1)', 
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: 1,
      },}}>
          {item.subspecialtyList.map((subItem, index) => {
            return (
              <Box key={subItem.id} onClick={() => focusClick(subItem)} className={styles.subItemContainer}>
                <Checkbox
                  size="small"
                  color="success"
                  checked={subItem.selected}
                  onChange={() => focusClick(subItem)}
                />

                <Typography variant='subtitle2' color={'white'} fontWeight={400} onClick={() => focusClick(subItem)}>
                  {subItem.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default CustomItem;
