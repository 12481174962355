import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Checkbox, ListItemText } from '@mui/material'
type DropdownProps = {
  value: any
  onChange: (value: any) => void
  options: {
    value: any
    label: string
  }[]
  label: string
  required?: boolean
  multiple?: boolean
  disabled?: boolean
}
const Dropdown = ({
  value,
  onChange,
  options,
  label,
  required = false,
  multiple = false,
  disabled = false,
}: DropdownProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value)
  }

  const getMenuItmes = () => {
    const menuItems = []

    menuItems.push(
      <MenuItem value='' disabled>
        <em>{label}</em>
      </MenuItem>,
    )
    if (multiple) {
      options.forEach((option, i: number) => {
        menuItems.push(
          <MenuItem key={i} value={option.value}>
            <Checkbox color='success' checked={!!value.find((v: any) => v === option.value)} />
            {/* <ListItemText primary={option.label} /> */}
            {option.label}
          </MenuItem>,
        )
      })
    } else {
      options.forEach((option, i: number) => {
        menuItems.push(
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>,
        )
      })
    }

    return menuItems
  }
  return (
    <FormControl fullWidth>
      <Select
        SelectDisplayProps={{
          style: {
            color: !value || !value.length ? '#959AA9' : 'white',
          },
        }}
        value={value}
        onChange={handleChange}
        displayEmpty
        required={required}
        multiple={multiple}
        disabled={disabled}
        renderValue={(selected: any) => {
          if (multiple) {
            if (!selected.length) {
              return label
            }
            const selectedOption = options.filter((o) => {
              return !!selected.find((s: any) => s === o.value)
            })
            return selectedOption.map((op) => op.label).join(', ')
          }

          if (!selected) {
            return label
          }
          return options.find((op) => op.value === selected)?.label
        }}
      >
        {getMenuItmes()}
      </Select>
    </FormControl>
  )
}

export default Dropdown
