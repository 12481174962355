import React from 'react'
type IconProps = {
  color?: string
}
const MySavedFeedsIcon = ({ color = '#909090' }: IconProps) => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.57907 25C9.48021 25 9.38125 24.9747 9.29234 24.924C9.11164 24.821 9 24.629 9 24.4209V7.57907C9 7.25928 9.25923 7 9.57902 7H22.8224C23.1422 7 23.4014 7.25923 23.4014 7.57907V24.4209C23.4014 24.629 23.2898 24.8211 23.109 24.9241C22.9282 25.027 22.706 25.0251 22.527 24.919L16.2046 21.1692L9.8741 24.9191C9.78326 24.973 9.68119 25 9.57907 25ZM10.1581 8.15809V23.4049L15.9097 19.9979C16.0918 19.89 16.3182 19.8901 16.5002 19.998L22.2434 23.4043V8.15809H10.1581Z'
        fill={color}
      />
    </svg>
  )
}

export default MySavedFeedsIcon
