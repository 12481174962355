import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH, PER_PAGE } from 'src/utils/constants'
import { FeedResponse, FeedResponseData, PaginationPayload } from '../feed/types'
import {
  ConnectionRequestActionPayload,
  ConnectionRequestI,
  ConnectionRequestReceivedResposne,
  GetUserFeedsByIdPayload,
  SettingsI,
  SettingsResponse,
  UserProfileI,
  UserProfileResponse,
  myConnection,
  myConnectionListResponse,
} from './types'

const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['UserProfile'],
  endpoints: (builder) => ({
    getUserProfileById: builder.query<UserProfileI, number>({
      query: (userId) => ({
        url: `/api/v1/profile/get/?user_id=${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: UserProfileResponse) => {
        return response.data
      },
    }),

    getUserFeedsById: builder.query<FeedResponseData, GetUserFeedsByIdPayload>({
      query: ({userId,paginationPayload }) => {
        const { page, perPage = PER_PAGE, search = '' } = paginationPayload
        return {
          url: `/api/v1/feed/my_feed/?user_id=${userId}&page_size=${perPage}&page=${page}&search=${search}`,
          method: 'GET',
        }
      },
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),

    getConnectionRequest: builder.query<ConnectionRequestI[], void>({
      query: () => ({
        url: '/api/v1/connectionrequest/received/',
        method: 'GET',
      }),
      transformResponse: (response: ConnectionRequestReceivedResposne) => {
        return response.data
      },
    }),

    acceptConnectionRequest: builder.mutation<void, ConnectionRequestActionPayload>({
      query: ({ requestId, toUserId }) => ({
        url: `/api/v1/connectionrequest/${requestId}/accept/`,
        method: 'PATCH',
        data: {
          // eslint-disable-next-line camelcase
          to_user: toUserId,
        },
      }),
    }),

    rejectConnectionRequest: builder.mutation<void, ConnectionRequestActionPayload>({
      query: ({ requestId, toUserId }) => ({
        url: `/api/v1/connectionrequest/${requestId}/reject/`,
        method: 'DELETE',
        data: {
          // eslint-disable-next-line camelcase
          to_user: toUserId,
        },
      }),
    }),

    sendConnectionRequest: builder.mutation<void, number>({
      query: (toUserId) => ({
        url: '/api/v1/connectionrequest/send/',
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          to_user: toUserId,
        },
      }),
    }),

    getMyConnections: builder.query<myConnection[], void>({
      query: () => ({
        url: '/api/v1/connectionrequest/my_connections/',
        method: 'GET',
      }),
      transformResponse: (response: myConnectionListResponse) => {
        return response.data
      },
    }),

    getMySavedFeeds: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '' }) => ({
        url: `/api/v1/feed/all_saved_feed/?page_size=${perPage}&page=${page}&search=${search}`,
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),

    updateUserProfile: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: '/api/v1/profile/edit/',
        method: 'PATCH',
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    deleteUserProfile: builder.mutation<void, void>({
      query: () => ({
        url: '/api/v1/profile/delete/',
        method: 'DELETE',
      }),
    }),

    getSettings: builder.query<SettingsI, void>({
      query: () => ({
        url: '/modules/firebase-push-notifications/settings/get_preference/',
        method: 'GET',
      }),
      transformResponse: (response: SettingsResponse) => {
        return response.data
      },
    }),

    saveSettings: builder.mutation<void, SettingsI>({
      query: (payload) => ({
        url: '/modules/firebase-push-notifications/settings/update_preference/',
        method: 'PATCH',
        data: payload,
      }),
    }),

    getUserLikedFeeds: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '' }) => ({
        url: `/api/v1/liked-posts/?page_size=${perPage}&page=${page}&search=${search}`,
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),
  }),
})

export const {
  useGetUserProfileByIdQuery,
  useLazyGetUserFeedsByIdQuery,
  useGetConnectionRequestQuery,
  useLazyGetMySavedFeedsQuery,
  useAcceptConnectionRequestMutation,
  useRejectConnectionRequestMutation,
  useSendConnectionRequestMutation,
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
  useGetSettingsQuery,
  useSaveSettingsMutation,
  useLazyGetUserLikedFeedsQuery,
  useGetMyConnectionsQuery,
} = userProfileApi
export default userProfileApi
