import React, { useRef, useState } from 'react'
import { Avatar, Grid, Box, IconButton, Typography } from '@mui/material'
import styles from './index.module.scss'
import { chatOrCommentMessageType, pubnub } from 'src/utils/constants'
import AudioPlayer from 'src/Component/AudioPlayer'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { downloadFileFromUrl } from 'src/utils/helpers'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

const convertTimeToken = (timetoken: number) => {
  const datetime: number = new Date(Math.trunc(timetoken / 10000)).getTime()
  const day = dayjs(datetime)
  if (day.isToday()) {
    return day.format('hh:mm A')
  }
  return day.format('DD MMM, hh:mm A')
}

type MessageItemProps = {
  channelId: string
  message: {
    title: string
    description?: string
  }
  file?: {
    id: string
    name: string
    url?: string
  } | null
  isOwn: boolean
  fromUser: {
    id: number
    name: string
    profilePic: string | null
  }
  toUser: {
    id: number
    name: string
    profilePic: string | null
  } | null
  timetoken: string
}
const MessageItem = ({
  message,
  isOwn,
  fromUser,
  toUser,
  channelId,
  file = null,
  timetoken,
}: MessageItemProps) => {

  const cacheUrl = useRef<string>('')
  const [isDownloading, setIsDownloading] = useState(false)
  const getFileUrlFromPubnub = (id: string, name: string): string => {
    if (cacheUrl.current) return cacheUrl.current

    if (file?.url) {
      cacheUrl.current = file.url
      return file.url
    }

    const url = pubnub.getFileUrl({
      channel: channelId,
      id,
      name,
    })

    cacheUrl.current = url

    return url
  }

  const downloadFile = async () => {
    if (cacheUrl.current && file) {
      setIsDownloading(true)
      downloadFileFromUrl(cacheUrl.current, file.name)
      setIsDownloading(false)
    }
  }

  const formatedTimeTokne = convertTimeToken(Number(timetoken))

  if (isOwn && fromUser) {
    return (
      <Grid container alignItems='end' justifyContent='end' p={1}>
        <Grid item mr={1}>
          {message.title === chatOrCommentMessageType.text && (
            <Box className={styles.messageBox_sent}> {message.description} </Box>
          )}

          {message.title === chatOrCommentMessageType.audio && file && (
            <Box width={300}>
              <AudioPlayer
                url={getFileUrlFromPubnub(file.id, file.name)}
                durationInSec={Number(message.description) / 1000}
              />
            </Box>
          )}

          {message.title === chatOrCommentMessageType.image && file && (
            <Box sx={{ position: 'relative' }}>
              <img src={getFileUrlFromPubnub(file.id, file.name)} width={300} />
              <IconButton
                onClick={downloadFile}
                color='success'
                sx={{
                  position: 'absolute',
                  bottom: 5,
                  right: 0,
                  background: 'black',
                  margin: 1,
                  ':hover': {
                    background: 'black',
                  },
                }}
                disabled={isDownloading}
              >
                <DownloadForOfflineIcon />
              </IconButton>
            </Box>
          )}
          <Typography textAlign='right' variant='subtitle2' color='white' mt={1}>
            {' '}
            {formatedTimeTokne}{' '}
          </Typography>
        </Grid>

        <Grid item>
          <Avatar
            alt={fromUser.name}
            src={fromUser.profilePic || 'broken-image.png'}
            className={styles.feedAvatarSmall}
          />
        </Grid>
      </Grid>
    )
  }

  if (!isOwn && toUser) {
    return (
      <Grid container alignItems='end' p={1}>
        <Grid item>
          <Avatar
            alt={toUser.name}
            src={toUser.profilePic || 'broken-image.png'}
            className={styles.feedAvatarSmall}
          />
          <Typography variant='subtitle2' color='white' mt={1}>
            {toUser.name.split(' ')[0]}
          </Typography>
        </Grid>
        <Grid item ml={1}>
          {message.title === chatOrCommentMessageType.text && (
            <Box className={styles.messageBox_received}> {message.description} </Box>
          )}

          {message.title === chatOrCommentMessageType.audio && file && (
            <Box width={300}>
              <AudioPlayer
                url={getFileUrlFromPubnub(file.id, file.name)}
                durationInSec={Number(message.description) / 1000}
              />
            </Box>
          )}

          {message.title === chatOrCommentMessageType.image && file && (
            <Box sx={{ position: 'relative' }}>
              <img src={getFileUrlFromPubnub(file.id, file.name)} width={300} />
              <IconButton
                onClick={downloadFile}
                color='success'
                sx={{
                  position: 'absolute',
                  bottom: 5,
                  right: 0,
                  background: 'black',
                  margin: 1,
                  ':hover': {
                    background: 'black',
                  },
                }}
                disabled={isDownloading}
              >
                <DownloadForOfflineIcon />
              </IconButton>
            </Box>
          )}
          <Typography variant='subtitle2' color='white' mt={1}>
            {' '}
            {formatedTimeTokne}{' '}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return null
}

export default MessageItem
