import React, { useEffect } from 'react'
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material'
import InputField from 'src/Component/InputField'
import { routeUrls, userRoles } from 'src/utils/constants'
import { Link } from 'react-router-dom'
import Dropdown from 'src/Component/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { getPrimarySpecialities } from 'src/store/features/auth/authActions'
import SocialLogin from 'src/Component/SocialLogin'

type CreateAccountProps = {
  form: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    createPassword: string
    termAndPolicy: boolean
    speciality: string | number
  }
  onSubmit: (e: React.SyntheticEvent) => void
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleTermAndPolicyClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDropdownChange: (name: string, value: any) => void
  loading: boolean
  error?: string | null
}
const CreateAccount = ({
  form,
  onSubmit,
  onInputChange,
  handleTermAndPolicyClick,
  onDropdownChange,
  loading,
  error,
}: CreateAccountProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { data } = useSelector((state: RootState) => state.auth.primarySpeciality)

  useEffect(() => {
    dispatch(getPrimarySpecialities())
  }, [])
  return (
    <form onSubmit={onSubmit}>
      <Box p={1}>
        <InputField
          name='firstName'
          id='firstName'
          type='text'
          placeholder='First Name'
          value={form.firstName}
          onChange={onInputChange}
          required
          regex={/[^a-zA-Z'\s]+/}
        />
      </Box>

      <Box p={1}>
        <InputField
          name='lastName'
          id='lastName'
          type='text'
          placeholder='Last Name'
          value={form.lastName}
          onChange={onInputChange}
          required
          regex={/[^a-zA-Z'\s]+/}
        />
      </Box>

      <Box p={1}>
        <Dropdown
          required
          value={form.speciality}
          label='Primary Speciality'
          options={data.map((row: any) => {
            return {
              value: row.name,
              label: row.name,
            }
          })}
          onChange={(value: any) => onDropdownChange('speciality', value)}
        />
      </Box>

      <Box p={1}>
        <InputField
          name='email'
          id='email'
          type='email'
          autoComplete='new-password'
          placeholder='Email'
          value={form.email}
          onChange={onInputChange}
          required
        />
      </Box>

      <Box p={1}>
        <InputField
          name='phoneNumber'
          id='phoneNumber'
          type='text'
          regex={/[^0-9]*$/}
          placeholder='Phone Number'
          value={form.phoneNumber}
          onChange={onInputChange}
        />
      </Box>

      <Box p={1}>
        <InputField
          name='createPassword'
          id='createPassword'
          type='password'
          autoComplete='new-password'
          placeholder='Create Password'
          value={form.createPassword}
          onChange={onInputChange}
          required
        />
      </Box>

      <Box p={1} pt={0}>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.termAndPolicy}
              onChange={handleTermAndPolicyClick}
              color='success'
              required
            />
          }
          label={
            <Typography variant='subtitle2' component='span'>
              I have read and agree to the{' '}
              <Link to={routeUrls.termAndCondition} target='_blank'>
              Terms of Service
              </Link>{' '}
              and{' '}
              <Link to={routeUrls.privacyPolicy} target='_blank'>
              Privacy Notice
              </Link>
            </Typography>
          }
        />
      </Box>

      <Typography variant='subtitle2' color='red' textAlign='center'>
        {' '}
        {error}{' '}
      </Typography>

      <Box p={1}>
        <Button
          type='submit'
          variant='contained'
          color='success'
          fullWidth
          disabled={loading}
          className={loading ? 'buttonDisabled' : ''}
        >
          {loading ? <CircularProgress size={25} /> : 'Continue'}
        </Button>
      </Box>

      <Box pt={1} pb={1} pl={10} pr={10}>
        <Divider>or</Divider>
      </Box>

      <Box p={1}>
        <SocialLogin userCategory={userRoles.healthCare} />
      </Box>
    </form>
  )
}

export default CreateAccount
