/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  IconButton,
  Badge,
  Avatar,
  Input,
} from '@mui/material'
import Header from 'src/Layout/HomeLayout/Header'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useNavigate } from 'react-router-dom'
import styles from '../index.module.scss'
import editIcon from 'src/assets/icons/edit-profile.png'
import InputField from 'src/Component/InputField'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import { useGetUserProfileByIdQuery, useUpdateUserProfileMutation } from 'src/api/userProfile'
import { errorToast, successToast } from 'src/utils/toastService'
import { routeUrls } from 'src/utils/constants'

const EditProfile = () => {
  const naviagate = useNavigate()

  const { userInfo } = useSelector((state: RootState) => state.auth)
  const { data: userProfileInfo, isLoading: isProfileInfoLoading } = useGetUserProfileByIdQuery(
    userInfo?.id || 0,
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const [updateProfile, { isLoading: isUpdatingProfile }] = useUpdateUserProfileMutation()

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  })

  const [imageFile, setImageFile] = useState<File | null | string>(null)

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageFile(e.target.files?.length ? e.target.files[0] : null)
  }

  const handleSave = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (form.phoneNumber && form.phoneNumber.toString().length < 10) {
      errorToast('Phone number should be atleast 10 digit long.')
      return
    }
    const formData = new FormData()
    formData.append('first_name', form.firstName)
    formData.append('last_name', form.lastName)
    form.phoneNumber && formData.append('phone', form.phoneNumber)

    if (imageFile && typeof imageFile !== 'string') {
      formData.append('selfie', imageFile)
    }

    updateProfile(formData)
      .unwrap()
      .then((res: any) => {
        successToast(res.message || 'Profile updated successfully.')
        naviagate(routeUrls.nonHealthCareUser.myProfile)
      })
      .catch((err) => {
        const obj = err.data.data
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key]
            errorToast(`${key}: ` + element.toString(','))
          }
        }
      })
  }

  useEffect(() => {
    if (userProfileInfo) {
      setForm({
        firstName: userProfileInfo.first_name,
        lastName: userProfileInfo.last_name,
        email: userProfileInfo.email,
        phoneNumber: userProfileInfo.phone,
      })

      setImageFile(userProfileInfo.selfie)
    }
  }, [userProfileInfo])

  if (isProfileInfoLoading) {
    return (
      <Box textAlign='center'>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box>
      <form onSubmit={handleSave}>
        <Header>
          <Box width='100%' p={3} pb={1} pl={0}>
            <Box mt={1}>
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <Typography variant='subtitle1' fontSize='24px'>
                    <IconButton onClick={() => naviagate(-1)}>
                      <ArrowBackIosNewIcon sx={{ color: 'white', marginRight: '10px' }} />{' '}
                    </IconButton>
                    Edit Profile
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    type='submit'
                    variant='contained'
                    color='success'
                    className={styles.editSaveBtn}
                    disabled={isUpdatingProfile}
                  >
                    {isUpdatingProfile ? <CircularProgress size={25} /> : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Header>

        <Box mt={8}>
          <Grid container justifyContent='center'>
            <Box className={styles.formWrapper} sx={{ height: '600px' }}>
              <Box textAlign='center'>
                <Badge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <label htmlFor='userImage' style={{ cursor: 'pointer' }}>
                      <img src={editIcon} height={32} width={32} />
                      <Input
                        type='file'
                        onChange={handleImageChange}
                        inputProps={{ accept: 'image/*' }}
                        sx={{ display: 'none' }}
                        name='userImage'
                        id='userImage'
                      />
                    </label>
                  }
                >
                  <Avatar
                    src={(() => {
                      if (imageFile) {
                        if (typeof imageFile === 'string') return imageFile
                        else return URL.createObjectURL(imageFile)
                      }
                      return 'broken-image.png'
                    })()}
                    variant='circular'
                    sx={{
                      width: 144,
                      height: 144,
                      border: '5px solid rgba(52, 214, 149, 1)',
                    }}
                  />
                </Badge>
              </Box>

              <Box mt={1}>
                <Box p={1}>
                  <InputField
                    name='firstName'
                    id='firstName'
                    type='text'
                    placeholder='First Name'
                    value={form.firstName}
                    onChange={onInputChange}
                    required
                    regex={/[^a-zA-Z'\s]+/}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='lastName'
                    id='lastName'
                    type='text'
                    placeholder='Last Name'
                    value={form.lastName}
                    onChange={onInputChange}
                    required
                    regex={/[^a-zA-Z'\s]+/}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='email'
                    id='email'
                    type='email'
                    autoComplete='new-password'
                    placeholder='Email'
                    value={form.email}
                    onChange={onInputChange}
                    disabled
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='phoneNumber'
                    id='phoneNumber'
                    type='text'
                    regex={/[^0-9]*$/}
                    placeholder='Phone Number'
                    value={form.phoneNumber}
                    onChange={onInputChange}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default EditProfile
