import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import InputField from 'src/Component/InputField'
import StyledFileInputComponent from 'src/Component/StyledFileInputComponent'
import Dropdown from 'src/Component/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { getPrimarySpecialities } from 'src/store/features/auth/authActions'
import VouchedVerification from 'src/Component/VouchedVerification'
import { errorToast, successToast } from 'src/utils/toastService'

type IDverificationProps = {
  form: {
    idNumber: string
    // frontPhoto: File | null
    // backPhoto: File | null
    speciality: string | number
    phoneNumber: string
    isVerified: boolean
  }
  onSubmit: (e: React.SyntheticEvent) => void
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  loading: boolean
  onDropdownChange: (name: string, value: any) => void
  onVouchedChange: (status: boolean) => void
  error?: string | null
}

const IDverification = ({
  form,
  onInputChange,
  onSubmit,
  loading,
  error,
  onFileChange,
  onDropdownChange,
  onVouchedChange,
}: IDverificationProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { data } = useSelector((state: RootState) => state.auth.primarySpeciality)

  useEffect(() => {
    dispatch(getPrimarySpecialities())
  }, [])
  const [isDisabledIDNumber, setDisabledIDNumber] = useState(false)
  const vouchedSuccess = (response: any) => {
    console.log('vouchedSuccess : ', response)
    if (response.errors.length === 0) {
      if (form.idNumber === response.result.id) {
        onVouchedChange(true)
        setDisabledIDNumber(true)
        successToast('Verification success, Please continue your registration.')
      } else {
        vouchedFailure('ID number mismatch')
      }
    }
  }
  const vouchedFailure = (errMsg: string) => {
    console.log('vouchedFailure : ', errMsg)
    onVouchedChange(false)
    setDisabledIDNumber(false)
    errorToast('Verification failed. Please try again.')
  }
  return (
    <form onSubmit={onSubmit}>
      <Box p={1}>
        <Dropdown
          required
          value={form.speciality}
          label='Primary Speciality'
          options={data.map((row: any) => {
            return {
              value: row.name,
              label: row.name,
            }
          })}
          onChange={(value: any) => onDropdownChange('speciality', value)}
        />
      </Box>
      <Box p={1}>
        <InputField
          name='phoneNumber'
          id='phoneNumber'
          type='text'
          regex={/[^0-9]*$/}
          placeholder='Phone Number'
          value={form.phoneNumber}
          onChange={onInputChange}
        />
      </Box>
      <Box p={1}>
        <InputField
          name='idNumber'
          id='idNumber'
          type='text'
          placeholder='ID Number'
          value={form.idNumber}
          onChange={onInputChange}
          disabled={isDisabledIDNumber}
          required
        />
        <Typography variant='subtitle2' textAlign='left'>
          *Driving License
        </Typography>
      </Box>

      <Box p={1}>
        <VouchedVerification
          onSuccess={(job: any) => {
            vouchedSuccess(job)
          }}
          onFailure={(errMsg: string) => {
            vouchedFailure(errMsg)
          }}
          title='Upload your ID'
          discription='Upload your ID'
          name='vouchedVerification'
          validationId={form.idNumber}
        />
      </Box>

      <Typography variant='subtitle2' color='red' textAlign='center'>
        {error}
      </Typography>

      <Box p={1}>
        <Button
          type='submit'
          variant='contained'
          color='success'
          fullWidth
          disabled={loading || !isDisabledIDNumber}
          className={loading ? 'buttonDisabled' : ''}
        >
          {loading ? <CircularProgress size={25} /> : 'Continue'}
        </Button>
      </Box>
    </form>
  )
}

export default IDverification
