import React, { useEffect, useState } from 'react'
import Header from 'src/Layout/LoginLayout/Header'
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
} from '@mui/material'
import styles from '../index.module.scss'
import InputField from 'src/Component/InputField'
import { routeUrls, userRoles, EMAIL_REGEX } from 'src/utils/constants'
import { Link } from 'react-router-dom'
import Dropdown from 'src/Component/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { getPrimarySpecialities, registerUser } from 'src/store/features/auth/authActions'
import { setAuthErrorReducer } from 'src/store/features/auth/authSlice'
import { toast } from 'react-toastify'
import Modal from '@mui/material/Modal'

interface ModalWindowProps {
  open: boolean;
  handleClose: () => void;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ open, handleClose }) => {
  const modalStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle: React.CSSProperties = {
    position: 'absolute',
    width: '90%',
    maxWidth: 400, 
    backgroundColor: '#0a1418',
    border: '2px solid #000',
    padding: '20px',
  };


  const okayButtonStyle: React.CSSProperties = {
    marginTop: '20px',
    width: '100%',
    backgroundColor: 'rgb(52, 214, 149)',
    color: 'white'
  };

  const handleOkay = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={modalStyle}
    >
      <div style={paperStyle}>
        <Typography variant="body1">
          The request for creation of an account sent for admin approval. Once the admin approves the user data,
          the credentials will be triggered and sent to your registered email.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOkay}
          style={okayButtonStyle}
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};

const HealthcareUserEndorse = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error } = useSelector((state: RootState) => state.auth)
  const { data } = useSelector((state: RootState) => state.auth.primarySpeciality)
  const [modalOpen, setModalOpen] = useState(false);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    speciality: '',
    email: '',
    phoneNumber: '',
    createPassword: '',
    termAndPolicy: false,
  })

  useEffect(() => {
    dispatch(getPrimarySpecialities())
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleDropdownChange = (name: string, value: any) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleTermAndPolicyClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      termAndPolicy: event.target.checked,
    })
  }

  const resetForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      speciality: '',
      email: '',
      phoneNumber: '',
      createPassword: '',
      termAndPolicy: false,
    });
  };

  const handleCreateAccountSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!EMAIL_REGEX.test(form.email)) {
      dispatch(setAuthErrorReducer('Please enter a valid email address.'))
      return
    }

    if (form.phoneNumber && form.phoneNumber.toString().length < 10) {
      dispatch(setAuthErrorReducer('Phone number should be atleast 10 digit long.'))
      return
    }
    else {
      dispatch(setAuthErrorReducer(''))
    }

    const formData = new FormData()
    formData.append('first_name', form.firstName)
    formData.append('last_name', form.lastName)
    formData.append('email', form.email)
    form.phoneNumber && formData.append('phone', form.phoneNumber)
    formData.append('primary_speciality', form.speciality)

    dispatch(registerUser(formData))
      .then((res) => {
        console.log('response', res);
        if (res.type === 'register/rejected') {
          toast.error(res.payload || 'An error occurred.');
        } else {
          setModalOpen(true);
          resetForm();
        }
      })
  }

  return (
    <Box>
      <Header hideLogin={true} />
      <Box mt={10}>
        <Grid container>
          <Grid xs={12} sm={12} md={3} lg={3.5}>
            {''}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Typography textAlign='center' variant='h4'>
              Create Account
            </Typography>
            <Typography textAlign='center' variant='subtitle2'>
              We need to verify your information. <br />
              All data is encrypted end to end
            </Typography>

            <Box mt={1} p={2} className={styles.createAccountFormWrapper}>
              <form onSubmit={handleCreateAccountSubmit}>
                <Box p={1}>
                  <InputField
                    name='firstName'
                    id='firstName'
                    type='text'
                    placeholder='First Name'
                    value={form.firstName}
                    onChange={handleInputChange}
                    required
                    regex={/[^a-zA-Z'\s]+/}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='lastName'
                    id='lastName'
                    type='text'
                    placeholder='Last Name'
                    value={form.lastName}
                    onChange={handleInputChange}
                    required
                    regex={/[^a-zA-Z'\s]+/}
                  />
                </Box>

                <Box p={1}>
                  <Dropdown
                    required
                    value={form.speciality}
                    label='Primary Speciality'
                    options={data.map((row: any) => {
                      return {
                        value: row.name,
                        label: row.name,
                      }
                    })}
                    onChange={(value: any) => handleDropdownChange('speciality', value)}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='email'
                    id='email'
                    type='email'
                    autoComplete='new-password'
                    placeholder='Email'
                    value={form.email}
                    onChange={handleInputChange}
                    required
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='phoneNumber'
                    id='phoneNumber'
                    type='text'
                    regex={/[^0-9]*$/}
                    placeholder='Phone Number'
                    value={form.phoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                </Box>

                <Box p={1} pt={0}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.termAndPolicy}
                        onChange={handleTermAndPolicyClick}
                        color='success'
                        required
                      />
                    }
                    label={
                      <Typography variant='subtitle2' component='span'>
                        I have read and agree to the{' '}
                        <Link to={routeUrls.termAndCondition} target='_blank'>
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link to={routeUrls.privacyPolicy} target='_blank'>
                          Privacy Notice
                        </Link>
                      </Typography>
                    }
                  />
                </Box>

                <Typography variant='subtitle2' color='red' textAlign='center'>
                  {' '}
                  {error}{' '}
                </Typography>

                <Box p={1}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='success'
                    fullWidth
                    disabled={loading}
                    className={loading ? 'buttonDisabled' : ''}
                  >
                    {loading ? <CircularProgress size={25} /> : 'Submit'}
                  </Button>
                </Box>

              </form>
            </Box>

            <ModalWindow open={modalOpen} handleClose={() => setModalOpen(false)} />

            <Box mt={5}></Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default HealthcareUserEndorse
