/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import {
  API_BASE_PATH,
  SIGHTENGINE_API_SECRET,
  SIGHTENGINE_API_USER,
  SIGHTENGINE_URL,
} from 'src/utils/constants'
import { MyConnectionResponse, myConnection, tag, Feed, UpdateFeedPayload, Speciality } from './types'
import { Specialty } from '../feed/types'

const createFeedApi = createApi({
  reducerPath: 'createFeedApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['CreateFeed'],
  endpoints: (builder) => ({
    getTags: builder.query<tag[], void>({
      query: () => ({
        url: '/api/v1/tags/',
        method: 'GET',
      }),
      transformResponse: (response: tag[]) => {
        return response
      },
    }),

    getMyConnections: builder.query<myConnection[], void>({
      query: () => ({
        url: '/api/v1/connections/my_connections/',
        method: 'GET',
      }),
      transformResponse: (response: MyConnectionResponse) => {
        return response.data
      },
    }),

    createFeed: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: '/api/v1/post/',
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
        },
        data: payload,
      }),
    }),

    removeInappropriateContentFromImage: builder.mutation<void, File>({
      query: (image) => {
        return {
          url: SIGHTENGINE_URL,
          method: 'POST',
          headers: {
            'Content-type': 'multipart/form-data',
          },
          data: {
            media: image,
            concepts:
              'nudity,nudity-raw,face-minor,email,phone,license-plate,offensive,weapon,alcohol,recreational-drug,medical-drug,gore,profanity,link,email,social,qr,face',
            api_user: SIGHTENGINE_API_USER,
            api_secret: SIGHTENGINE_API_SECRET,
          },
        }
      },
    }),

    deleteMyFeed: builder.mutation<void, number>({
      query: (feedId) => ({
        url: `/api/v1/post/${feedId}/`,
        method: 'DELETE',
      }),
    }),

    getFeedById: builder.query<Feed, number>({
      query: (feedId) => ({
        url: `/api/v1/post/${feedId}/`,
        method: 'GET',
      }),
    }),
    updateFeed: builder.mutation<void, UpdateFeedPayload>({
      query: ({ data, id }) => ({
        url: `/api/v1/post/${id}/`,
        method: 'PUT',
        // method: 'PATCH',
        headers: {
          'Content-type': 'multipart/form-data',
        },
        data: data,
      }),
    }),
    getSpecialtyList: builder.query<Specialty[], string>({
      query: (queryString) => ({
        url: '/api/v1/speciality/',
        method: 'GET',
        params: {
          search: queryString,
        }
      }),
      transformResponse: (response: Specialty[]) => {
        return response
      },
    })


  }),
})




export const {
  useGetTagsQuery,
  useGetMyConnectionsQuery,
  useCreateFeedMutation,
  useRemoveInappropriateContentFromImageMutation,
  useDeleteMyFeedMutation,
  useLazyGetFeedByIdQuery,
  useUpdateFeedMutation,
  useGetSpecialtyListQuery

} = createFeedApi
export default createFeedApi
