import React from 'react'
type IconProps = {
  color?: string
}
const CreatIcon = ({ color = '#909090' }: IconProps) => {
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 19.3335V1.3335M1.5 10.3335H19.5'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CreatIcon
