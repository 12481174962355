import Pubnub from 'pubnub'

export const API_BASE_PATH = window.location.origin

import React from 'react'
import FeedIcon from 'src/assets/icons/svg/FeedIcon'
import ChatIcon from 'src/assets/icons/svg/ChatIcon'
import CreatIcon from 'src/assets/icons/svg/CreateIcon'
import MyPostsIcon from 'src/assets/icons/svg/MyPostsIcon'
import UserProfileIcon from 'src/assets/icons/svg/UserProfileIcon'
import FeedbackIcon from 'src/assets/icons/svg/FeedbackIcon'
import SettingIcon from 'src/assets/icons/svg/SettingIcon'
import LogoutIcon from 'src/assets/icons/svg/LogoutIcon'
import MySavedFeedsIcon from 'src/assets/icons/svg/MySavedFeedsIcon'

export const routeUrls = {
  landingPage: '/',
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  termAndCondition: '/term-and-condition',
  privacyPolicy: '/privacy-policy',
  healthcareUserRegistration: '/register/healthcare-professional',
  healthcareUserRegistrationEndorse: '/register/healthcare-professional/endorsement',
  completeHealthcareUserRegistration: '/register/complete-healthcare-professional',
  nonHealthcareUserRegistration: '/register/non-healthcare-professional',
  logout: '/logout',

  home: '/home',
  postDetail: '/home/post-detail/:postId',
  myPosts: '/home/my-posts',
  mySavedFeeds: '/home/my-saved-feeds',
  createFeed: '/home/create-feed',
  editFeed: '/home/create-feed/edit/:feedId',
  supportAndFeedback: '/home/support-and-feedback',
  myProfile: '/home/my-profile',
  editMyyProfile: '/home/my-profile/edit',
  viewProfile: '/home/view-profile/:userId',
  settings: '/home/settings',

  chat: '/home/chat',

  // non healthcare users
  nonHealthCareUser: {
    home: '/non-healthcare/home',
    viewOthersProfile: '/non-healthcare/home/view-profile/:userId',
    myProfile: '/non-healthcare/home/my-profile',
    editMyProfile: '/non-healthcare/home/my-profile/edit',
    postDetail: '/non-healthcare/home/post-detail/:postId',
    supportAndFeedback: '/non-healthcare/home/support-and-feedback',
    settings: '/non-healthcare/home/settings',
  },
}

export const localStorageKeys = {
  accessToken: 'accessToken',
  user: 'user',
  role: 'role',
  isSignupCompleted: 'isSignupCompleted',
}

export type userRolesTypes = 'nonhealthcare' | 'healthcare'

export const userRoles = {
  nonHealthCare: 'nonhealthcare',
  healthCare: 'healthcare',
}

export interface ISidebarMenuItem {
  label: string
  url: string
  icon: React.ReactElement
  color?: string
  role: userRolesTypes
}

export const sidebarMenuItems: ISidebarMenuItem[] = [
  {
    label: 'Feed',
    url: routeUrls.home,
    icon: <FeedIcon />,
    role: 'healthcare',
  },
  {
    label: 'Chat',
    url: routeUrls.chat,
    icon: <ChatIcon />,
    role: 'healthcare',
  },
  {
    label: 'Create',
    url: routeUrls.createFeed,
    icon: <CreatIcon />,
    role: 'healthcare',
  },
  {
    label: 'My Posts',
    url: routeUrls.myPosts,
    icon: <MyPostsIcon />,
    role: 'healthcare',
  },
  {
    label: 'Saved Posts',
    url: routeUrls.mySavedFeeds,
    icon: <MySavedFeedsIcon />,
    role: 'healthcare',
  },
  {
    label: 'Profile',
    url: routeUrls.myProfile,
    icon: <UserProfileIcon />,
    role: 'healthcare',
  },

  // non healtcare
  {
    label: 'Feed',
    url: routeUrls.nonHealthCareUser.home,
    icon: <FeedIcon />,
    role: 'nonhealthcare',
  },
  {
    label: 'Profile',
    url: routeUrls.nonHealthCareUser.myProfile,
    icon: <UserProfileIcon />,
    role: 'nonhealthcare',
  },
]

export const sidebarSettingMenuItems: ISidebarMenuItem[] = [
  {
    label: 'Support and Feedback',
    url: routeUrls.supportAndFeedback,
    icon: <FeedbackIcon />,
    role: 'healthcare',
  },
  {
    label: 'Settings',
    url: routeUrls.settings,
    icon: <SettingIcon />,
    role: 'healthcare',
  },
  {
    label: 'Log Out',
    url: routeUrls.logout,
    icon: <LogoutIcon />,
    color: '#D30000',
    role: 'healthcare',
  },

  // non healtcare
  {
    label: 'Support and Feedback',
    url: routeUrls.nonHealthCareUser.supportAndFeedback,
    icon: <FeedbackIcon />,
    role: 'nonhealthcare',
  },
  {
    label: 'Settings',
    url: routeUrls.nonHealthCareUser.settings,
    icon: <SettingIcon />,
    role: 'nonhealthcare',
  },
  {
    label: 'Log Out',
    url: routeUrls.logout,
    icon: <LogoutIcon />,
    color: '#D30000',
    role: 'nonhealthcare',
  },
]

export const drawerWidth = 280
export const EMAIL_REGEX = /[^\s@]+@[^\s@]+\.[^\s@]+/

export const PUBNUB_PUBLISH_KEY = 'pub-c-fe5b4a39-c8c3-4c2e-a582-20bbbe92b1e0'
export const PUBNUB_SUBSCRIBE_KEY = 'sub-c-4d898571-3666-4316-8901-47eccc59d84c'
export const pubnub = new Pubnub({
  subscribeKey: PUBNUB_SUBSCRIBE_KEY,
  publishKey: PUBNUB_PUBLISH_KEY,
  uuid: 'ChangeMe',
})

export const chatOrCommentMessageType = {
  text: 'text',
  audio: 'audio',
  image: 'image',
}

export const SIGHTENGINE_API_SECRET = 'KxXSwLdnVRWH84Zway5a'
export const SIGHTENGINE_API_USER = 1045395739
export const SIGHTENGINE_URL = 'https://api.sightengine.com/1.0/transform.json'

export const PER_PAGE = 50

export const VISION_API_KEY = 'AIzaSyD-Kxs-ZcdWiwKqyZwznTgRYiFLrYPXHgE'
export const VISION_API_ENDPOINT =
  'https://vision.googleapis.com/v1/images:annotate?key=' + VISION_API_KEY

// Vouched keys
export const VOUCHED_PUBLIC_KEY = 'CtX!6U#81BDh3_vgO@o6vXMRzWKRTh';

export const chatWidthForLargeScreen = 1130;
export const isSmallerDevice : boolean = window.innerWidth < chatWidthForLargeScreen
