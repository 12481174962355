import React, { useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Avatar,
  Input,
  Badge,
} from '@mui/material'
import { myConnection } from 'src/api/createFeed/types'
import ConnectionRow from '../OneToOneChat/ConnectionRow'
import InputField from 'src/Component/InputField'
import editIcon from 'src/assets/icons/edit-profile.png'
import cameraIcon from 'src/assets/icons/camera.png'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { useCreateChannelMutation, useUpdateGroupChannelInfoMutation } from 'src/api/chat'
import { initiateGroupChat, refetchChannelList } from 'src/store/features/chat/chatSlice'

type GroupChatProps = {
  isFetchingConnections: boolean
  connectionsArr: myConnection[]
  onClose: () => void
}
const GroupChat = ({ isFetchingConnections, connectionsArr, onClose }: GroupChatProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch<AppDispatch>()
  const [activeStep, setActiveStep] = useState<1 | 2>(1)
  const [selectedConnections, setSelectedConnections] = useState<Map<number, number>>(new Map())
  const [groupName, setGroupName] = useState<string>('')
  const [groupIcon, setGroupIcon] = useState<File | null>(null)
  const [error, setError] = useState<string>('')
  const [createChannel, { isLoading: isCreateChannelLoading }] = useCreateChannelMutation()
  const [updateGroupChannelInfo, { isLoading: isUpdatingChannelInfoLoading }] =
    useUpdateGroupChannelInfoMutation()

  const handleCheckboxClick = (userId: number) => {
    const _selectedConnections = new Map(selectedConnections)
    if (_selectedConnections.has(userId)) {
      _selectedConnections.delete(userId)
    } else {
      _selectedConnections.set(userId, 1)
    }

    setSelectedConnections(_selectedConnections)
  }

  const handleGroupIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setGroupIcon(e.target.files[0])
    }
  }

  const isNotSelectedAtleastTwoUsers = () => {
    return selectedConnections && selectedConnections.size !== 0 && selectedConnections.size === 1
  }

  const handleNext = () => {
    if (!selectedConnections.size) {
      setError('Please select group users.')
      return
    }
    if (isNotSelectedAtleastTwoUsers()) {
      setError('Atleast select 2 users.')
      return
    }

    setError('')
    setActiveStep(2)
  }

  const handleCreateGroup = async () => {
    
    if (!groupName) {
      setError('Please enter group name.')
      return
    }

    if (!groupIcon) {
      setError('Please upload group icon.')
      return
    }

    if (userInfo && selectedConnections.size > 0 && groupName && groupIcon) {
      const groupUsers = Array.from(selectedConnections.keys())
      let updateGroupResponse: any
      try {
        const channelInfo: any = await createChannel([userInfo.id, ...groupUsers])
        if (channelInfo?.data?.data) {
          const formData = new FormData()
          formData.append('channel_name', groupName)
          formData.append('channel_icon', groupIcon)
          updateGroupResponse = await updateGroupChannelInfo({
            formData,
            channelId: channelInfo.data.data.channel_id,
          })
        }
      } catch (error) {
        console.log(error)
      }

      setError('')
      console.log(updateGroupResponse)
      if (updateGroupResponse) {
        const fromUser =
          updateGroupResponse.data.data.users.find((user: any) => user.id == userInfo.id) || null
        const toUsersArr =
          updateGroupResponse.data.data.users.filter((user: any) => user.id != userInfo.id) || []

        dispatch(
          initiateGroupChat({
            fromUser: {
              id: fromUser.id,
              firstName: fromUser.first_name,
              lastName: fromUser.last_name,
              profilePic: fromUser.profile_picture,
              isAdmin: !!updateGroupResponse.data.data.admins.find(
                (id: number) => id === fromUser.id,
              ),
            },
            toUsersArr: (() => {
              return toUsersArr.map((toUser: any) => {
                return {
                  id: toUser.id,
                  firstName: toUser.first_name,
                  lastName: toUser.last_name,
                  profilePic: toUser.profile_picture,
                  isAdmin: !!updateGroupResponse.data.data.admins.find(
                    (id: number) => id === toUser.id,
                  ),
                }
              })
            })(),
            channelId: updateGroupResponse.data.data.channel_id,
            groupInfo: {
              name: updateGroupResponse.data.data.channel_name || '',
              icon: updateGroupResponse.data.data.channel_icon || '',
            },
          }),
        )
        dispatch(refetchChannelList())
        onClose()
      }
    }
  }
  return (
    <Box p={2}>
      <Typography variant='subtitle1' fontWeight={400} fontSize={24}>
        New Group
      </Typography>

      {activeStep === 1 && (
        <>
          <Typography variant='subtitle1' fontWeight={600} fontSize={16} color='gray'>
            Connections
          </Typography>
          <Box
            sx={{
              overflowY: 'scroll',
              height: 370,
            }}
          >
            {isFetchingConnections ? (
              <Grid container justifyContent='center'>
                <CircularProgress />
              </Grid>
            ) : (
              connectionsArr.map((connection) => (
                <ConnectionRow
                  userId={connection.id}
                  key={connection.id}
                  name={connection.name}
                  profilePic={connection.profile_picture}
                  onClick={handleCheckboxClick}
                  showCheckbox
                  checked={selectedConnections.has(connection.id)}
                />
              ))
            )}
          </Box>
        </>
      )}

      {activeStep === 2 && (
        <>
          <Grid container pt={3} pb={3} alignItems='center'>
            <Grid xs={2}>
              <Box textAlign='center'>
                <Badge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <label htmlFor='userImage' style={{ cursor: 'pointer' }}>
                      <img src={editIcon} height={15} width={15} />
                      <Input
                        type='file'
                        onChange={handleGroupIconChange}
                        inputProps={{ accept: 'image/*' }}
                        sx={{ display: 'none' }}
                        name='userImage'
                        id='userImage'
                      />
                    </label>
                  }
                >
                  <Avatar
                    src={(() => {
                      if (groupIcon) {
                        return URL.createObjectURL(groupIcon)
                      }
                      return cameraIcon
                    })()}
                    variant='circular'
                    sx={{
                      width: 45,
                      height: 45,
                      border: '1px solid rgba(52, 214, 149, 1)',
                    }}
                  />
                </Badge>
              </Box>
            </Grid>
            <Grid xs={10}>
              <InputField
                name='groupName'
                id='groupName'
                type='text'
                placeholder='Group Name'
                value={groupName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
              />
            </Grid>
          </Grid>

          <Typography variant='subtitle1' fontWeight={600} fontSize={16} color='gray'>
            Connections
          </Typography>
          <Box
            sx={{
              overflowY: 'scroll',
              height: 275,
            }}
          >
            {connectionsArr.map((connection) => {
              if (!selectedConnections.has(connection.id)) return null
              return (
                <ConnectionRow
                  userId={connection.id}
                  key={connection.id}
                  name={connection.name}
                  profilePic={connection.profile_picture}
                />
              )
            })}
          </Box>
        </>
      )}

      <Typography textAlign='center' variant='subtitle1' color='red'>
        {error}
      </Typography>

      <Grid container>
        <Grid xs={6} p={1}>
          {activeStep === 1 ? (
            <Button
              onClick={handleNext}
              type='button'
              variant='contained'
              color='success'
              fullWidth
              sx={{ height: '48px' }}
            >
              Next
            </Button>
          ) : isCreateChannelLoading || isUpdatingChannelInfoLoading ? (
            <Grid container justifyContent='center'>
              <CircularProgress />
            </Grid>
          ) : (
            <Button
              onClick={handleCreateGroup}
              type='button'
              variant='contained'
              color='success'
              fullWidth
              sx={{ height: '48px' }}
            >
              Create
            </Button>
          )}
        </Grid>

        <Grid xs={6} p={1}>
          <Button
            onClick={onClose}
            type='button'
            variant='outlined'
            color='error'
            fullWidth
            sx={{ border: 'none', height: '48px' }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GroupChat
