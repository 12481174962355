import React, { useState } from 'react'
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material'
import InputField from 'src/Component/InputField'

import styles from './index.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { resetPasswordpApi } from 'src/store/features/forgotPassword/forgotPasswordAction'
import { passwordValidation } from 'src/utils/helpers'
import { setErrorReducer } from 'src/store/features/forgotPassword/forgotPasswordSlice'
type ResetPasswordProps = {
  callback: () => void
}
const ResetPassword = (props: ResetPasswordProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error, email, token } = useSelector((state: RootState) => state.forgotPassword)
  const [form, setForm] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    dispatch(setErrorReducer(''))
  }

  const handleResetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const passwordValidationError = passwordValidation(form.newPassword)
    if (passwordValidationError !== '') {
      dispatch(setErrorReducer(passwordValidationError))
      return
    }

    if (form.newPassword !== form.confirmPassword) {
      dispatch(setErrorReducer('New password and confirm password  should be same.'))
      return
    }
    dispatch(resetPasswordpApi({ email, password: form.newPassword, token })).then((res: any) => {
      if (!res.error) {
        props.callback()
      }
    })
  }

  return (
    <Box>
      <Box className={styles.title}>
        <Typography variant='h3'> Create New Password</Typography>
        <Typography variant='subtitle2'>
          Your new password must be different <br /> from previous used password
        </Typography>
      </Box>
      <Box className={styles.formWrapper}>
        <form onSubmit={handleResetPassword}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
            p={10}
            height='100%'
            className={styles.wrapperNestedDiv}
          >
            <Grid item>
              <InputField
                name='newPassword'
                id='newPassword'
                autoComplete='new-password'
                type='password'
                placeholder='New Password'
                value={form.newPassword}
                onChange={onInputChange}
                required
              />

              <InputField
                name='confirmPassword'
                id='confirmPassword'
                autoComplete='new-password'
                type='password'
                placeholder='Confirm Password'
                value={form.confirmPassword}
                onChange={onInputChange}
                margin='dense'
                required
              />
            </Grid>

            <Grid item>
              <Typography variant='subtitle2' color='red' textAlign='center'>
                {error}
              </Typography>
            </Grid>
            <Grid item pt={2}> 
              <Button
                variant='contained'
                color='success'
                fullWidth
                type='submit'
                disabled={loading}
                className={loading ? 'buttonDisabled' : ''}
              >
                {loading ? <CircularProgress size={25} /> : ' Reset Password'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}

export default ResetPassword
