import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import styles from './index.module.scss';
import InputField from 'src/Component/InputField';
import { longDescriptionIndexToPlaceholderMapping, Section } from '.';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
interface LongDescriptionProps {
  sections: Section[];
  setSection(description: string, index: number): void;
}
const LongDescription: React.FC<LongDescriptionProps> = ({ sections, setSection }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % sections.length);
  };

  const handlePrev = () => {
    setActiveIndex(prevIndex => (prevIndex - 1 + sections.length) % sections.length);
  };

  const onInputChange = (event, index) => {
    setSection(event.target.value, index);
  };

  return (
    <Box className={styles['carousel-container']} sx={{ position: 'relative' }}>
      <Carousel
        className={styles.descriptionCarousel}
        autoPlay={false}
        navButtonsAlwaysInvisible={true}
        cycleNavigation={false}
        swipe={true}
        indicators={true}
        animation="slide"
        fullHeightHover={true}
        changeOnFirstRender
        onChange={index => setActiveIndex(index)}
        height={150}
        index={activeIndex}
      >
        {sections.map((item, idx) => {
          return (
            <Box
              key={String(item.id)}
              sx={{
                height: '100%',
              }}
              className="annotation-tooltip-wrapper"
            >
              <Typography sx={{ marginLeft: 1 }} variant="h5">
                {item.title}
              </Typography>
              <Box p={1}>
                <InputField
                  name="description"
                  id="description"
                  type="text"
                  placeholder={longDescriptionIndexToPlaceholderMapping[idx]}
                  value={item.description}
                  onChange={event => onInputChange(event, idx)}
                  required
                  multiline
                  rows={4}
                />
              </Box>
            </Box>
          );
        })}
      </Carousel>
      <IconButton
        onClick={handlePrev}
        className={styles['nav-button']}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '-20px',
          transform: 'translateY(-50%)',
          zIndex: 1,
          color: 'white',
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        onClick={handleNext}
        className={styles['nav-button']}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '-22px',
          transform: 'translateY(-50%)',
          zIndex: 1,
          color: 'white',
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default LongDescription;
