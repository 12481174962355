import React from 'react'
import { Typography, Grid, Avatar, FormControlLabel, Checkbox, Chip } from '@mui/material'
type ConnectionRowProps = {
  userId: number
  name: string
  profilePic: string | null
  onClick?: (userId: number) => void
  showCheckbox?: boolean
  checked?: boolean
  showAdminTag?: boolean
}

const ConnectionRow = ({
  userId,
  name,
  profilePic,
  onClick = () => {
    console.log('no on click handler')
  },
  showCheckbox = false,
  checked = false,
  showAdminTag = false,
}: ConnectionRowProps) => {
  return (
    <Grid
      container
      alignItems='center'
      p={2}
      onClick={() => onClick(userId)}
      sx={{ cursor: 'pointer' }}
    >
      <Grid item>
        <Avatar
          alt={name}
          src={profilePic || 'broken-image.png'}
          sx={{ border: '1px solid rgba(52, 214, 149, 1)' }}
        />
      </Grid>

      <Grid item flexGrow={2}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='subtitle1' fontWeight={600} fontSize={14} pl={2}>
              {name}
            </Typography>
          </Grid>

          {showCheckbox && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={() => onClick(userId)} color='success' />
                }
                label=''
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {showAdminTag && (
        <Grid item>
          <Chip label='Group Admin' color='primary' />
        </Grid>
      )}
    </Grid>
  )
}

export default ConnectionRow
