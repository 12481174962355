/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import Header from 'src/Layout/LoginLayout/Header'
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material'
import InputField from 'src/Component/InputField'
import { Link } from 'react-router-dom'
import styles from '../index.module.scss'
import { EMAIL_REGEX, routeUrls, userRoles } from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { registerCompleteHealthUser } from 'src/store/features/auth/authActions'
import CustomStepper from 'src/Component/CustomStepper'
import IDverification from './IDverification'
import LicenseVerification from '../HealthcareUserRegistration/LicenseVerification'
import UploadSelfie from '../HealthcareUserRegistration/UploadSelfie'
import { dataURLtoFile, passwordValidation } from 'src/utils/helpers'
import { errorToast } from 'src/utils/toastService'
import { setAuthErrorReducer } from 'src/store/features/auth/authSlice'
const CompleteHealthcareUserRegistration = () => {
  const [activeStep, setActiveStep] = useState<number>(1)
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error } = useSelector((state: RootState) => state.auth)

  // create account
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    speciality: '',
    email: '',
    phoneNumber: '',
    createPassword: '',
    dob: '',
    termAndPolicy: false,
  })

  const handleTermAndPolicyClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      termAndPolicy: event.target.checked,
    })
  }

  // ID verification
  const [IdVerificationForm, setIdVerificationForm] = useState({
    idNumber: '',
    // frontPhoto: null,
    // backPhoto: null,
    speciality: '',
    phoneNumber: '',
    isVerified: false,
  })

  const onIdVerificationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIdVerificationForm({
      ...IdVerificationForm,
      [e.target.name]: e.target.value,
    })
  }

  const onIdVerificationVouched = (status:boolean)=>{
    dispatch(setAuthErrorReducer(''))
    setIdVerificationForm({
      ...IdVerificationForm,
      ['isVerified']: status,
    })
  }

  const onIdVerificationFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIdVerificationForm({
      ...IdVerificationForm,
      [e.target.name]: e.target.files?.length ? e.target.files[0] : null,
    })
  }

  const onIDverificationDropdownChange = (name: string, value: any) => {
    setIdVerificationForm({
      ...IdVerificationForm,
      [name]: value,
    })
  }

  const handleIdVerificationSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (IdVerificationForm.phoneNumber && IdVerificationForm.phoneNumber.toString().length < 10) {
      dispatch(setAuthErrorReducer('Phone number should be atleast 10 digit long.'))
      return
    }
    // if (!IdVerificationForm.frontPhoto) {
    //   dispatch(setAuthErrorReducer('Upload the front of your ID card'))
    //   return
    // }
    // if (!IdVerificationForm.backPhoto) {
    //   dispatch(setAuthErrorReducer('Upload the back of your ID card'))
    //   return
    // }
    if (!IdVerificationForm.isVerified) {
      dispatch(setAuthErrorReducer('Please verify your Govt. ID card'))
      return
    }
    setActiveStep((prev) => prev + 1)
    dispatch(setAuthErrorReducer(''))
  }

  // license verification
  const [licenseVerificationForm, setlicenseVerificationForm] = useState({
    professionalName: '',
    medicalLicenseNumber: '',
    licenseImage: null,
  })

  const onlicenseVerificationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setlicenseVerificationForm({
      ...licenseVerificationForm,
      [e.target.name]: e.target.value,
    })
  }

  const onlicenseVerificationFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setlicenseVerificationForm({
      ...licenseVerificationForm,
      [e.target.name]: e.target.files?.length ? e.target.files[0] : null,
    })
  }

  const handlelicenseVerificationSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!licenseVerificationForm.licenseImage) {
      dispatch(setAuthErrorReducer('Upload Medical License'))
      return
    }
    // setActiveStep((prev) => prev + 1)
    dispatch(setAuthErrorReducer(''))
    handleFinalSubmit()
  }

  // upload selfie

  const [selfie, setSelfie] = useState<string>('')

  // const handleFinalSubmit = (e: React.SyntheticEvent) => {
  //  e.preventDefault()
  const handleFinalSubmit = () => {
    dispatch(setAuthErrorReducer(''))
    const selfieImage = dataURLtoFile(selfie, 'selfie.jpeg')
    if (
      IdVerificationForm.isVerified &&
      licenseVerificationForm.licenseImage
    ) {
      const formData = new FormData()
      IdVerificationForm.phoneNumber && formData.append('phone', IdVerificationForm.phoneNumber)
      formData.append('primary_speciality', IdVerificationForm.speciality)
      formData.append('gov_id_number', IdVerificationForm.idNumber)
      // formData.append('gov_id_proof_front', IdVerificationForm.frontPhoto)
      // formData.append('gov_id_proof_back', IdVerificationForm.backPhoto)
      formData.append('medical_licence_number', licenseVerificationForm.medicalLicenseNumber)
      formData.append('medical_licence_proof', licenseVerificationForm.licenseImage)
      // formData.append('npi_number', licenseVerificationForm.nipNumber)
      // formData.append('selfie', selfieImage)
      formData.append('category', userRoles.healthCare)
      console.log('CompleteHeatlthCareUserRegistration formData:', formData)
      dispatch(registerCompleteHealthUser(formData)).then((res) => {
        if (res.type === 'auth/register/health/rejected') {
          setActiveStep(1)
        }
      })
    }
  }

  // helpers
  const getFormTitle = (step: number) => {
    switch (step) {
      case 1:
        return 'Create Account'
      case 2:
        return 'ID Verification'
      case 3:
        return 'LicenseVerification'
      case 4:
        return 'Upload Selfie'
      default:
        return ''
    }
  }

  useEffect(() => {
    dispatch(setAuthErrorReducer(''))
  }, [])

  return (
    <Box>
      <Header />

      <Box mt={10}>
        <Grid container>
          <Grid xs={12} sm={12} md={3} lg={3.5}>
            {''}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={5}>
            <Typography textAlign='center' variant='h4'>
              {getFormTitle(activeStep)}
            </Typography>
            <Typography textAlign='center' variant='subtitle2'>
              We need to verify your information. <br />
              All data is encrypted end to end
            </Typography>

            <CustomStepper
              length={2}
              active={activeStep}
              title={`Onboarding ${activeStep}/2`}
              onStepClick={setActiveStep}
            />

            <Box mt={1} p={2} className={styles.createAccountFormWrapper}>
              {activeStep === 1 && (
                <IDverification
                  form={IdVerificationForm}
                  onInputChange={onIdVerificationInputChange}
                  loading={loading}
                  error={error}
                  onSubmit={handleIdVerificationSubmit}
                  onFileChange={onIdVerificationFileInput}
                  onDropdownChange={onIDverificationDropdownChange}
                  onVouchedChange={onIdVerificationVouched}
                />
              )}

              {activeStep === 2 && (
                <LicenseVerification
                  form={licenseVerificationForm}
                  onInputChange={onlicenseVerificationInputChange}
                  loading={loading}
                  error={error}
                  onSubmit={handlelicenseVerificationSubmit}
                  onFileChange={onlicenseVerificationFileInput}
                />
              )}

              {/* {activeStep === 3 && (
                <UploadSelfie
                  loading={loading}
                  error={error}
                  selfie={selfie}
                  onSelfieClick={setSelfie}
                  onSubmit={handleFinalSubmit}
                />
              )} */}
            </Box>
            <Box mt={5}></Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CompleteHealthcareUserRegistration
