import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

import SearchIcon from 'src/assets/icons/search.png'

import { debounce } from 'lodash'
import { Box, Grid, Typography } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    color: '#959AA9',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}))

type SearchBarProps = {
  onChangeWithDebounce: (value: string) => void;
  onFilterClick?: () => void;
  isFilterApplied?: boolean;
};

const SearchBar = ({ onChangeWithDebounce, onFilterClick, isFilterApplied }: SearchBarProps) => {
  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeWithDebounce(e.target.value);
  }, 700);

  return (
    <Grid container gap={1} display="flex" direction="row" justifyContent={'space-around'}>
      <Grid item xs={onFilterClick ? 10 : 12}>
        <Search>
          <SearchIconWrapper>
            <img src={SearchIcon} height={16} style={{ zIndex: 1 }} alt="search icon" />
          </SearchIconWrapper>
          <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} onChange={handleSearch} />
        </Search>
      </Grid>

      {onFilterClick && (
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" position="relative">
          <SortIcon
            onClick={onFilterClick}
            sx={{ cursor: 'pointer', height: 24, width: 24, color: isFilterApplied ? 'green' : 'white' }}
          />
          {isFilterApplied && (
            <span
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: 8,
                width: 8,
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchBar
