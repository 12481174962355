import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import { FeedbackPayload, ReportFeedPayload, ReportUserPayload } from './types'

const supportAndFeedbackApi = createApi({
  reducerPath: 'supportAndFeedbackApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['SupportAndFeedback'],
  endpoints: (builder) => ({
    submitFeedback: builder.mutation<void, FeedbackPayload>({
      query: (payload) => ({
        url: '/api/v1/feedback/',
        method: 'POST',
        data: payload,
      }),
      transformErrorResponse: (error: any): string => {
        return error.data.message || 'Opps, something went wrong.'
      },
    }),

    reportFeed: builder.mutation<void, ReportFeedPayload>({
      query: (payload) => ({
        url: '/api/v1/report/',
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          reported_id: payload.feedId,
          reason: payload.reason,
          // eslint-disable-next-line camelcase
          reported_type: 'post',
        },
      }),
      transformErrorResponse: (error: any): string => {
        return error.data.message || 'Opps, something wend wrong.'
      },
    }),

    reportUser: builder.mutation<void, ReportUserPayload>({
      query: (payload) => ({
        url: '/api/v1/report/',
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          reported_id: payload.userId,
          reason: payload.reason,
          // eslint-disable-next-line camelcase
          reported_type: 'user',
        },
      }),
      transformErrorResponse: (error: any): string => {
        return error.data.message || 'Opps, something wend wrong.'
      },
    }),
    
  }),
})

export const { useSubmitFeedbackMutation, useReportFeedMutation , useReportUserMutation} = supportAndFeedbackApi
export default supportAndFeedbackApi
