import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import logo from 'src/assets/images/logo2.png'
import {
  ISidebarMenuItem,
  drawerWidth,
  routeUrls,
  sidebarMenuItems,
  sidebarSettingMenuItems,
} from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { handleDrawerToggle } from 'src/store/features/common/commonSlice'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
// import { logoutUser } from 'src/store/features/auth/authActions'
import { CircularProgress } from '@mui/material'
import { doLogout } from 'src/store/features/auth/authSlice'
interface SidebarProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}
const Sidebar = ({ window }: SidebarProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { loading, role } = useSelector((state: RootState) => state.auth)

  function isActiveLink(item: ISidebarMenuItem): boolean {
    if (location.pathname === item.url) return true
    return false
  }

  const { mobileOpen } = useSelector((state: RootState) => state.common)

  const handleNavigation = (item: ISidebarMenuItem) => {
    if (item.url === routeUrls.logout) {
      dispatch(doLogout())
    } else {
      navigate(item.url)
    }
  }
  const drawer = (
    <Box>
      <Box textAlign='left'>
        <img src={logo} alt='Logo' height={65} />
      </Box>

      <List>
        {sidebarMenuItems.map((item, index) => {
          if (role !== item.role) return null
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => handleNavigation(item)}
                className={isActiveLink(item) ? styles.activeNavItem : styles.inActiveNavItem}
              >
                <ListItemIcon
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>

      <List className={styles.sidebarSettingMenu}>
        {sidebarSettingMenuItems.map((item, index) => {
          if (role !== item.role) return null
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => handleNavigation(item)}
                className={isActiveLink(item) ? styles.activeNavItem : styles.inActiveNavItem}
                disabled={item.url === routeUrls.logout && loading === true}
              >
                <ListItemIcon
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  {item.url === routeUrls.logout && loading === true ? (
                    <CircularProgress size={20} />
                  ) : (
                    item.icon
                  )}
                </ListItemIcon>

                <ListItemText>
                  <span style={{ color: item.color || 'inherit' }}>{item.label}</span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box component='nav' sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        className='appSidebar'
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={() => dispatch(handleDrawerToggle())}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        className='appSidebar'
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar
