import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import {
  ChannelI,
  ChannelListResponse,
  UpdateGroupChannelInfoPayload,
  addUserToGroupPayload,
  makeUserGroupAdminPayload,
  updateChatTimestampPayload,
} from './types'

const chatApi = createApi({
  reducerPath: 'chatApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['Chat'],
  endpoints: (builder) => ({
    getChannelList: builder.query<ChannelI[], any>({
      query: () => ({
        url: '/chat/api/v1/get-channels/',
        method: 'GET',
      }),
      transformResponse: (response: ChannelListResponse) => {
        return response.data
      },
    }),

    createChannel: builder.mutation<void, number[]>({
      query: (userIds) => ({
        url: '/chat/api/v1/channel/',
        method: 'POST',
        data: {
          users: userIds,
        },
      }),
    }),

    updateChatTimestamp: builder.mutation<void, updateChatTimestampPayload>({
      query: ({ channelId, data }) => ({
        url: `/chat/api/v1/channel/${channelId}/add_timestamp/`,
        method: 'POST',
        data: data,
      }),
    }),

    updateGroupChannelInfo: builder.mutation<void, UpdateGroupChannelInfoPayload>({
      query: ({ formData, channelId }) => ({
        url: `/chat/api/v1/channel/${channelId}/change_group_name_icon/`,
        method: 'PATCH',
        data: formData,
      }),
    }),

    deleteChatGroup: builder.mutation<void, string>({
      query: (channelId) => ({
        url: `/chat/api/v1/channel/${channelId}/delete_group/`,
        method: 'DELETE',
      }),
    }),

    addUserToGroup: builder.mutation<void, addUserToGroupPayload>({
      query: ({ userIds, channelId }) => ({
        url: `/chat/api/v1/channel/${channelId}/add_users_to_group/`,
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          user_ids: userIds,
        },
      }),
    }),

    makeUserGroupAdmin: builder.mutation<void, makeUserGroupAdminPayload>({
      query: ({ userId, channelId }) => ({
        url: `/chat/api/v1/channel/${channelId}/make_user_group_admin/`,
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          user_id: userId,
        },
      }),
    }),

    leaveGroup: builder.mutation<void, makeUserGroupAdminPayload>({
      query: ({ userId, channelId }) => ({
        url: `/chat/api/v1/channel/${channelId}/leave_group/`,
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          user_id: userId,
        },
      }),
    }),
  }),
})

export const {
  useGetChannelListQuery,
  useCreateChannelMutation,
  useUpdateChatTimestampMutation,
  useUpdateGroupChannelInfoMutation,
  useDeleteChatGroupMutation,
  useAddUserToGroupMutation,
  useMakeUserGroupAdminMutation,
  useLeaveGroupMutation,
} = chatApi

export default chatApi
