import React, { useState } from 'react'
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { MuiOtpInput } from 'mui-one-time-password-input'

import styles from './index.module.scss'
import { routeUrls } from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import {
  generateOtpApi,
  verifyOtpApi,
} from 'src/store/features/forgotPassword/forgotPasswordAction'
import { setTokenReducer } from 'src/store/features/forgotPassword/forgotPasswordSlice'
import { successToast } from 'src/utils/toastService'
type VerifyOtpProps = {
  callback: () => void
}
const VerifyOTP = (props: VerifyOtpProps) => {
  const [otp, setOtp] = useState<string>('')
  const dispatch = useDispatch<AppDispatch>()
  const { loading, email, error } = useSelector((state: RootState) => state.forgotPassword)

  const onInputChange = (newValue: string) => {
    setOtp(newValue)
  }

  const handleReSendOtp = (e: React.SyntheticEvent) => {
    e.preventDefault()

    dispatch(generateOtpApi({ email: email }))
      .then((res: any) => {
        if (!res.error) {
          setOtp('')
          successToast('OTP sent succssfully.')
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const handleVerifyOtp = () => {
    dispatch(
      verifyOtpApi({
        email,
        otp,
      }),
    ).then((res: any) => {
      if (!res.error) {
        dispatch(setTokenReducer(res?.payload?.data?.token || ''))
        props.callback()
      }
    })
  }

  return (
    <Box>
      <Box className={styles.title}>
        <Typography variant='h3'> Verification</Typography>
        <Typography variant='subtitle2'>
          A 6 digit PIN has been sent to your email. <br /> Enter the code to continue.{' '}
          <Link to={routeUrls.login} onClick={handleReSendOtp}>
            {' '}
            Resend code?
          </Link>
        </Typography>
      </Box>
      <Box className={styles.formWrapper}>
        <form>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
            p={10}
            height='100%'
            className={styles.wrapperNestedDiv}
          >
            <Grid item>
              <MuiOtpInput
                value={otp}
                onChange={onInputChange}
                length={6}
                validateChar={(value) => {
                  return !isNaN(Number(value))
                }}
                TextFieldsProps={{ size: 'small', placeholder: '_' }}
              />
              <Typography p={1} variant='subtitle2' color='red' textAlign='center'>
                {' '}
                {error}{' '}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant='contained'
                color='success'
                fullWidth
                onClick={handleVerifyOtp}
                className={otp?.length < 6 || loading ? 'buttonDisabled' : ''}
                disabled={otp?.length < 6 || loading}
              >
                {loading ? <CircularProgress size={25} /> : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}

export default VerifyOTP
