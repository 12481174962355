import React, { useState } from 'react'
import { Box, Button, Popover, Typography, Grid, CircularProgress } from '@mui/material'
import CustomizedDialogBox from 'src/Component/CustomizedDialogBox'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useDeleteMyFeedMutation } from 'src/api/createFeed'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'
type FeedMenuActionsProps = {
  isPopoverOpen: boolean
  anchorEl: HTMLButtonElement | null
  onPopoverClose: () => void
  feedId: number
  refetchData: () => void
}

const FeedMenuActions = ({
  isPopoverOpen,
  anchorEl,
  onPopoverClose,
  feedId,
  refetchData,
}: FeedMenuActionsProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [deleteFeed, { isLoading }] = useDeleteMyFeedMutation()
  const navigate = useNavigate()

  const handleOpenDeleteConfirmation = () => {
    setOpen(true)
    onPopoverClose()
  }

  const onDeleteConfirm = () => {
    deleteFeed(feedId).then(() => {
      refetchData()
      setOpen(false)
    })
  }

  const goToEditFeed = () => {
    navigate(routeUrls.editFeed.replace(':feedId', feedId.toString()))
  }
  return (
    <Box>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography
          alignItems='center'
          sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
          color='red'
          onClick={handleOpenDeleteConfirmation}
        >
          <DeleteIcon /> &nbsp; Delete Post
        </Typography>

        <Typography
          alignItems='center'
          sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
          color='white'
          onClick={goToEditFeed}
        >
          <EditIcon /> &nbsp; Edit Post
        </Typography>
      </Popover>

      <CustomizedDialogBox open={open} onClose={() => setOpen(false)}>
        <Box>
          <Typography variant='h3' textAlign='center' mb={5}>
            {' '}
            Are you sure to delete this post?
          </Typography>
          <Grid container>
            <Grid xs={6} p={2}>
              <Button
                type='button'
                onClick={onDeleteConfirm}
                variant='contained'
                color='success'
                fullWidth
                disabled={isLoading}
                className={isLoading ? 'buttonDisabled' : ''}
              >
                {isLoading ? <CircularProgress size={25} /> : 'Confirm'}
              </Button>
            </Grid>
            <Grid xs={6} p={2}>
              <Button
                type='button'
                onClick={() => setOpen(false)}
                variant='contained'
                color='error'
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomizedDialogBox>
    </Box>
  )
}

export default FeedMenuActions
