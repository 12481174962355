import React from 'react'
type IconProps = {
  color?: string
}
const FeedbackIcon = ({ color = '#909090' }: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.8444 4C12 4 9.68889 6.31111 9.68889 9.15556C9.68889 9.35111 9.70667 9.52889 9.72444 9.72444C9.54667 9.70667 9.35111 9.68889 9.15556 9.68889C6.31111 9.68889 4 12 4 14.8444C4 17.6889 6.31111 20 9.15556 20C12 20 14.3111 17.6889 14.3111 14.8444C14.3111 14.6489 14.2933 14.4711 14.2756 14.2756C14.4533 14.2933 14.6489 14.3111 14.8444 14.3111C17.6889 14.3111 20 12 20 9.15556C20 6.31111 17.6889 4 14.8444 4ZM9.15556 19.2889C6.70222 19.2889 4.71111 17.2978 4.71111 14.8444C4.71111 12.3911 6.70222 10.4 9.15556 10.4C11.6089 10.4 13.6 12.3911 13.6 14.8444C13.6 17.2978 11.6089 19.2889 9.15556 19.2889ZM14.8444 13.6C14.6133 13.6 14.3644 13.5822 14.1511 13.5467C14.0089 12.9956 13.7778 12.4622 13.4578 12C13.4578 11.9822 13.44 11.9644 13.4222 11.9644C13.44 11.2 14.08 10.5778 14.8444 10.5778C15.6267 10.5778 16.2667 11.2178 16.2667 12C16.2667 12.1956 16.4267 12.3556 16.6222 12.3556C16.8178 12.3556 16.9778 12.1956 16.9778 12C16.9778 10.8267 16.0178 9.86667 14.8444 9.86667C13.9378 9.86667 13.1556 10.4356 12.8533 11.2533C12.2133 10.5956 11.3778 10.0978 10.4533 9.84889C10.4178 9.61778 10.4 9.38667 10.4 9.15556C10.4 6.70222 12.3911 4.71111 14.8444 4.71111C17.2978 4.71111 19.2889 6.70222 19.2889 9.15556C19.2889 11.6089 17.2978 13.6 14.8444 13.6ZM11.2889 16.0889C11.2889 17.2622 10.3289 18.2222 9.15556 18.2222C7.98222 18.2222 7.02222 17.2622 7.02222 16.0889C7.02222 15.8933 7.18222 15.7333 7.37778 15.7333C7.57333 15.7333 7.73333 15.8933 7.73333 16.0889C7.73333 16.8711 8.37333 17.5111 9.15556 17.5111C9.93778 17.5111 10.5778 16.8711 10.5778 16.0889C10.5778 15.8933 10.7378 15.7333 10.9333 15.7333C11.1289 15.7333 11.2889 15.8933 11.2889 16.0889Z'
        fill={color}
      />
    </svg>
  )
}

export default FeedbackIcon
