import React, { useState } from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  IconButton,
  Popover,
  Badge,
  Input,
  Button,
} from '@mui/material'
import styles from '../index.module.scss'
import UserProfileIcon from 'src/assets/icons/svg/UserProfileIcon'
import InfoIcon from 'src/assets/icons/svg/InfoIcon'
import deleteIcon from 'src/assets/icons/deleteIcon.png'
import pencilIcon from 'src/assets/icons/pencil.png'
import editIcon from 'src/assets/icons/edit-profile.png'
import cameraIcon from 'src/assets/icons/camera.png'

import personIconFilled from 'src/assets/icons/personIconFIlled.png'
import personIcon from 'src/assets/icons/personIcon.png'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import ConnectionRow from '../NewChatPopUp/OneToOneChat/ConnectionRow'
import {
  useDeleteChatGroupMutation,
  useMakeUserGroupAdminMutation,
  useUpdateGroupChannelInfoMutation,
} from 'src/api/chat'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import {
  endSingleOrGroupChat,
  markUserAsAdmin,
  refetchChannelList,
  setChatInfo,
  UserI,
} from 'src/store/features/chat/chatSlice'
import InputField from 'src/Component/InputField'
import { errorToast } from 'src/utils/toastService'
type GroupInfoProps = {
  onAddConnectionClick: () => void
}
const GroupInfo = ({ onAddConnectionClick }: GroupInfoProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [selectedUserForAdmin, setSelectedUserForAdmin] = useState<number>(0)
  const { chatInfo, toUsersArr, fromUser, channelId } = useSelector(
    (state: RootState) => state.chat,
  )
  const [isEditGroupInfo, setIsEditGroupInfo] = useState(false)
  const [groupName, setGroupName] = useState<string>(chatInfo?.name || '')
  const [groupIcon, setGroupIcon] = useState<File | null | string>(chatInfo?.icon || null)
  const dispatch = useDispatch<AppDispatch>()
  const [deleteChatGroup] = useDeleteChatGroupMutation()
  const [makeUserGroupAdmin] = useMakeUserGroupAdminMutation()
  const [updateGroupInfo] = useUpdateGroupChannelInfoMutation()
  const isAdmin = !!fromUser?.isAdmin || false
  const menus = [
    {
      label: 'Info',
      icon: <InfoIcon />,
    },
    {
      label: 'Conections',
      icon: <UserProfileIcon />,
    },
  ]
  const handleDeleteGroup = () => {
    if (!isAdmin) return
    dispatch(startPageLoader())
    deleteChatGroup(channelId)
      .unwrap()
      .then(() => {
        dispatch(endSingleOrGroupChat())
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleContextMenuOnConnectionRow = (e: any, user: UserI) => {
    e.preventDefault()
    if (!isAdmin || !user || !!user.isAdmin) return
    setSelectedUserForAdmin(user.id)
    setAnchorEl(e.currentTarget)
  }

  const handleMakeUserGroupAdmin = () => {
    if (!isAdmin) return
    dispatch(startPageLoader())
    makeUserGroupAdmin({
      channelId,
      // eslint-disable-next-line camelcase
      userId: selectedUserForAdmin,
    })
      .unwrap()
      .then(() => {
        dispatch(markUserAsAdmin(selectedUserForAdmin))
        setSelectedUserForAdmin(0)
        setAnchorEl(null)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handleGroupIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setGroupIcon(e.target.files[0])
    }
  }

  const handleUpdateGroupInfo = () => {
    if (!groupName) {
      errorToast('Please enter group name.')
      return
    }

    if (!groupIcon) {
      errorToast('Please upload group icon.')
      return
    }
    const formData = new FormData()
    formData.append('channel_name', groupName)
    if (typeof groupIcon !== 'string') {
      formData.append('channel_icon', groupIcon)
    }
    dispatch(startPageLoader())
    updateGroupInfo({
      formData,
      channelId: channelId,
    })
      .unwrap()
      .then((res: any) => {
        if (res.success && res.data) {
          dispatch(
            setChatInfo({
              name: res.data.channel_name,
              icon: res.data.channel_icon,
            }),
          )
          setIsEditGroupInfo(false)
          dispatch(refetchChannelList())
        } else {
          errorToast('Unable to update group info.')
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handleEditCancel = () => {
    setGroupName(chatInfo?.name || '')
    setGroupIcon(chatInfo?.icon || null)
    setIsEditGroupInfo(false)
  }
  return (
    <Grid container>
      <Grid xs={4}>
        <List>
          {menus.map((item, index) => {
            return (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => setActiveIndex(index)}
                  className={activeIndex === index ? styles.activeNavItem : styles.inActiveNavItem}
                >
                  <ListItemIcon
                    sx={{
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid xs={8} p={2} height={450}>
        {activeIndex === 0 && (
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            height={'100%'}
            alignItems='center'
          >
            <Grid>
              {isEditGroupInfo ? (
                <Box>
                  <Grid container alignItems='center'>
                    <Grid xs={3}>
                      <Box textAlign='center'>
                        <Badge
                          overlap='circular'
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <label htmlFor='userImage' style={{ cursor: 'pointer' }}>
                              <img src={editIcon} height={15} width={15} />
                              <Input
                                type='file'
                                onChange={handleGroupIconChange}
                                inputProps={{ accept: 'image/*' }}
                                sx={{ display: 'none' }}
                                name='userImage'
                                id='userImage'
                              />
                            </label>
                          }
                        >
                          <Avatar
                            src={(() => {
                              if (groupIcon) {
                                if (typeof groupIcon === 'string') return groupIcon
                                return URL.createObjectURL(groupIcon)
                              }
                              return cameraIcon
                            })()}
                            variant='circular'
                            sx={{
                              width: 45,
                              height: 45,
                              border: '1px solid rgba(52, 214, 149, 1)',
                            }}
                          />
                        </Badge>
                      </Box>
                    </Grid>
                    <Grid xs={9}>
                      <InputField
                        name='groupName'
                        id='groupName'
                        type='text'
                        placeholder='Group Name'
                        value={groupName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setGroupName(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems='stretch' justifyContent='center' mt={1}>
                    <Grid item p={2}>
                      <Button
                        onClick={handleUpdateGroupInfo}
                        type='button'
                        variant='contained'
                        color='success'
                      >
                        Save
                      </Button>
                    </Grid>

                    <Grid item p={2}>
                      <Button
                        onClick={handleEditCancel}
                        type='button'
                        variant='contained'
                        color='error'
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <Avatar
                    alt={chatInfo?.name || ''}
                    src={chatInfo?.icon || 'broken-image.png'}
                    sx={{
                      height: 144,
                      width: 144,
                      border: '1px solid rgba(52, 214, 149, 1)',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />

                  <Typography mt={2} variant='h5' textAlign='center'>
                    {' '}
                    {chatInfo?.name}{' '}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid>
              {!isEditGroupInfo && (
                <Grid container>
                  {isAdmin && (
                    <Typography
                      alignItems='center'
                      sx={{ display: 'flex', cursor: 'pointer' }}
                      onClick={handleDeleteGroup}
                      color='white'
                      p={2}
                    >
                      <img
                        src={deleteIcon}
                        height={20}
                        alt='delete group'
                        style={{ paddingRight: '10px' }}
                      />
                      Delete Group
                    </Typography>
                  )}

                  <Typography
                    alignItems='center'
                    sx={{ display: 'flex', cursor: 'pointer' }}
                    onClick={() => setIsEditGroupInfo(true)}
                    color='white'
                    p={2}
                  >
                    <img
                      src={pencilIcon}
                      height={20}
                      alt='edit info'
                      style={{ paddingRight: '10px' }}
                    />
                    Edit Group info
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {activeIndex === 1 && (
          <Box>
            {isAdmin && (
              <Grid
                container
                alignItems='center'
                p={2}
                sx={{ cursor: 'pointer' }}
                onClick={onAddConnectionClick}
              >
                <Grid item>
                  <IconButton sx={{ padding: 0 }}>
                    <img src={personIconFilled} />
                  </IconButton>
                </Grid>

                <Grid item>
                  <Typography variant='subtitle1' fontWeight={400} fontSize={14} pl={2}>
                    Add Connect
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Typography variant='subtitle1' fontWeight={600} fontSize={16} color='gray'>
              Connections
            </Typography>

            <Box sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
              {[fromUser, ...toUsersArr].map((connection) => {
                if (!connection) return null
                return (
                  <Box
                    key={connection.id}
                    onContextMenu={(e) => handleContextMenuOnConnectionRow(e, connection)}
                  >
                    <ConnectionRow
                      userId={connection.id}
                      name={connection.firstName + ' ' + connection.lastName}
                      profilePic={connection.profilePic}
                      showAdminTag={!!connection.isAdmin}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}

        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Typography
            alignItems='center'
            sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
            onClick={handleMakeUserGroupAdmin}
            color='white'
          >
            <img src={personIcon} height={20} alt='Flag post' style={{ paddingRight: '10px' }} />
            Make group admin
          </Typography>
        </Popover>
      </Grid>
    </Grid>
  )
}

export default GroupInfo
