/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react'
import Header from 'src/Layout/HomeLayout/Header'
import { Box, Grid, Typography, CircularProgress, Tab, Divider } from '@mui/material'
import SearchBar from 'src/Component/SearchBar'
import Feed, { UpdatedFeed } from 'src/Component/Feed'
import { useLazyGetMySavedFeedsQuery } from 'src/api/userProfile'
import styles from './index.module.scss'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { Feed as FeedType } from 'src/api/feed/types'
import InfiniteScroll from 'react-infinite-scroll-component'

const MySavedFeeds = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { userInfo } = useSelector((state: RootState) => state.auth)

  const [searchValue, setSearchValue] = useState<string>('')

  const [paginatedData, setPaginatedData] = useState<FeedType[]>([])
  const [metaData, setMetaData] = useState({
    hasMore: true,
  })

  const pageRef = useRef<number>(1)
  const [fetchFeeds] = useLazyGetMySavedFeedsQuery()

  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }

  const fetchData = (isSearchResult = false): void => {
    if (isSearchResult) {
      pageRef.current = 1
    }
    dispatch(startPageLoader())
    fetchFeeds({
      search: searchValue,
      page: pageRef.current,
    })
      .unwrap()
      .then((res) => {
        setMetaData({
          hasMore: !!res.next,
        })
        if (isSearchResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
        } else {
          setPaginatedData([
            ...JSON.parse(JSON.stringify(paginatedData)),
            ...JSON.parse(JSON.stringify(res.results)),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handleUpdateFeed = (updatedFeed: UpdatedFeed) => {
    const newState = paginatedData.map((feed) => {
      if (feed.id === updatedFeed.id) {
        feed.is_reacted = updatedFeed.isReacted
        feed.is_saved = updatedFeed.isSaved
        feed.like_count = updatedFeed.likeCount
      }
      return feed
    })
    setPaginatedData(JSON.parse(JSON.stringify(newState)))
  }

  useEffect(() => {
    // on search
    fetchData(true)
  }, [searchValue])

  useEffect(() => {
    // fetch feeds on page load
    pageRef.current = 1
    fetchData()
  }, [])

  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Grid container justifyContent='center'>
      
              <Box className={styles.wrapper}>
                <SearchBar onChangeWithDebounce={setSearchValue} />
              </Box>
       
          </Grid>
        </Box>

        <Box width='100%' pr={3} pl={3}>
          <Box mt={1}>
            <Typography variant='subtitle1' fontSize='24px'>
              Saved Posts
            </Typography>
          </Box>
        </Box>
      </Header>

      {paginatedData.length === 0 ? (
        <Box textAlign='center'>
          <Typography variant='h5'> No feed found </Typography>
        </Box>
      ) : (
        <Box>
          <Grid container mt={3} justifyContent='center'>
        
              <Box className={styles.wrapper}>
                <InfiniteScroll
                  dataLength={paginatedData.length}
                  next={loadNextPage}
                  hasMore={metaData.hasMore}
                  loader={
                    <Typography variant='subtitle1' textAlign='center'>
                      {' '}
                      loading more feeds..{' '}
                    </Typography>
                  }
                >
                  {paginatedData.map((feed) => {
                    return (
                      <Feed
                        onUpdateFeed={handleUpdateFeed}
                        key={feed.id}
                        id={feed.id}
                        author={{
                          id: feed.author.id,
                          name: feed.author.name,
                          profilePic: feed.author.profile_picture,
                        }}
                        isPrivateForAllConnections={feed.is_public_for_all_connections}
                        isPrivateForSelectedConnections={feed.is_public_for_added_connections}
                        images={feed.images}
                        likeCount={feed.like_count}
                        createdAt={feed.created_at}
                        isReacted={feed.is_reacted}
                        isSaved={feed.is_saved}
                        title={feed.title}
                        description={feed.details}
                        tags={feed.tags}
                        isPhotoBlur={false}
                        isChatVisible={!!feed.is_connected && feed.author.id !== userInfo?.id}
                        isLongDescription={feed.is_long_description}
                        sections={feed.sections}
                      />
                    )
                  })}
                </InfiniteScroll>
              </Box>
       
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default MySavedFeeds
