import React from 'react'
import { Box, Grid, Avatar, Typography, Button, CircularProgress } from '@mui/material'
import styles from './index.module.scss'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

type ConnectionRequestComponentProps = {
  title: string | null
  firstName: string
  lastName: string
  profilePic?: string
  createdAt: string
  onAccept?: () => void
  onReject?: () => void
  isLoading?: boolean
}
const ConnectionRequestComponent = ({
  title = '',
  firstName = '',
  lastName = '',
  profilePic,
  createdAt,
  onAccept,
  onReject,
  isLoading,
}: ConnectionRequestComponentProps) => {
  const name = `${title || ''} ${firstName} ${lastName}`

  if (isLoading) {
    return (
      <Box p={2} pb={0} textAlign='center'>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box p={2} pb={0}>
      <Box>
        <Typography p={1} pb={0} variant='subtitle2' fontSize='0.7rem' textAlign={'right'}>
          {dayjs(createdAt).fromNow()}
        </Typography>
      </Box>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid xs={12} sm={6} md={6} mb={2}>
          <Grid container alignItems='center' justifyContent='center'>
            <Grid item>
              <Avatar
                alt={firstName}
                src={profilePic || 'broken-image.png'}
                className={styles.feedAvatar}
              />
            </Grid>
            <Grid item pl={1}>
              <Typography variant={'h5'}>{name}</Typography>
              <Typography variant='subtitle2' fontSize='0.7rem'>
                requests <b>Connection</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} sm={6} md={6} mb={2}>
          <Grid container spacing={1} justifyContent='center'>
            <Grid item xs={6} pb={0}>
              <Button
                type='button'
                variant='contained'
                color='success'
                onClick={onAccept}
                disabled={isLoading} 
                sx={{ height:'48px'}}
                fullWidth
              >
                Accept
              </Button>
            </Grid>
            <Grid item xs={6}  pb={0}>
              <Button
                type='button'
                variant='contained'
                color='error'
                onClick={onReject}
                disabled={isLoading}
                sx={{ height:'48px'}}
                fullWidth
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConnectionRequestComponent
