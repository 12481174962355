import React, { useState } from 'react'
import { Box, Typography, Grid, Tabs, Tab, Divider, CircularProgress } from '@mui/material'
import Header from 'src/Layout/HomeLayout/Header'
import styles from './index.module.scss'

import BasicInfo from 'src/Component/UserProfile/BasicInfo'
import { useGetUserProfileByIdQuery } from 'src/api/userProfile'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import LikedPosts from 'src/Component/UserProfile/LikedPosts'

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState<0>(0)
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const { data: userProfileInfo, isLoading: isProfileInfoLoading } = useGetUserProfileByIdQuery(
    userInfo?.id || 0,
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: 0) => {
    setActiveTab(newValue)
  }

  const tabs = ['Liked Posts']

  if (isProfileInfoLoading) {
    return (
      <Box textAlign='center'>
        <CircularProgress />{' '}
      </Box>
    )
  }

  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Box mt={1} >
            <Typography variant='subtitle1' fontSize='24px'>
              Profile
            </Typography>
          </Box>
        </Box>
      </Header>

      <Box mt={3}>
        <Grid container justifyContent='center'>
      
            <Box className={styles.formWrapper}>
              <Box>
                {userProfileInfo && (
                  <BasicInfo
                    userId={userInfo?.id || 0}
                    profilePic={userProfileInfo.selfie}
                    title={userProfileInfo.title}
                    firstName={userProfileInfo.first_name}
                    lastName={userProfileInfo.last_name}
                    email={userProfileInfo.email}
                    phone={userProfileInfo.phone}
                    isPublicProfile={userProfileInfo.is_public_profile}
                    isOwnProfile
                    isConnected={false}
                  />
                )}

                <Box mt={2}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    centered
                    // variant='fullWidth'
                    textColor='primary'
                    indicatorColor='secondary'
                  >
                    {tabs.map((tab, i) => {
                      return (
                        <Tab
                          key={tab}
                          label={tab}
                          sx={{
                            color: activeTab === i ? '#FFFEFE !important' : '#909090',
                            width: '250px',
                          }}
                        />
                      )
                    })}
                  </Tabs>
                  <Divider />
                </Box>
              </Box>
              <Box>{activeTab === 0 && <LikedPosts />}</Box>
            </Box>
      
        </Grid>
      </Box>
    </Box>
  )
}

export default MyProfile
