import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography, Box, Grid } from '@mui/material'
import InputField from 'src/Component/InputField'

export type ImageAnnotationProps = {
  open: boolean
  onClose: () => void
  data: {
    image: File
    annotation: string
    index: number
  } | null
  onSave: (annotation: string, index: number) => void
}
export default function ImageAnnotation({ open, onClose, data, onSave }: ImageAnnotationProps) {
  const [showInputField, setShowInputField] = React.useState(false)
  const [annotation, setAnnotation] = React.useState('')

  const handleClose = () => {
    setShowInputField(false)
    setAnnotation('')
    onClose()
  }

  const handleSubmit = () => {
    if (data) {
      onSave(annotation, data.index)
      setShowInputField(false)
      setAnnotation('')
      onClose()
    }
  }

  React.useEffect(() => {
    setShowInputField(!!data?.annotation)
    setAnnotation(data?.annotation || '')
  }, [data])
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant='h4'>Annotate Image</Typography>
      </DialogTitle>
      {data && (
        <DialogContent>
          <Box p={2} pb={0}>
            <Box height='220px'>
              <img
                src={URL.createObjectURL(data.image)}
                style={{
                  height: '95%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
            </Box>

            <Box>
              {showInputField ? (
                <InputField
                  name='annotation'
                  id='annotation'
                  type='text'
                  placeholder='Annotation'
                  value={annotation}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAnnotation(e.target.value)
                  }
                  required
                />
              ) : (
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  onClick={() => setShowInputField(true)}
                  // disabled={loading}
                  // className={loading ? 'buttonDisabled' : ''}
                >
                  {/* {loading ? <CircularProgress size={25} /> : 'Login'} */}
                  <b>Annotate</b>
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Grid container justifyContent='center' alignItems='center' p={2} pt={0}>
          <Grid item xs={6} p={2} pt={0}>
            <Button
              type='submit'
              variant='contained'
              color='success'
              fullWidth
              onClick={handleSubmit}
            >
              Done
            </Button>
          </Grid>
          <Grid item xs={6} p={2} pt={0}>
            <Button
              sx={{
                border: 'none',
                fontWeight: 500,
              }}
              type='submit'
              variant='outlined'
              color='error'
              fullWidth
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
