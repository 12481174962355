import React, { useState } from 'react'
import { Popover } from '@mui/material'
import styles from '../index.module.scss'

import { useGetMyConnectionsQuery } from 'src/api/createFeed'
import OneToOneChat from './OneToOneChat'
import GroupChat from './GroupChat'

type NewChatOrGroupPopUpProps = {
  anchorEl: HTMLButtonElement | null
  onClose: () => void
}
const NewChatOrGroupPopUp = ({ anchorEl, onClose }: NewChatOrGroupPopUpProps) => {
  const [showGroupChat, setShowGroupChat] = useState(false)
  const { data, isLoading: isFetchingConnections } = useGetMyConnectionsQuery()

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          className: styles.newChatPopUp,
        },
      }}
    >
      {showGroupChat ? (
        <GroupChat
          isFetchingConnections={isFetchingConnections}
          connectionsArr={data || []}
          onClose={onClose}
        />
      ) : (
        <OneToOneChat
          isFetchingConnections={isFetchingConnections}
          onClose={onClose}
          connectionsArr={data || []}
          onGroupChatClick={() => setShowGroupChat(true)}
        />
      )}
    </Popover>
  )
}

export default NewChatOrGroupPopUp
