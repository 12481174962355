/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import Header from 'src/Layout/HomeLayout/Header'
import StyledFileInputComponent from 'src/Component/StyledFileInputComponent'
import InputField from 'src/Component/InputField'
import Dropdown from 'src/Component/Dropdown'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import del from 'src/assets/icons/svg/del.svg'
import {
  useCreateFeedMutation,
  useGetMyConnectionsQuery,
  useGetSpecialtyListQuery,
  useGetTagsQuery,
  useLazyGetFeedByIdQuery,
  useRemoveInappropriateContentFromImageMutation,
  useUpdateFeedMutation,
} from 'src/api/createFeed'
import styles from './index.module.scss'
import Carousel from 'react-material-ui-carousel'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  Tooltip,
  Switch,
  Radio,
  Modal,
  IconButton,
} from '@mui/material';
import { errorToast, successToast } from 'src/utils/toastService';
import CarouselIndicator from 'src/Component/Feed/CarouselIndicator';
import ImageAnnotation from './ImageAnnotation';
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice';
import { convertBase64ToFile } from 'src/utils/helpers';
import axios from 'axios';
import AutoCompleteInput from 'src/Component/AutocompleteInput';
import { routeUrls } from 'src/utils/constants';
import LongDescription from './LongDescription';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Info from 'src/assets/icons/svg/Info';

const initialLongDescription: Section[] = [
  {
    id: 0,
    title: 'Case History/Exam',
    description: '',
  },
  {
    id: 1,
    title: 'Diagnosis',
    description: '',
  },
  {
    id: 2,
    title: 'Management',
    description: '',
  },
  {
    id: 3,
    title: 'Outcome',
    description: '',
  }
];

export const longDescriptionIndexToPlaceholderMapping = {
  0:'Please enter the case history/exam details here.',
  1:'Please enter the diagnosis details here.',
  2:'Please enter the management details here.',
  3:'Please enter the outcome details here.'
}

export type Section = {
  id: number;
  title: string;
  description: string;
};

type CreateFeedFormType = {
  images: {
    image: File;
    annotations: string;
  }[];
  title: string;
  description: string;
  tags: any[];
  connections: any[];
  isPrivateForAllConnections: boolean;
  isPrivateForSelectedConnections: boolean;
  doesImageContainGraphicMaterial: boolean;
  isAnonymousPost: boolean;
  descriptionMode: 'single' | 'multiple';
  sections: Section[];
};

const initialFormState: CreateFeedFormType = {
  images: [],
  title: '',
  description: '',
  tags: [],
  connections: [],
  isPrivateForAllConnections: false,
  isPrivateForSelectedConnections: false,
  doesImageContainGraphicMaterial: false,
  isAnonymousPost: false,
  descriptionMode: 'single',
  sections: initialLongDescription,
};

type imageAnnotationInfoI = {
  open: boolean;
  data: {
    image: File;
    annotation: string;
    index: number;
  } | null;
};

export interface IDropDownData {
  label: string;
  value: string;
  picture?: string;
}

export interface IDropDownDataExtended extends IDropDownData {
  subspecialtyList: {
    name: string;
    id: string;
    selected: boolean;
  }[];
}

const CreateFeed = () => {
  const { data: tagOptions = [] } = useGetTagsQuery();
  const [queryParam, setQueryParam] = useState('');
  const { data: specialtyList = [] } = useGetSpecialtyListQuery(queryParam);
  const { data: connectionOptions = [] } = useGetMyConnectionsQuery();
  const [createFeedMutation, { isLoading, error }] = useCreateFeedMutation();
  const [removeInappropriateContentFromImage] = useRemoveInappropriateContentFromImageMutation();
  const [updateFeedMutation, { isLoading: isUpdating, error: updateError }] = useUpdateFeedMutation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [form, setForm] = useState<CreateFeedFormType>(JSON.parse(JSON.stringify(initialFormState)));
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);

  const params = useParams();
  const editFeedId = Number(params.feedId) || 0;

  const [fetchFeedById, editFeedData] = useLazyGetFeedByIdQuery();

  const [imageAnnotationInfo, setImageAnnotationInfo] = useState<imageAnnotationInfoI>({
    open: false,
    data: null,
  });

  const numberOfRender = useRef(0);
  useEffect(() => {
    if (numberOfRender.current == 0 && specialtyList && specialtyList.length > 0 && editFeedData.data) {
      const selectSpecialty: IDropDownDataExtended[] = (editFeedData.data.speciality || [])
        .map(item => {
          const found = specialtyList.find(speciality => speciality.id == item);
          if (!found) return null;

          const subspecialtyList = found.have_sub_speciality
            ? (found.sub_specialities || []).map(subItem => ({
                ...subItem,
                selected: editFeedData.data.sub_speciality.includes(subItem.id),
              }))
            : [];

          return {
            label: found.name,
            value: found.id.toString(),
            haveSubspecialty: found.have_sub_speciality,
            subspecialtyList,
          };
        })
        .filter(Boolean);

      setSelectedSpeciality(selectSpecialty);
      numberOfRender.current = 1;
    }
  }, [editFeedData, specialtyList]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const _images = form.images;
    if (_images.length >= 30) {
      errorToast('Maximum 30 files are allowed.');
      return;
    }

    dispatch(startPageLoader());
    removeInappropriateContentFromImage(e.target.files[0])
      .unwrap()
      .then((res: any) => {
        console.log(res);
        if (res && res.status === 'success' && res.transform) {
          const file = convertBase64ToFile(
            `data:${res.transform['content-type']};base64,${res.transform.base64}`,
            res.media.url,
          );
          if (file) {
            _images.push({
              image: file,
              annotations: '',
            });
            setForm({
              ...form,
              images: _images,
            });
          } else {
            errorToast('Unable to select this image.');
          }
        } else {
          errorToast('Unable to select this image.');
        }
      })
      .catch(err => {
        console.log(err, 'err');
        errorToast('Unable to select this image.');
      })
      .finally(() => {
        dispatch(stopPageLoader());
      });
  };

  const onDropdownChange = (name: string, value: any) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => {
      const newState: any = { ...prevState };
      newState[event.target.name] = event.target.checked;
      if (event.target.name === 'isPrivateForAllConnections' && event.target.checked === true) {
        newState.connections = [];
      }
      return newState;
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (form.images.length === 0) {
      errorToast('Image is required.');
      return;
    }
    if (!form.title) {
      errorToast('Title is required.');
      return;
    }
    if (form.descriptionMode == 'single' && !form.description) {
      errorToast('Description is required.');
      return;
    }
    if (form.isPrivateForSelectedConnections === true && form.connections.length === 0) {
      errorToast('Connections are required.');
      return;
    }
    const payload = new FormData();
    payload.append('author', userInfo?.id.toString() || '');
    payload.append('is_public_for_added_connections', form.isPrivateForSelectedConnections ? 'true' : 'false');
    payload.append('is_public_for_all_connections', form.isPrivateForAllConnections ? 'true' : 'false');
    payload.append('is_graphic', form.doesImageContainGraphicMaterial ? 'true' : 'false');
    payload.append('title', form.title);
    payload.append('is_anonymous', form.isAnonymousPost ? 'true' : 'false');
    selectedSpeciality?.forEach((item: IDropDownDataExtended) => {
      const { value } = item;
      payload.append('speciality', value);
      (item.subspecialtyList ?? []).forEach(subItem => {
        if (subItem.selected) {
          payload.append('sub_speciality', subItem.id);
        }
      });
    });
    form.tags.forEach(tag => {
      payload.append('tags', tag);
    });
    form.connections.forEach(connection => {
      payload.append('connections', connection);
    });
    if (form.images && form.images.length) {
      for (let i = 0; i < form.images.length; i++) {
        payload.append('images', form.images[i].image);
        payload.append('annotations', form.images[i].annotations);
      }
    }

    if (form.descriptionMode == 'single') {
      payload.append('is_long_description', 'false');
      payload.append('details', form.description);
    } else if (form.descriptionMode == 'multiple') {
      payload.append('is_long_description', 'true');
      form.sections.forEach((item, idx) => {
        payload.append(`sections_data[${idx}]title`, item.title);
        payload.append(`sections_data[${idx}]description`, item.description);
      });
    }

    if (editFeedId && typeof editFeedId === 'number' && editFeedId > 0) {
      // return console.log('editFeedId', form.images);
      updateFeedMutation({
        data: payload,
        id: editFeedId,
      })
        .unwrap()
        .then(res => {
          setForm({
            ...initialFormState,
            images: [],
          });

          successToast('Post updated successfully.');
          setActiveImageIndex(0);
          navigate(-1);
        });
    } else {
      createFeedMutation(payload)
        .unwrap()
        .then(res => {
          setForm({
            ...initialFormState,
            images: [],
          });
          setSelectedSpeciality([]);
          successToast('Post Created successfully.');
          setActiveImageIndex(0);
          navigate(routeUrls.home);
        });
    }
  };

  const handleImageAnnotationClose = () => {
    setImageAnnotationInfo({
      ...imageAnnotationInfo,
      open: false,
    });
  };

  const handleImageAnnotationOpen = (image: File, annotation: string, index: number) => {
    setImageAnnotationInfo({
      open: true,
      data: {
        image,
        annotation,
        index,
      },
    });
  };

  const onAnnotationSave = (annotation: string, index: number) => {
    const _images = form.images;

    _images[index].annotations = annotation;
    setForm({
      ...form,
      images: _images,
    });
  };

  const handleDeleteImage = (image: any, index: number) => {
    const _images = form.images;
    console.log('id', _images);
    _images.splice(index, 1);
    setForm({
      ...form,
      images: _images,
    });

    console.log('id', _images, form?.images, index);
  };

  useEffect(() => {
    if (editFeedId && typeof editFeedId === 'number' && editFeedId > 0) {
      fetchFeedById(editFeedId);
    } else {
      setForm({
        ...initialFormState,
        images: [],
      });
      setActiveImageIndex(0);
    }
  }, [editFeedId]);

  useEffect(() => {
    if (editFeedData.data) {
      const isPrivateForAllConnections = (() => {
        if (
          editFeedData.data.is_public_for_all_connections === true &&
          editFeedData.data.is_public_for_added_connections === true
        ) {
          return editFeedData.data.connections.length === 0;
        }
        return editFeedData.data.is_public_for_all_connections;
      })();

      const isPrivateForSelectedConnections = (() => {
        if (
          editFeedData.data.is_public_for_all_connections === true &&
          editFeedData.data.is_public_for_added_connections === true
        ) {
          return editFeedData.data.connections.length > 0;
        }
        return editFeedData.data.is_public_for_added_connections;
      })();

      setForm({
        title: editFeedData.data.title,
        description: editFeedData.data.details,
        doesImageContainGraphicMaterial: editFeedData.data.is_graphic,
        isPrivateForAllConnections: isPrivateForAllConnections,
        isPrivateForSelectedConnections: isPrivateForSelectedConnections,
        isAnonymousPost: editFeedData.data.is_anonymous,
        connections: isPrivateForSelectedConnections ? editFeedData.data.connections : [],
        tags: editFeedData.data.tags,
        images: [],
        descriptionMode: editFeedData.data.is_long_description ? 'multiple' : 'single',
        sections: editFeedData.data.is_long_description ? editFeedData.data.sections : initialLongDescription,
      });

      getImages(editFeedData.data.images);
    }
  }, [editFeedData.data]);

  const getImages = async (images: any) => {
    const imagesUri = images?.map((data: any) =>
      axios.get(data.image, { responseType: 'blob', headers: { 'Cache-Control': 'no-cache' } }),
    );
    if (imagesUri && imagesUri?.length > 0) {
      try {
        const imagesBlob = await Promise.all(imagesUri);
        setForm(form => ({
          ...form,
          images:
            imagesBlob.map((resp, index) => {
              return {
                image: new File([resp.data], 'undefined', {
                  type: resp.data.type,
                }),
                annotations: images[index].annotations,
              };
            }) || [],
        }));
        return imagesBlob;
      } catch (err) {
        console.log(err);
      }
    }
    return [];
  };

  useEffect(() => {
    if (editFeedData.isFetching) {
      dispatch(startPageLoader());
    } else {
      dispatch(stopPageLoader());
    }
  }, [editFeedData.isFetching]);

  const [selectedSpeciality, setSelectedSpeciality] = useState<IDropDownDataExtended[]>([]);

  const handleChange = event => {
    console.log(event.target.name);
    setForm({
      ...form,
      descriptionMode: event.target.value,
    });
  };

  const handleLongDescriptionChange = (description, index) => {
    setForm({
      ...form,
      sections: form.sections.map((item, idx) => {
        if (idx == index) {
          return {
            ...item,
            description: description,
          };
        }
        return item;
      }),
    });
  };

  const [isConnectionModalOpen, setConnectionModalOpen] = useState(false);
  const [isPostVisibilityModalOpen, setPostVisibilityModalOpen] = useState(false);

  return (
    <Box>
      <Header>
        <Box width="100%" p={3} pb={0}>
          <Box mt={1}>
            <Typography variant="subtitle1" fontSize="24px">
              {editFeedId ? 'Edit Post' : 'Create Post'}
            </Typography>
          </Box>
        </Box>
      </Header>

      <Box>
        <Grid container justifyContent="center">
          <Box className={styles.wrapper}>
            <form className={styles.formWrapper} noValidate onSubmit={handleSubmit}>
              <Box p={1}>
                <Carousel
                  className={styles.carousel}
                  navButtonsAlwaysInvisible
                  autoPlay={false}
                  cycleNavigation={true}
                  swipe={false}
                  indicators={false}
                  animation="fade"
                  fullHeightHover={false}
                  changeOnFirstRender
                  height="220px"
                  activeIndicatorIconButtonProps={{
                    style: {
                      color: '#34D695',
                    },
                  }}
                  index={activeImageIndex}
                >
                  {(() => {
                    const slide: any[] = [];

                    if (editFeedId && typeof editFeedId === 'number' && editFeedId > 0) {
                      // form.images.forEach((image, index) => {
                      //   slide.push(
                      //     <Box
                      //       sx={{
                      //         height: '100%',
                      //         cursor: 'pointer',
                      //       }}
                      //       className='annotation-tooltip-wrapper'
                      //     >
                      //       <Grid
                      //         // display={'flex'}
                      //         // justifyContent='flex-end'
                      //         style={{
                      //           // height: '0%',
                      //           width: '93%',
                      //           display: 'flex',
                      //           justifyContent: 'flex-end',
                      //           cursor: 'pointer',
                      //           position: 'absolute',
                      //           fontWeight: 'bold',
                      //           paddingRight: '5px',
                      //           top: '-6px',
                      //           color: 'red',
                      //         }}
                      //         onClick={(e) => {
                      //           handleDeleteImage(image, index)
                      //         }}
                      //       >
                      //         X
                      //       </Grid>
                      //       <img
                      //         src={URL.createObjectURL(image.image)}
                      //         style={{
                      //           height: '95%',
                      //           objectFit: 'cover',
                      //           borderRadius: '10px',
                      //         }}
                      //       />

                      //       {image.annotations && (
                      //         <div className='annotation-tooltiptext'>{image.annotations}</div>
                      //       )}
                      //     </Box>,
                      //   )
                      // })

                      // slide.push(
                      //   <StyledFileInputComponent
                      //     accept='image/png, image/jpg, image/jpeg'
                      //     value={null}
                      //     name='image'
                      //     onChange={onImageChange}
                      //     title='Upload Image'
                      //     discription='Browse and choose the images you want to upload from your computer JPEG, PNG.'
                      //   />,
                      // )
                      form.images.forEach((image, index) => {
                        slide.push(
                          <Box
                            sx={{
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            className="annotation-tooltip-wrapper"
                          >
                            <img
                              src={URL.createObjectURL(image.image)}
                              onClick={() => handleImageAnnotationOpen(image.image, image.annotations, index)}
                              style={{
                                height: '95%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                              }}
                            />

                            {image.annotations && <div className="annotation-tooltiptext">{image.annotations}</div>}
                            <Grid
                              display={'flex'}
                              justifyContent="flex-end"
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                cursor: 'pointer',
                                position: 'absolute',
                                fontWeight: 'bold',
                                paddingRight: '20px',
                                paddingBottom: '10px',
                                marginTop: '-50px',
                                marginLeft: '-50px',
                                // marginTop: '-20px',
                                color: 'red',
                              }}
                              onClick={e => {
                                handleDeleteImage(image, index);
                              }}
                            >
                              {/* <DeleteForeverIcon color='primary' /> */}
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  padding: '10px 15px',
                                  paddingBottom: '5px',
                                  border: '1px solid red',
                                  borderRadius: '10px',
                                }}
                              >
                                <img src={del} alt="delete image" />
                              </div>
                            </Grid>
                          </Box>,
                        );
                      });
                    } else {
                      form.images.forEach((image, index) => {
                        slide.push(
                          <Box
                            sx={{
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleImageAnnotationOpen(image.image, image.annotations, index)}
                            className="annotation-tooltip-wrapper"
                          >
                            <img
                              src={URL.createObjectURL(image.image)}
                              style={{
                                height: '95%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                              }}
                            />

                            {image.annotations && <div className="annotation-tooltiptext">{image.annotations}</div>}
                          </Box>,
                        );
                      });
                    }
                    slide.push(
                      <StyledFileInputComponent
                        accept="image/png, image/jpg, image/jpeg"
                        value={null}
                        name="image"
                        onChange={onImageChange}
                        title="Upload Image"
                        discription="Browse and choose the images you want to upload from your computer JPEG, PNG."
                      />,
                    );
                    return slide;
                  })()}
                </Carousel>
                {(!!form.images.length || !!editFeedData?.data?.images?.length) && (
                  <Box textAlign="center">
                    <CarouselIndicator
                      imagesCount={form.images.length + 1}
                      activeIndex={activeImageIndex}
                      onIndexChange={setActiveImageIndex}
                    />
                  </Box>
                )}
              </Box>
              <Box p={1}>
                <InputField
                  name="title"
                  id="title"
                  type="text"
                  placeholder="Title of Post"
                  value={form.title}
                  onChange={onInputChange}
                  required
                />
              </Box>

              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Radio
                    color="success"
                    checked={form.descriptionMode === 'single'}
                    onChange={handleChange}
                    value="single"
                    name="descriptionMode"
                    inputProps={{ 'aria-label': 'Single' }}
                  />
                  <Typography variant="subtitle2">Short Format</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Radio
                    color="success"
                    checked={form.descriptionMode === 'multiple'}
                    onChange={handleChange}
                    value="multiple"
                    name="descriptionMode"
                    inputProps={{ 'aria-label': 'Multiple' }}
                  />
                  <Typography variant="subtitle2">Full Case Format</Typography>
                </Box>
              </Box>

              {form.descriptionMode == 'single' ? (
                <Box p={1}>
                  <InputField
                    name="description"
                    id="description"
                    type="text"
                    placeholder="This is where you can write more about your post. You can share your thoughts,  opinions, or experiences."
                    value={form.description}
                    onChange={onInputChange}
                    // required
                    multiline
                    rows={4}
                  />
                </Box>
              ) : (
                <LongDescription sections={form.sections} setSection={handleLongDescriptionChange} />
              )}

              <Box p={1}>
                <Dropdown
                  multiple
                  value={form.tags}
                  label="Tags"
                  options={tagOptions.map(option => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  })}
                  onChange={(value: any) => onDropdownChange('tags', value)}
                />
              </Box>

              <Box p={1}>
                <AutoCompleteInput
                  data={
                    specialtyList.map(item => {
                      return {
                        label: item.name,
                        value: `${item.id}`,
                        haveSubspecialty: item.have_sub_speciality,
                        subspecialtyList: item.sub_specialities.map(subItem => ({ ...subItem, selected: false })),
                      };
                    }) ?? []
                  }
                  setSelectedValue={setSelectedSpeciality}
                  selectedValue={selectedSpeciality}
                  onType={query => setQueryParam(query)}
                  placeholder={'Add Subspecialty'}
                />
              </Box>

              <Box display="flex" alignItems="center">
                {selectedSpeciality && selectedSpeciality.length > 0 && (
                  <>
                    <Typography variant="subtitle2" fontWeight={600} ml={1}>
                      {selectedSpeciality.map(item => (
                        <a key={item.value}> #{item.label} </a>
                      ))}
                    </Typography>
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} p={1}>
                <Dropdown
                  multiple
                  value={form.connections}
                  label="Add Connection"
                  disabled={form.isPrivateForAllConnections}
                  options={connectionOptions.map(option => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  })}
                  onChange={(value: any) => onDropdownChange('connections', value)}
                />
                <IconButton onClick={() => setConnectionModalOpen(true)}>
                  <Info />
                </IconButton>
              </Box>
              <Box p={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography variant="h5">Post Visibility</Typography>
                  <IconButton onClick={() => setPostVisibilityModalOpen(true)}>
                    <Info />
                  </IconButton>
                </Box>

                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isPrivateForAllConnections"
                        disabled={form.isPrivateForSelectedConnections}
                        checked={form.isPrivateForAllConnections}
                        onChange={handleCheckboxClick}
                        color="success"
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color={form.isPrivateForSelectedConnections ? 'gray' : 'white'}
                      >
                        Private : for all healthcare professionals
                      </Typography>
                    }
                  />
                </Box>

                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isPrivateForSelectedConnections"
                        disabled={form.isPrivateForAllConnections}
                        checked={form.isPrivateForSelectedConnections}
                        onChange={handleCheckboxClick}
                        color="success"
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color={form.isPrivateForAllConnections ? 'gray' : 'white'}
                      >
                        Private : for selected connections
                      </Typography>
                    }
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="doesImageContainGraphicMaterial"
                        checked={form.doesImageContainGraphicMaterial}
                        onChange={handleCheckboxClick}
                        color="success"
                        disabled={form.isPrivateForAllConnections || form.isPrivateForSelectedConnections}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color={
                          form.isPrivateForAllConnections || form.isPrivateForSelectedConnections ? 'gray' : 'white'
                        }
                      >
                        Images uploaded contain graphic material
                      </Typography>
                    }
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isAnonymousPost"
                        checked={form.isAnonymousPost}
                        onChange={handleCheckboxClick}
                        className={styles.feedAnonymousPost}
                        color="success"
                      />
                    }
                    label={
                      <Typography variant="subtitle2" component="span" color="white">
                        Post Anonymously
                      </Typography>
                    }
                  />
                </Box>
              </Box>

              <Box p={2}>
                <Typography variant="subtitle2" color="red" textAlign="center">
                  {error || updateError ? 'Opps something went wrong' : ''}
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                  disabled={isLoading || isUpdating}
                  className={isLoading || isUpdating ? 'buttonDisabled' : ''}
                >
                  {isLoading ? <CircularProgress size={25} /> : editFeedId ? 'Save' : 'Post'}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Box>

      <ImageAnnotation
        open={imageAnnotationInfo.open}
        onClose={handleImageAnnotationClose}
        data={imageAnnotationInfo.data}
        onSave={onAnnotationSave}
      />
      <Modal
        open={isConnectionModalOpen}
        onClose={() => setConnectionModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            boxShadow: 24,
            backgroundColor: 'black',
            borderRadius: 2,
            p: 4,
          }}
        >
          <IconButton
            onClick={() => setConnectionModalOpen(false)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: 20, mr: 1 }}>
              &#8226;
            </Typography>
            <Typography variant="subtitle2" id="modal-modal-description" sx={{ fontSize: 16 }}>
              Add one of your connections so that only they can see this post.
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isPostVisibilityModalOpen}
        onClose={() => setPostVisibilityModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            backgroundColor: 'black',
            borderRadius: 2,
            p: 4,
          }}
        >
          <IconButton
            onClick={() => setPostVisibilityModalOpen(false)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: 20, mr: 1 }}>
              &#8226;
            </Typography>
            <Typography variant="subtitle2" id="modal-modal-description" sx={{ fontSize: 16 }}>
              Public: Posts visible to all users of the application, healthcare professionals and non healthcare
              professionals (Default).
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: 20, mr: 1 }}>
              &#8226;
            </Typography>
            <Typography variant="subtitle2" id="modal-modal-description" sx={{ fontSize: 16 }}>
              Private: Posts are generated and appear in the &quot;Private&quot; Tab. Not visible to the public, only to
              verified healthcare professionals.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: 20, mr: 1 }}>
              &#8226;
            </Typography>
            <Typography variant="subtitle2" id="modal-modal-description" sx={{ fontSize: 16 }}>
              Private for selected connections: If a user creates a post for selected healthcare professionals, the post
              is only visible to the tagged users. if no users are tagged, the post will be visible to all of a
              user&apos;s connections.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateFeed
