import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
const PageLoader = () => {
  const { isPageLoading } = useSelector((state: RootState) => state.common)
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => 9999999999 }}
      open={isPageLoading}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default PageLoader
