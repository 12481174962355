import React, { useState } from 'react'
import { Box, Popover, Typography } from '@mui/material'
import dangerIcon from 'src/assets/icons/danger-icon.png'
import CustomizedDialogBox from 'src/Component/CustomizedDialogBox'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputField from 'src/Component/InputField'
import { errorToast, successToast } from 'src/utils/toastService'
import { useReportUserMutation } from 'src/api/supportAndFeedback'

type ReportUserModalProps = {
  isPopoverOpen: boolean
  anchorEl: HTMLButtonElement | null
  onPopoverClose: () => void
  reportId: number
}

const reportReasons = [
  'Spam',
  'Hatred and bullying',
  'Self-harm',
  'Violent, gory and harmful content',
  'Illegal activities (e.g. drug uses)',
  'Deceptive content',
  'Copyright and trademark infringement',
]
const ReportUserModal = ({
  isPopoverOpen,
  anchorEl,
  onPopoverClose,
  reportId,
}: ReportUserModalProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [reportReason, setReportReason] = useState<string>('')
  const [otherReason, setOtherReason] = useState<string>('')
  const [reportUser, { isLoading }] = useReportUserMutation()

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportReason((event.target as HTMLInputElement).value)
    setOtherReason('')
  }

  const handleModaOpen = () => {
    setOpen(true)
    onPopoverClose()
  }

  const onOtherReasonInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(e.target.value)
    setReportReason('')
  }
  const handleSubmit = () => {
    if (!reportReason && !otherReason) {
      errorToast('Please mention the reporting reason.')
      return
    }
    reportUser({
      userId: reportId,
      reason: reportReason || otherReason,
    })
      .unwrap()
      .then((res) => {
        successToast('User reported successfully.')
        setOpen(false)
      })
      .catch((err: string) => {
        errorToast(err)
      })
  }

  React.useEffect(() => {
    setOtherReason('')
    setReportReason('')
  }, [open])
  return (
    <Box>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography
          alignItems='center'
          sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
          color='red'
          onClick={handleModaOpen}
        >
          {' '}
          <img src={dangerIcon} height={25} alt='Flag post' style={{ paddingRight: '10px' }} /> Flag
          User{' '}
        </Typography>
      </Popover>

      <CustomizedDialogBox
        title='Why are you reporting this?'
        open={open}
        onClose={() => setOpen(false)}
        loading={isLoading}
        onSubmit={handleSubmit}
      >
        <Box>
          <FormControl>
            <RadioGroup
              aria-labelledby='flag-feed-radio-buttons-group-label'
              value={reportReason}
              onChange={handleReasonChange}
              name='radio-buttons-group'
            >
              {reportReasons.map((reason, index) => {
                return (
                  <FormControlLabel
                    sx={{ paddingTop: '10px' }}
                    key={index}
                    value={reason}
                    control={<Radio />}
                    label={<Typography variant='subtitle2'>{reason}</Typography>}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>

          <Box pt={3}>
            <InputField
              name='description'
              id='description'
              type='text'
              placeholder='Other'
              value={otherReason}
              onChange={onOtherReasonInput}
              required
              multiline
              rows={4}
            />
          </Box>
        </Box>
      </CustomizedDialogBox>
    </Box>
  )
}

export default ReportUserModal
