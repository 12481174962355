import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import InputField from 'src/Component/InputField';
import StyledFileInputComponent from 'src/Component/StyledFileInputComponent';
import { fileToBase64 } from 'src/utils/helpers';
import { verifyMedicalLicenseApi } from 'src/store/features/auth/authActions';
import { errorToast, successToast } from 'src/utils/toastService';

type LicenseVerificationProps = {
  form: {
    professionalName: string;
    medicalLicenseNumber: string;
    licenseImage: File | null;
  };
  onSubmit: (e: React.SyntheticEvent) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  error?: string | null;
};

const LicenseVerification = ({
  form,
  onInputChange,
  onSubmit,
  loading,
  error,
  onFileChange,
}: LicenseVerificationProps) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const verifyDocument = (licence: string): boolean => {
    const lic = licence.split('\n').join(' ');
    const fullTextArray = lic.split(' ').map(text => text.toLowerCase());
    if (
      form.professionalName.length === 0 ||
      form.medicalLicenseNumber.length === 0
    ) {
      errorToast('Professional Name & license Number is required');
      return false;
    } else {
      const nameArray = form.professionalName
        .split(' ')
        .map(text => text.toLowerCase());
      let startIndex: number;
      fullTextArray.forEach((item, index: number) => {
        if (nameArray[0] === item) {
          startIndex = index;
        }
      });
      let nameMatches = true;
      nameArray.forEach((name, index) => {
        if (name != fullTextArray[startIndex + index]) {
          nameMatches = false;
        }
      });
      let licenceMatches = false;
      const medicalLicenseNumber = form.medicalLicenseNumber.toLowerCase();
      fullTextArray.forEach(item => {
        if (medicalLicenseNumber === item) {
          licenceMatches = true;
        }
      });
      if (!nameMatches) {
        errorToast('Professional name doesn`t match.');
        return false;
      }
      if (!licenceMatches) {
        errorToast('Licence number doesn`t match.');
        return false;
      }
      successToast('Verification success, Please click submit to continue.');
      return true;
    }
  };

  const handleVerify = async () => {
    if (
      form.professionalName &&
      form.medicalLicenseNumber &&
      form.licenseImage
    ) {
      setIsLoading(true);
      let imageBase64: string;
      try {
        imageBase64 = (await fileToBase64(form.licenseImage)) as string;
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        errorToast('Opps, something went wrong.');
        return;
      }
      verifyMedicalLicenseApi(imageBase64)
        .then((res: any) => {
          const response: any = res.data;
          if (response && response.responses && response.responses.length > 0) {
            const fullText = response.responses[0].fullTextAnnotation.text;
            if (verifyDocument(fullText)) {
              setIsVerified(true);
            } else {
              setIsVerified(false);
            }
          } else {
            errorToast('Opps, something went wrong.');
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      errorToast('Please fill all fields.');
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <Box p={1}>
        <InputField
          name="professionalName"
          id="professionalName"
          type="text"
          placeholder="Professional Name"
          value={form.professionalName}
          onChange={onInputChange}
          required
        />
      </Box>

      <Box p={1}>
        <InputField
          name="medicalLicenseNumber"
          id="medicalLicenseNumber"
          type="text"
          placeholder="Medical License Number"
          value={form.medicalLicenseNumber}
          onChange={onInputChange}
          required
        />
      </Box>

      <Box p={1}>
        <StyledFileInputComponent
          accept="image/png, image/jpg, image/jpeg"
          name="licenseImage"
          value={form.licenseImage}
          onChange={onFileChange}
          title="Upload Medical License"
          discription="Browse and choose the images you want to upload from your computer JPEG, PNG."
        />
      </Box>

      <Typography variant="subtitle2" color="red" textAlign="center">
        {error}
      </Typography>

      <Box p={1}>
        <Button
          type="submit"
          variant="contained"
          color="success"
          fullWidth
          disabled={loading}
          className={loading ? 'buttonDisabled' : ''}
          sx={{
            display: isVerified ? 'initial' : 'none',
          }}
        >
          {loading ? <CircularProgress size={25} /> : 'Continue'}
        </Button>

        <Button
          onClick={handleVerify}
          type="button"
          variant="outlined"
          color="success"
          fullWidth
          disabled={isLoading}
          sx={{
            display: !isVerified ? 'initial' : 'none',
          }}
        >
          {isLoading ? <CircularProgress size={25} /> : 'Verify'}
        </Button>
      </Box>
    </form>
  );
};

export default LicenseVerification;
