import React from 'react'
type IconProps = {
  color?: string
}
const MyPostsIcon = ({ color = '#909090' }: IconProps) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2.3335V16.3335H2V2.3335H16ZM18 0.333496H0V18.3335H18V0.333496ZM14 14.3335H4V13.3335H14V14.3335ZM14 12.3335H4V11.3335H14V12.3335ZM14 9.3335H4V4.3335H14V9.3335Z" fill={color}/>
    </svg>
    
  )
}

export default MyPostsIcon
