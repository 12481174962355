import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Grid,
  IconButton,
  CircularProgress,
  Popover,
  Typography,
  Button,
  Input,
  Switch,
  FormControlLabel,
} from '@mui/material'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { useAudioRecorder } from 'react-audio-voice-recorder'

import AddIcon from 'src/assets/icons/addForComment.png'
import SendIcon from 'src/assets/icons/sendCommentbutton.png'
import InputField from 'src/Component/InputField'
import microphone from 'src/assets/icons/microphone.png'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import styles from './index.module.scss'
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice'
import uploadIcon from 'src/assets/icons/upload-white.png'
import microphoneIconWhite from 'src/assets/icons/microphone-white.png'
// eslint-disable-next-line camelcase
import { secondsTo_ms_format } from 'src/utils/helpers'
type CommentBoxProps = {
  comment: string
  isLoading: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAddComment: () => void
  handleAddVoiceMemo: (file: File, duration: number) => void
  placeholder: string
  isAnonymous?: boolean
  hideAnonymousFeature?: boolean
  onSpeechToTextResponse: (value: string) => void
  handleImageUpload: (file: File) => void
  handleIsAnonymous?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const CommentBox = ({
  comment,
  isLoading,
  handleAddComment,
  handleAddVoiceMemo,
  onChange,
  placeholder,
  onSpeechToTextResponse,
  handleImageUpload,
  isAnonymous,
  handleIsAnonymous,
  hideAnonymousFeature = false,
}: CommentBoxProps) => {
  const { transcript, resetTranscript } = useSpeechRecognition()
  const [isListening, setIsListening] = useState(false)
  const [doesBrowserSupportsSpeechRecognition, setDoesBrowserSupportsSpeechRecognition] =
    useState(false)
  const { startRecording, stopRecording, recordingBlob, isRecording, recordingTime } =
    useAudioRecorder()
  const [isRecordingCompleted, setIsRecordingCompleted] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [imageToBeSend, setImageToBeSend] = useState<File | null>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)
  const [audioDuration, setAudioDuration] = useState<number>(0)

  const handleShowMoreOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMoreOption = () => {
    setAnchorEl(null)
    setIsRecordingCompleted(false)
    stopRecording()
  }

  const handleListing = () => {
    resetTranscript()
    setIsListening(true)
    SpeechRecognition.startListening({
      continuous: true,
    })
  }
  const handleStopListing = () => {
    setIsListening(false)
    SpeechRecognition.stopListening()
    resetTranscript()
  }

  const handleStartVoiceMemoRecording = () => {
    setAudioDuration(0)
    startRecording()
  }

  const handleStopVoiceMemoRecording = () => {
    setAudioDuration(recordingTime)
    stopRecording()
  }

  const handleSendVoiceMemo = () => {
    if (recordingBlob && isRecordingCompleted) {
      handleAddVoiceMemo(
        new File([recordingBlob], `'recording_${Math.random() * 1000}.mp4'`, {
          type: 'video/mp4',
          lastModified: Date.now(),
        }),
        audioDuration * 1000, // miliseconds
      )
      setIsRecordingCompleted(false)
      handleCloseMoreOption()
    }
  }

  useEffect(() => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      setDoesBrowserSupportsSpeechRecognition(true)
    }
  }, [])

  useEffect(() => {
    if (isListening && transcript) {
      onSpeechToTextResponse(transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (!recordingBlob || !anchorEl) return

    // recordingBlob will be present at this point after 'stopRecording' has been called
    setIsRecordingCompleted(true)
  }, [recordingBlob])

  const renderRecordingActions = () => {
    if (isRecording) {
      return (
        <Box m={1}>
          <Button
            type='button'
            variant='outlined'
            color='error'
            onClick={handleStopVoiceMemoRecording}
          >
            <StopCircleIcon
              color='error'
              sx={{ height: 25, width: 25 }}
              className={styles.blinkStopListingIcon}
            />
            {secondsTo_ms_format(recordingTime)}, Click to stop.
          </Button>
        </Box>
      )
    }

    if (isRecordingCompleted) {
      return (
        <Grid container spacing={1} m={1}>
          <Grid item>
            <Button
              type='button'
              variant='contained'
              color='success'
              fullWidth
              onClick={handleSendVoiceMemo}
            >
              Send Voice
            </Button>
          </Grid>
          <Grid item>
            <Button
              type='button'
              variant='contained'
              color='error'
              fullWidth
              onClick={() => setIsRecordingCompleted(false)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )
    }

    return (
      <Box m={1}>
        <Button type='button' onClick={handleStartVoiceMemoRecording}>
          <img src={microphoneIconWhite} alt='microphone' /> &nbsp; Record voice memo
        </Button>
      </Box>
    )
  }

  const renderUploadImage = () => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e?.target?.files?.[0]) {
        console.log(e.target.files[0])
        setImageToBeSend(e.target.files[0])
      }
    }

    const sendImage = () => {
      if (imageToBeSend) {
        handleImageUpload(imageToBeSend)
        setImageToBeSend(null)
        if (imageInputRef.current) {
          imageInputRef.current.value = ''
        }
      }
      handleCloseMoreOption()
    }

    if (imageToBeSend) {
      return (
        <Grid container spacing={1} m={1}>
          <Grid item>
            <Button
              type='button'
              variant='contained'
              color='success'
              fullWidth
              disabled={!imageToBeSend}
              className={!imageToBeSend ? 'buttonDisabled' : ''}
              onClick={sendImage}
            >
              Send Image
            </Button>
          </Grid>
          <Grid item>
            <Button
              type='button'
              variant='contained'
              color='error'
              fullWidth
              onClick={() => setImageToBeSend(null)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )
    }

    return (
      <Box m={1}>
        <Button type='button'>
          <label style={{ cursor: 'pointer' }}>
            <img src={uploadIcon} alt='upload' /> &nbsp; Upload from device
            <Input
              type='file'
              inputRef={imageInputRef}
              onChange={onChange}
              inputProps={{
                accept: 'image/png, image/jpg, image/jpeg',
              }}
              name='imageToBeSend'
              disableUnderline
              sx={{ display: 'none' }}
            />
          </label>
        </Button>
      </Box>
    )
  }

  return (
    <>
      {hideAnonymousFeature === false && (
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <FormControlLabel
            control={
              <Switch
                name='isAnonymousPost'
                checked={isAnonymous}
                onChange={handleIsAnonymous}
                className={styles.commentReplyAnonymousPost}
                color='success'
              />
            }
            label={
              <Typography variant='subtitle2' component='span' color='white'>
                Anonymous
              </Typography>
            }
          />
        </Box>
      )}

      <Grid container alignItems='center' mt={1}>
        <Grid item>
          <IconButton onClick={handleShowMoreOption}>
            <img src={AddIcon} />
          </IconButton>
        </Grid>

        <Grid item flexGrow={2}>
          <Box sx={{ width: '100%', position: 'relative' }}>
            <InputField
              name='addComment'
              id='addComment'
              type='text'
              placeholder={placeholder}
              value={comment}
              disabled={isLoading}
              onChange={onChange}
              inputStyles={{
                paddingRight: '35px',
              }}
            />
            {doesBrowserSupportsSpeechRecognition && (
              <Box className={styles.speechToTextMicWrapper}>
                {isListening ? (
                  <IconButton onClick={handleStopListing}>
                    <StopCircleIcon
                      color='error'
                      sx={{ height: 25, width: 25 }}
                      className={styles.blinkStopListingIcon}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleListing}>
                    <img src={microphone} alt='microphone' height={22} width={15} />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item>
          <IconButton
            onClick={handleAddComment}
            disabled={isListening || isLoading}
            sx={{ opacity: isListening ? 0.5 : 1 }}
          >
            {isLoading ? <CircularProgress size={36} /> : <img src={SendIcon} />}
          </IconButton>
        </Grid>

        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseMoreOption}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            sx={{
              width: '260px',
            }}
            p={2}
          >
            {!imageToBeSend && renderRecordingActions()}
            {!isRecording && !isRecordingCompleted && renderUploadImage()}
          </Box>
        </Popover>
      </Grid>
    </>
  )
}

export default CommentBox
