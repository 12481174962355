import React from 'react'
import { Box, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type ReportFeedMenuProps = {
  onMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
}
const ReportFeedMenu = ({ onMenuOpen }: ReportFeedMenuProps) => {
  return (
    <Box>
      <IconButton onClick={onMenuOpen}>
        <MoreVertIcon color='primary' />
      </IconButton>
    </Box>
  )
}

export default ReportFeedMenu
