import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Box } from '@mui/material'
import Input from '@mui/material/Input'
import AddIcon from 'src/assets/icons/addFileIcon.png'

const Image = styled('img')`
  height: 150px;
  object-fit: cover;
  margin-bottom: ${(props) => props.theme.spacing(2)};
  border: 1px solid white;
  border-radius: 10px;
`
export interface AddBusinessDirectoryModalProps {
  open: boolean
  onClose: () => void
}

// Define a custom styled file input component
const StyledFileInput = styled('label')({
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  background: '#142036',
  borderRadius: '4px',
})

// Define the props interface for the styled file input component
interface StyledFileInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
  title: string
  discription: string
  name: string
  value: File | null
  required?: boolean
  accept?: string
}

// Define the styled file input component
const StyledFileInputComponent: React.FC<StyledFileInputProps> = ({
  onChange,
  multiple = false,
  title,
  discription,
  name,
  value,
  required = false,
  accept = 'image/*',
}) => (
  <StyledFileInput>
    <Box p={2}>
      <Typography textAlign='left' variant='h5' mb={1}>
        {title}
      </Typography>
      <Box
        sx={{
          textAlign: 'center',
          border: '1px dashed #8D9199',
          borderRadius: '4px',
        }}
        p={3}
      >
        {value && (
          <Box textAlign='center'>
            <Image src={URL.createObjectURL(value)} alt={title} />
          </Box>
        )}
        <Input
          type='file'
          onChange={onChange}
          inputProps={{ accept: accept, multiple }}
          sx={{ display: 'none' }}
          name={name}
          required={required}
        />
        <Typography
          mb={2}
          pt={1}
          variant='subtitle2'
          component='p'
          sx={{ color: 'rgba(235, 235, 245, 0.6)' }}
        >
          {discription}
        </Typography>
        <img src={AddIcon} height={30} width={30} alt='add' />
      </Box>
    </Box>
  </StyledFileInput>
)

export default StyledFileInputComponent
