import React, { useState } from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import InputField from 'src/Component/InputField'
import VouchedVerification from 'src/Component/VouchedVerification'
import { errorToast, successToast } from 'src/utils/toastService'

type IDverificationProps = {
  form: {
    idNumber: string
    isVerified: boolean
  }
  onSubmit: (e: React.SyntheticEvent) => void
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onVouchedChange: (status: boolean) => void
  loading: boolean
  error?: string | null
}

const IDverification = ({
  form,
  onInputChange,
  onSubmit,
  loading,
  error,
  onVouchedChange,
}: IDverificationProps) => {
  const [isDisabledIDNumber, setDisabledIDNumber] = useState(false)
  const vouchedSuccess = (response: any) => {
    if (response.errors.length === 0) {
      if (form.idNumber === response.result.id) {
        onVouchedChange(true)
        setDisabledIDNumber(true)
        successToast('Verification success, Please continue your registration.')
      } else {
        vouchedFailure('ID number mismatch')
      }
    }
  }
  const vouchedFailure = (errMsg: string) => {
    onVouchedChange(false)
    setDisabledIDNumber(false)
    errorToast('Verification failed. Please try again.')
  }

  return (
    <form onSubmit={onSubmit}>
      <Box p={1}>
        <InputField
          name='idNumber'
          id='idNumber'
          type='text'
          placeholder='ID Number'
          value={form.idNumber}
          onChange={onInputChange}
          disabled={isDisabledIDNumber}
          required
        />
        <Typography variant='subtitle2' textAlign='left'>
          *Driving License
        </Typography>
      </Box>

      <Box p={1}>
        <VouchedVerification
          onSuccess={(job: any) => {
            vouchedSuccess(job)
          }}
          onFailure={(errMsg: string) => {
            vouchedFailure(errMsg)
          }}
          title='Upload your ID'
          discription='Upload your ID'
          name='vouchedVerification'
          validationId={form.idNumber}
        />
      </Box>

      <Typography variant='subtitle2' color='red' textAlign='center'>
        {error}
      </Typography>

      <Box p={1}>
        <Button
          type='submit'
          variant='contained'
          color='success'
          fullWidth
          disabled={loading || !isDisabledIDNumber}
          className={loading ? 'buttonDisabled' : ''}
        >
          {loading ? <CircularProgress size={25} /> : 'Continue'}
        </Button>
      </Box>
    </form>
  )
}

export default IDverification
