import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { useAddCommentMutation, useListCommentsQuery } from 'src/api/feed'
import CommentList from './CommentList'
import CommentBox from './CommentBox'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'src/store'
import { chatOrCommentMessageType, userRoles } from 'src/utils/constants'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { FocusArea, Specialty } from 'src/api/feed/types';
import ExpandablePostDescription from './ExpandablePostDescription';
import LongDescriptionView from './LongDescriptionView'

type ExpandViewProps = {
  postId: number;
  description: string;
  tags: {
    id: number;
    name: string;
  }[];
  specialty?: Specialty[];
  focusArea?: FocusArea[];
  isLongDescription:boolean;
  sections:Array<any>
};
const ExpandView = ({ description, tags, postId, specialty, focusArea, sections, isLongDescription }: ExpandViewProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { role } = useSelector((state: RootState) => state.auth);
  const [addComment, { isLoading: isAddCommentLoading }] = useAddCommentMutation();
  const [comment, setComment] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);

  const { isLoading: isCommentsLoading, data: commentData, refetch, isFetching } = useListCommentsQuery(postId);

  const handleSpeechToTextResponse = (value: string) => {
    setComment(value);
  };

  const handleAddComment = () => {
    if (!comment) return;

    const formData = new FormData();
    formData.append('text', comment);
    formData.append('comment_type', chatOrCommentMessageType.text);
    addComentApi(formData);
  };

  const handleAddVoiceMemo = (file: File, duration: number) => {
    if (file) {
      const formData = new FormData();
      formData.append('audio', file);
      formData.append('comment_type', chatOrCommentMessageType.audio);
      formData.append('audio_duration', duration.toString());
      addComentApi(formData);
    }
  };

  const handleSendImage = (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('comment_type', chatOrCommentMessageType.image);
      addComentApi(formData);
    }
  };

  const addComentApi = (formData: FormData) => {
    formData.append('is_anonymous', isAnonymous ? 'true' : 'false');
    addComment({
      postId: postId,
      payload: formData,
    })
      .unwrap()
      .then(res => {
        setComment('');
        refetch();
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  if (isFetching) {
    dispatch(startPageLoader());
  } else {
    dispatch(stopPageLoader());
  }

  const renderTags = (title: string, data?: { id: any; name: string }[]) => {
    return (
      <Box display="flex" alignItems="center">
        {data && data.length > 0 && (
          <>
            <Typography variant="subtitle1">
              <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{title}:</span>
              <span style={{ fontWeight: 600 }}>
                {data.map(item => (
                  <a key={item.id}> #{item.name} </a>
                ))}
              </span>
            </Typography>
          </>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box mt={2} mb={2}>
      {isLongDescription ? (
          <LongDescriptionView content={sections} />
        ) : (
          <ExpandablePostDescription text={description} maxLength={500} />
        )}
       
      </Box>
      <Box sx={{marginBottom:3}}>
        {renderTags('Tags', tags)}

        {renderTags('Subspecialty', specialty)}

        {renderTags('Focus Area', focusArea)}
      </Box>

      <CommentList data={commentData} isLoading={isCommentsLoading} refetch={refetch} />

      {role === userRoles.healthCare && (
        <CommentBox
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          isLoading={isAddCommentLoading}
          handleAddComment={handleAddComment}
          handleAddVoiceMemo={handleAddVoiceMemo}
          comment={comment}
          placeholder="Add a comment"
          onSpeechToTextResponse={handleSpeechToTextResponse}
          handleImageUpload={handleSendImage}
          isAnonymous={isAnonymous}
          handleIsAnonymous={(event: React.ChangeEvent<HTMLInputElement>) => setIsAnonymous(event.target.checked)}
        />
      )}
    </Box>
  );
};

export default ExpandView
