import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, Button, CircularProgress } from '@mui/material'
import Header from 'src/Layout/HomeLayout/Header'
import styles from './index.module.scss'
import InputField from 'src/Component/InputField'
import { useSubmitFeedbackMutation } from 'src/api/supportAndFeedback'
import { errorToast, successToast } from 'src/utils/toastService'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

type formI = {
  email: string
  message: string
}
const initialFormState: formI = {
  email: '',
  message: '',
}
const SupportAndFeedback = () => {
  const [form, setForm] = useState<formI>(initialFormState)
  const [submitFeedback, { isLoading }] = useSubmitFeedbackMutation()

  const { userInfo } = useSelector((state: RootState) => state.auth)

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    submitFeedback({
      ...form,
    })
      .unwrap()
      .then((res) => {
        successToast('Feedback submitted successfully.')
        setForm({
          ...initialFormState,
          email: userInfo?.email ? userInfo.email : '',
        })
      })
      .catch((err: string) => {
        errorToast(err)
      })
  }

  useEffect(() => {
    if (userInfo?.email) {
      setForm({
        ...form,
        email: userInfo.email,
      })
    }
  }, [userInfo])
  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Box mt={1}>
            <Typography variant='subtitle1' fontSize='24px'>
              Support and Feedback
            </Typography>
          </Box>
        </Box>
      </Header>

      <Box mt={5}>
        <Grid justifyContent='center' container>
          <form className={styles.formWrapper} onSubmit={handleSubmit}>
            <Box textAlign='center'>
              <Typography variant='h3'>What could be better?</Typography>
              <Typography variant='subtitle2' mt={1}>
                If you have a suggestions you feel will help us improve your experience or <br />{' '}
                the experience of others while using the app, kindly share, we love <br />
                feedback
              </Typography>
            </Box>

            <Box mt={3}>
              <Box p={1}>
                <InputField
                  name='email'
                  id='email'
                  type='text'
                  placeholder='Email address'
                  value={form.email}
                  onChange={onInputChange}
                  required
                  disabled
                />
              </Box>

              <Box p={1}>
                <InputField
                  name='message'
                  id='message'
                  type='text'
                  placeholder='Details of suggestions and feedback'
                  value={form.message}
                  onChange={onInputChange}
                  required
                  multiline
                  rows={4}
                />
              </Box>
            </Box>

            <Box mt={5}>
              <Button
                type='submit'
                variant='contained'
                color='success'
                fullWidth
                disabled={isLoading}
                className={isLoading ? 'buttonDisabled' : ''}
              >
                {isLoading ? <CircularProgress size={25} /> : 'Submit'}
              </Button>
            </Box>
          </form>
        </Grid>
      </Box>
    </Box>
  )
}

export default SupportAndFeedback
