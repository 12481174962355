import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { routeUrls, userRoles } from 'src/utils/constants'

const LoginLayout = () => {
  const { isAuthenticated, userInfo, token, role, isSignupCompleted } = useSelector((state: RootState) => state.auth)
  const location = useLocation()

  if (isAuthenticated === true && userInfo && token && isSignupCompleted) {
    if (role === userRoles.healthCare) {
      return <Navigate to={routeUrls.home} state={{ from: location }} />
    }
    if (role === userRoles.nonHealthCare) {
      return <Navigate to={routeUrls.nonHealthCareUser.home} state={{ from: location }} />
    }
  }

  return (
    <Box p={2}>
      <Outlet />
    </Box>
  )
}

export default LoginLayout
