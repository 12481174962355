import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Speciality } from 'src/api/createFeed/types'

type InitialStateType = {
  mobileOpen: boolean
  isPageLoading: boolean
  selectedSpecialitiesFilter: Speciality[],
  sortBy: string
}

const initialState: InitialStateType = {
  mobileOpen: false,
  isPageLoading: false,
  selectedSpecialitiesFilter: [],
  sortBy: 'most_recent',
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    handleDrawerToggle(state) {
      state.mobileOpen = !state.mobileOpen
    },
    startPageLoader(state) {
      state.isPageLoading = true
    },
    stopPageLoader(state) {
      state.isPageLoading = false
    },
    setSelectedSpecialitiesFilter(state, action: PayloadAction<Speciality[]>) {
      state.selectedSpecialitiesFilter = action.payload;
    },
    setSortBy(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },
  },
})

export const { handleDrawerToggle, startPageLoader, stopPageLoader, setSelectedSpecialitiesFilter, setSortBy } = commonSlice.actions

export default commonSlice.reducer
