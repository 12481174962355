import React from 'react'
import { useListCommentsQuery } from 'src/api/feed'
import { Box, CircularProgress, Grid, Avatar, Typography, IconButton } from '@mui/material'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from './index.module.scss'
import LikeIcon from 'src/assets/icons/thumbs-up.png'
import ThinkingIcon from 'src/assets/icons/thinking.png'
import { CommentResponseData } from 'src/api/feed/types'
import CommentItem from './CommentItem'

dayjs.extend(relativeTime)
type CommentListProps = {
  data: CommentResponseData | undefined
  isLoading: boolean
  refetch: () => void
}
const CommentList = ({ data, isLoading, refetch }: CommentListProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  if (isLoading) {
    return (
      <Box textAlign='center'>
        <CircularProgress />
      </Box>
    )
  }

  if (data?.comments.length === 0)
    return (
      <Box textAlign='center'>
        <Typography variant='h6'> No comments found </Typography>
      </Box>
    )

  return (
    <Box>
      {data?.comments.map((comment) => {
        return <CommentItem key={comment.id} comment={comment} refetch={refetch} />
      })}
    </Box>
  )
}

export default CommentList
