import React, { useState, useEffect } from 'react'
import Header from 'src/Layout/LoginLayout/Header'
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material'
import InputField from 'src/Component/InputField'
import { Link } from 'react-router-dom'
import styles from '../index.module.scss'
import { EMAIL_REGEX, routeUrls, userRoles } from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { registerNonHealthUser } from 'src/store/features/auth/authActions'
import { passwordValidation } from 'src/utils/helpers'
import { setAuthErrorReducer } from 'src/store/features/auth/authSlice'
import SocialLogin from 'src/Component/SocialLogin'
import dayjs from 'dayjs'
const NonHealthcareUserRegistration = () => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    createPassword: '',
    dob: '',
    termAndPolicy: false,
  })

  const dispatch = useDispatch<AppDispatch>()
  const { loading, error } = useSelector((state: RootState) => state.auth)

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleTermAndPolicyClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      termAndPolicy: event.target.checked,
    })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const passwordValidationError = passwordValidation(form.createPassword)
    if (passwordValidationError !== '') {
      dispatch(setAuthErrorReducer(passwordValidationError))
      return
    }

    if (!EMAIL_REGEX.test(form.email)) {
      dispatch(setAuthErrorReducer('Please enter a valid email address.'))
      return
    }

    if (form.phoneNumber && form.phoneNumber.toString().length < 10) {
      dispatch(setAuthErrorReducer('Phone number should be atleast 10 digit long.'))
      return
    }

    const payload: any = {
      // eslint-disable-next-line camelcase
      first_name: form.firstName,
      // eslint-disable-next-line camelcase
      last_name: form.lastName,
      email: form.email,
      password: form.createPassword,
    }

    if (form.phoneNumber) {
      payload.phone = form.phoneNumber
    }

    if (form.dob) {
      payload.dob = form.dob
    }

    dispatch(registerNonHealthUser(payload))
  }

  useEffect(() => {
    dispatch(setAuthErrorReducer(''))
  }, [])

  return (
    <Box>
      <Header />

      <Box mt={10}>
        <Grid container>
          <Grid xs={12} sm={12} md={3} lg={3.5}>
            {''}
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={5}>
            <Typography textAlign='center' variant='h4'>
              Create Account
            </Typography>
            <Box mt={1} p={2} className={styles.createAccountFormWrapper}>
              <form onSubmit={handleSubmit}>
                <Box p={1}>
                  <InputField
                    name='firstName'
                    id='firstName'
                    type='text'
                    placeholder='First Name'
                    value={form.firstName}
                    onChange={onInputChange}
                    required
                    regex={/[^a-zA-Z'\s]+/}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='lastName'
                    id='lastName'
                    type='text'
                    placeholder='Last Name'
                    value={form.lastName}
                    onChange={onInputChange}
                    required
                    regex={/[^a-zA-Z'\s]+/}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='email'
                    id='email'
                    type='email'
                    autoComplete='new-password'
                    placeholder='Email'
                    value={form.email}
                    onChange={onInputChange}
                    required
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='phoneNumber'
                    id='phoneNumber'
                    type='text'
                    regex={/[^0-9]*$/}
                    placeholder='Phone Number'
                    value={form.phoneNumber}
                    onChange={onInputChange}
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='createPassword'
                    id='createPassword'
                    type='password'
                    autoComplete='new-password'
                    placeholder='Create Password'
                    value={form.createPassword}
                    onChange={onInputChange}
                    required
                  />
                </Box>

                <Box p={1}>
                  <InputField
                    name='dob'
                    id='dob'
                    type='date'
                    placeholder='Date of Birth'
                    value={form.dob}
                    onChange={onInputChange}
                    max={(() => {
                      const today = new Date()
                      today.setFullYear(today.getFullYear() - 18)
                      const date = dayjs(today).format('YYYY-MM-DD')
                      return date
                    })()}
                  />
                </Box>

                <Box p={1} pt={0}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.termAndPolicy}
                        onChange={handleTermAndPolicyClick}
                        color='success'
                        required
                      />
                    }
                    label={
                      <Typography variant='subtitle2' component='span'>
                        I have read and agree to the{' '}
                        <Link to={routeUrls.termAndCondition}>Terms of Service</Link> and{' '}
                        <Link to={routeUrls.privacyPolicy}>Privacy Notice</Link>
                      </Typography>
                    }
                  />
                </Box>

                <Typography variant='subtitle2' color='red' textAlign='center'>
                  {error}
                </Typography>

                <Box p={1}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='success'
                    fullWidth
                    disabled={loading}
                    className={loading ? 'buttonDisabled' : ''}
                  >
                    {loading ? <CircularProgress size={25} /> : 'Continue'}
                  </Button>
                </Box>

                <Box pt={1} pb={1} pl={10} pr={10}>
                  <Divider light>or</Divider>
                </Box>

                <Box p={1}>
                  <SocialLogin userCategory={userRoles.nonHealthCare} />
                </Box>
              </form>
            </Box>
            <Box mt={5}></Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default NonHealthcareUserRegistration
