import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Grid, Avatar, IconButton, CircularProgress } from '@mui/material'
import styles from './index.module.scss'
import SearchIcon from '@mui/icons-material/Search'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'

import { chatOrCommentMessageType, isSmallerDevice, pubnub } from 'src/utils/constants'
import CommentBox from 'src/Component/Feed/CommentBox'
import { useGetChannelListQuery, useUpdateChatTimestampMutation } from 'src/api/chat'
import MessageItem from 'src/Component/Chat/MessageItem'
import ReportUserModal from 'src/Component/ReportUser/ReportUserModal'
import GroupSetting from './GroupComponents/GroupSetting'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { endSingleOrGroupChat } from 'src/store/features/chat/chatSlice'
type pubnubMessageI = {
  message: {
    title: string
    description: string
  }
  file?: {
    id: string
    name: string
  }
  timetoken: string
  uuid: string
}
const ChatWindow = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { fromUser, toUsersArr, channelId, chatInfo, chatType } = useSelector(
    (state: RootState) => state.chat,
  )
  const [message, setMessage] = useState<string>('')
  const [isSendingMessage, setSendingMessage] = useState(false)
  const [isFetchingMessageList, setFetchingMessageList] = useState(false)
  const [updateChatTimestamp] = useUpdateChatTimestampMutation()

  const [messageList, setMessageList] = useState<pubnubMessageI[]>([])
  const messageListEndRef = useRef<HTMLDivElement>(null)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [reportUserId, setReportUserId] = React.useState<number>(0)

  const [groupSettingAnchorEl, setGroupSettingAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  )

  const handlePopoverMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (chatType === 'single' && toUsersArr.length === 1) {
      setAnchorEl(event.currentTarget)
      setReportUserId(toUsersArr[0].id)
    } else if (chatType === 'group') {
      setGroupSettingAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setGroupSettingAnchorEl(null)
  }

  const scrollToBottom = () => {
    messageListEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const listener = {
    //  A message is received from PubNub.  This is the entry point for all messages on all
    //  channels or channel groups, though this application only uses a single channel.
    message: (receivedMsg: any) => {
      setMessageList((prevState) => {
        return [
          ...prevState,
          {
            message: receivedMsg.message,
            timetoken: receivedMsg.timetoken,
            uuid: receivedMsg.publisher,
          },
        ]
      })
    },

    file: (receivedFile: any) => {
      setMessageList((prevState) => {
        return [
          ...prevState,
          {
            message: receivedFile.message,
            file: receivedFile.file,
            timetoken: receivedFile.timetoken,
            uuid: receivedFile.publisher,
          },
        ]
      })
    },
  }

  const handleSendMessage = () => {
    if (!message) return
    setSendingMessage(true)
    pubnub
      .publish({
        channel: channelId,
        message: {
          title: chatOrCommentMessageType.text,
          description: message,
        },
      })
      .then(() => {
        setMessage('')
      })
      .finally(() => {
        setSendingMessage(false)
      })
  }

  const handleSendVoiceMemo = (file: File, duration: number) => {
    if (file) {
      setSendingMessage(true)
      pubnub
        .sendFile({
          channel: channelId,
          file: file,
          message: {
            title: chatOrCommentMessageType.audio,
            description: duration,
          },
        })
        .finally(() => {
          setSendingMessage(false)
        })
    }
  }

  const handleSendImage = (file: File) => {
    if (file) {
      setSendingMessage(true)
      pubnub
        .sendFile({
          channel: channelId,
          file: file,
          message: {
            title: chatOrCommentMessageType.image,
            description: '',
          },
        })
        .finally(() => {
          setSendingMessage(false)
        })
    }
  }

  const handleUpdateChatTimestamp = () => {
    if (fromUser && channelId) {
      updateChatTimestamp({
        channelId: channelId,
        data: {
          // eslint-disable-next-line camelcase
          user_id: fromUser.id,
          timestamp: (new Date().getTime() * 10000).toString(),
        },
      })
    }
  }

  const initiatePubNub = async () => {
    pubnub.setUUID(fromUser?.id.toString() || '')
    await pubnub.objects.setMemberships({
      channels: [channelId],
    })
    pubnub.subscribe({ channels: [channelId], withPresence: true })
  }

  const fetchMessageHistory = (callback: () => void) => {
    pubnub
      .fetchMessages({
        channels: [channelId],
        includeUUID: true,
      })
      .then((res: any) => {
        if (res?.channels?.[channelId]) {
          const _messageList: pubnubMessageI[] = res.channels[channelId].map((row: any) => {
            if (row.message.file) {
              return {
                message: row.message.message,
                file: row.message.file,
                timetoken: row.timetoken,
                uuid: row.uuid,
              }
            }
            return {
              message: row.message,
              file: null,
              timetoken: row.timetoken,
              uuid: row.uuid,
            }
          })

          setMessageList(_messageList)
        } else {
          setMessageList([])
        }
      })
      .finally(() => {
        if (typeof callback === 'function') {
          callback()
        }
      })
  }

  const handleExitChat = () => {
    dispatch(endSingleOrGroupChat())
  }

  const unMount = () => {
    setGroupSettingAnchorEl(null)
    handleUpdateChatTimestamp()
    pubnub.unsubscribe({ channels: [channelId] })
    pubnub.removeListener(listener)
  }
  useEffect(() => {
    if (fromUser && toUsersArr && channelId && chatInfo && chatType) {
      handleUpdateChatTimestamp()
      setFetchingMessageList(true)
      initiatePubNub().then(() => {
        pubnub.addListener(listener)
        fetchMessageHistory(() => {
          setFetchingMessageList(false)
        })
      })

      return () => {
        unMount()
      }
    }
  }, [fromUser, toUsersArr, channelId, chatInfo, chatType])

  useEffect(() => {
    scrollToBottom()
  }, [messageList])

  type toUserMapValueI = {
    id: number
    name: string
    profilePic: string | null
  }
  const toUsersMap = new Map<number, toUserMapValueI>()
  toUsersArr.forEach((user) => {
    toUsersMap.set(user.id, {
      id: user.id,
      name: user.firstName + ' ' + user.lastName,
      profilePic: user.profilePic,
    })
  })

  if (!fromUser || !toUsersArr.length || !channelId || !chatInfo || !chatType) {
    if (isSmallerDevice) {
      // if device has smaller screen then do not render RecentChat with ChatWindow
      return null
    }
    return (
      <Box className={styles.chatWindowWrapper}>
        <Grid container justifyContent='center' alignItems='center' height='100%'>
          <Typography variant='subtitle1' fontWeight={200}>
            Select any user or group for chat
          </Typography>
        </Grid>
      </Box>
    )
  }

  return (
    <Box className={styles.chatWindowWrapper}>
      <Box className={styles.backgroundColor} mb={3} p={1}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Grid container alignItems='center' p={1}>
              {isSmallerDevice && (
                <Grid item>
                  <IconButton onClick={handleExitChat}>
                    <ArrowBackIosIcon color='secondary' />
                  </IconButton>
                </Grid>
              )}

              <Grid item>
                <Avatar
                  alt={chatInfo.name}
                  src={chatInfo.icon || 'broken-image.png'}
                  className={styles.feedAvatar}
                />
              </Grid>
              <Grid item pl={1}>
                <Typography variant={'h5'}>{chatInfo.name}</Typography>
              </Grid>
            </Grid>
          </Grid>

          {chatType === 'group' && (
            <Grid item>
              <IconButton onClick={handlePopoverMenuOpen}>
                <MoreVertIcon color='primary' />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box mt={1} sx={{ height: '100%' }}>
        <Box m={2} sx={{ height: 'calc(100% - 80px)', overflowY: 'scroll' }}>
          {isFetchingMessageList ? (
            <Grid justifyContent='center' container>
              <CircularProgress />
            </Grid>
          ) : (
            messageList.map((message, i) => {
              console.log(message)

              return (
                <MessageItem
                  timetoken={message.timetoken}
                  channelId={channelId}
                  key={i}
                  message={{
                    title: message.message.title,
                    description: message.message.description,
                  }}
                  file={message.file || null}
                  fromUser={{
                    id: fromUser.id,
                    name: fromUser.firstName,
                    profilePic: fromUser.profilePic,
                  }}
                  toUser={toUsersMap.get(Number(message.uuid)) || null}
                  isOwn={message.uuid == fromUser.id.toString()}
                />
              )
            })
          )}

          <div ref={messageListEndRef}></div>
        </Box>

        <Grid sx={{ height: '80px' }} container justifyContent='center'>
          <Grid item className={styles.backgroundColor} maxWidth='630px' flexGrow={2}>
            <CommentBox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
              isLoading={isSendingMessage}
              handleAddComment={handleSendMessage}
              handleAddVoiceMemo={handleSendVoiceMemo}
              comment={message}
              placeholder='Add a message'
              onSpeechToTextResponse={(value: string) => {
                setMessage(value)
              }}
              handleImageUpload={handleSendImage}
              hideAnonymousFeature
            />
          </Grid>
        </Grid>
      </Box>

      <ReportUserModal
        isPopoverOpen={!!anchorEl}
        onPopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
        reportId={reportUserId}
      />

      <GroupSetting
        isPopoverOpen={!!groupSettingAnchorEl}
        onPopoverClose={handlePopoverClose}
        anchorEl={groupSettingAnchorEl}
        channelId={channelId}
        isAdmin={!!fromUser.isAdmin}
      />
    </Box>
  )
}

export default ChatWindow
