import React, { useState } from 'react'
import { Box, Typography, Button, Grid, Popover, CircularProgress } from '@mui/material'
import styles from './index.module.scss'
import ChatIcon from 'src/assets/icons/chats.png'
import RecentChatRow from 'src/Component/Chat/RecentChatRow'
import NewChatPopUp from './NewChatPopUp'
import { useGetChannelListQuery, useLeaveGroupMutation } from 'src/api/chat'
import { ChannelI } from 'src/api/chat/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import {
  endSingleOrGroupChat,
  initiateGroupChat,
  initiateSingleChat,
  refetchChannelList,
} from 'src/store/features/chat/chatSlice'
import LogoutIcon from 'src/assets/icons/svg/LogoutIcon'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { isSmallerDevice } from 'src/utils/constants'

const RecentChats = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const { channelId, refetchChannelCounter, fromUser, toUsersArr, chatInfo, chatType } =
    useSelector((state: RootState) => state.chat)
  const dispatch = useDispatch<AppDispatch>()
  const {
    data: channelList = [],
    isLoading: isFetchingChannelList,
    isFetching,
  } = useGetChannelListQuery(
    {
      refetchChannelCounter,
    },
    { refetchOnMountOrArgChange: true },
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [anchorChatRowEl, setAnchorChatRowEl] = React.useState<HTMLButtonElement | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<ChannelI>()
  const [leaveGroup] = useLeaveGroupMutation()

  const handleNewChatPopUpOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNewChatPopUpClose = () => {
    setAnchorEl(null)
  }

  const handleChannelClick = (channel: ChannelI) => {
    console.log(channel)
    if (userInfo && channel.channel_type === 'single') {
      const fromUser = channel.users.find((user) => user.id == userInfo.id) || null
      const toUser = channel.users.find((user) => user.id != userInfo.id) || null
      if (fromUser && toUser) {
        dispatch(
          initiateSingleChat({
            fromUser: {
              id: fromUser.id,
              firstName: fromUser.first_name,
              lastName: fromUser.last_name,
              profilePic: fromUser.profile_picture,
            },
            toUser: {
              id: toUser.id,
              firstName: toUser.first_name,
              lastName: toUser.last_name,
              profilePic: toUser.profile_picture,
            },
            channelId: channel.channel_id || '',
          }),
        )
      }
    } else if (userInfo && channel.channel_type === 'group') {
      const fromUser = channel.users.find((user) => user.id == userInfo.id) || null
      const toUsersArr = channel.users.filter((user) => user.id != userInfo.id) || []
      if (fromUser && toUsersArr.length) {
        dispatch(
          initiateGroupChat({
            fromUser: {
              id: fromUser.id,
              firstName: fromUser.first_name,
              lastName: fromUser.last_name,
              profilePic: fromUser.profile_picture,
              isAdmin: !!channel.admins.find((id) => id === fromUser.id),
            },
            toUsersArr: (() => {
              return toUsersArr.map((toUser: any) => {
                return {
                  id: toUser.id,
                  firstName: toUser.first_name,
                  lastName: toUser.last_name,
                  profilePic: toUser.profile_picture,
                  isAdmin: !!channel.admins.find((id) => id === toUser.id),
                }
              })
            })(),
            channelId: channel.channel_id || '',
            groupInfo: {
              name: channel.channel_name || '',
              icon: channel.channel_icon || '',
            },
          }),
        )
      }
    }
  }

  const handleContextMenu = (e: any, channel: ChannelI) => {
    e.preventDefault()
    if (channel.channel_type === 'group') {
      setAnchorChatRowEl(e.currentTarget)
      setSelectedGroup(channel)
    }
  }

  const handleChatRowPopoverClose = () => {
    setAnchorChatRowEl(null)
  }

  const handleLeaveGroup = () => {
    if (!selectedGroup) return
    dispatch(startPageLoader())
    leaveGroup({
      channelId: selectedGroup.channel_id,
      userId: userInfo?.id || 0,
    })
      .unwrap()
      .then(() => {
        dispatch(endSingleOrGroupChat())
        handleChatRowPopoverClose()
        dispatch(refetchChannelList())
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  if ( isSmallerDevice && fromUser && toUsersArr.length && channelId && chatInfo && chatType) {
    // if device has smaller screen then do not render RecentChat with ChatWindow
    return null
  }

  return (
    <Box className={styles.recentChatsWrapper}>
      <Box className={styles.backgroundColor} mb={3} p={1}>
        <Grid container pl={5} justifyContent='space-between'>
          <Typography variant='subtitle1' fontSize='24px'>
            {' '}
            Chats{' '}
          </Typography>

          <Button
            onClick={handleNewChatPopUpOpen}
            type='button'
            variant='contained'
            color='success'
            sx={{
              height: 56,
              width: 56,
              padding: 0,
              minWidth: 'fit-content',
              minHeight: 'fit-content',
            }}
          >
            <img src={ChatIcon} />
          </Button>
        </Grid>
      </Box>

      <Box className={styles.backgroundColor} mt={1} sx={{ height: '100%', overflowY: 'scroll' }}>
        {isFetchingChannelList || isFetching ? (
          <Grid container justifyContent='center' p={2}>
            <CircularProgress />
          </Grid>
        ) : (
          channelList.map((channel) => (
            <RecentChatRow
              key={channel.channel_id}
              channel={channel}
              onClick={handleChannelClick}
              activeChannelId={channelId}
              onContextMenu={handleContextMenu}
            />
          ))
        )}
      </Box>

      {!!anchorEl && <NewChatPopUp anchorEl={anchorEl} onClose={handleNewChatPopUpClose} />}

      <Popover
        open={!!anchorChatRowEl}
        anchorEl={anchorChatRowEl}
        onClose={handleChatRowPopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Typography
          alignItems='center'
          sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
          onClick={handleLeaveGroup}
          color='red'
        >
          <LogoutIcon /> &nbsp; Leave Group
        </Typography>
      </Popover>
    </Box>
  )
}

export default RecentChats
