import React, { useState } from 'react'
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import InputField from 'src/Component/InputField'

import styles from './index.module.scss'
import { routeUrls } from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { generateOtpApi } from 'src/store/features/forgotPassword/forgotPasswordAction'
import { AppDispatch, RootState } from 'src/store'
import { setEmailReducer } from 'src/store/features/forgotPassword/forgotPasswordSlice'

type AskEmailAndSendOtpProps = {
  callback: () => void
}

const AskEmailAndSendOTP = (props: AskEmailAndSendOtpProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error } = useSelector((state: RootState) => state.forgotPassword)
  const [email, setEmail] = useState<string>('')

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleSendOtp = (e: React.SyntheticEvent) => {
    e.preventDefault()

    dispatch(generateOtpApi({ email: email }))
      .then((res: any) => {
        if (!res.error) {
          dispatch(setEmailReducer(email))
          props.callback()
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  return (
    <Box>
      <Box className={styles.title}>
        <Typography variant='h3'> Forgot Password </Typography>
        <Typography variant='subtitle2'>
          Please enter your email address and we’ll send <br /> you a 6 digit PIN to reset your
          password.
          <br /> Know your password? <Link to={routeUrls.login}>Login</Link>
        </Typography>
      </Box>
      <Box className={styles.formWrapper}>
        <form onSubmit={handleSendOtp}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
            p={10}
            height='100%'
            className={styles.wrapperNestedDiv}
          >
            <Grid item>
              <InputField
                name='email'
                id='email'
                autoComplete='new-password'
                type='text'
                placeholder='Email'
                value={email}
                onChange={onInputChange}
                required
              />
              <Typography p={1} variant='subtitle2' color='red' textAlign='center'>
                {error}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant='contained'
                color='success'
                fullWidth
                type='submit'
                disabled={loading}
                className={loading ? 'buttonDisabled' : ''}
              >
                {loading ? <CircularProgress size={25} /> : 'Send OTP'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}

export default AskEmailAndSendOTP
