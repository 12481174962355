import React, {useEffect} from 'react'
import { Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom'
import { Box, CssBaseline } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import Sidebar from './Sidebar'
import { drawerWidth, routeUrls, userRoles, localStorageKeys } from 'src/utils/constants'
import PageLoader from './PageLoader'

const HomeLayout = () => {
  const { isAuthenticated, userInfo, token, role, isSignupCompleted } = useSelector((state: RootState) => state.auth)
  const location = useLocation()

  if (isAuthenticated === false || !userInfo || !token) {
    return <Navigate to={routeUrls.login} state={{ from: location }} />
  }

  if (role === userRoles.nonHealthCare) {
    return <Navigate to={routeUrls.nonHealthCareUser.home} state={{ from: location }} />
  }

 
  if(role === userRoles.healthCare && !isSignupCompleted){
    return <Navigate to={routeUrls.completeHealthcareUserRegistration} state={{ from: location }} />
  }
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Sidebar />

      <Box
        component='main'
        sx={{
          marginTop: '65px',
          flexGrow: 1,
          p: location.pathname === routeUrls.chat ? 0 : 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>

      <PageLoader />
    </Box>
  )
}

export default HomeLayout
