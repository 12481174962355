import React, { useState } from 'react'
import { Box, Popover, Typography } from '@mui/material'
import dangerIcon from 'src/assets/icons/danger-icon.png'
import personIcon from 'src/assets/icons/personIcon.png'

import infoIcon from 'src/assets/icons/infoIcon.png'

import deleteIcon from 'src/assets/icons/deleteIcon.png'

import CustomizedDialogBox from 'src/Component/CustomizedDialogBox'
import GroupInfo from './GroupInfo'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDeleteChatGroupMutation } from 'src/api/chat'
import { endSingleOrGroupChat, refetchChannelList } from 'src/store/features/chat/chatSlice'
import AddConnectionToGroup from './AddConnectionToGroup'

type GroupSettingProps = {
  isPopoverOpen: boolean
  anchorEl: HTMLButtonElement | null
  onPopoverClose: () => void
  channelId: string
  isAdmin: boolean
}

const GroupSetting = ({
  isPopoverOpen,
  anchorEl,
  onPopoverClose,
  channelId,
  isAdmin,
}: GroupSettingProps) => {
  const [openGroupInfo, setOpenGroupInfo] = useState<boolean>(false)
  const [openAddConnectionModal, setOpenAddConnectionModal] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const [deleteChatGroup] = useDeleteChatGroupMutation()

  const handleGroupModalOpen = () => {
    setOpenAddConnectionModal(false)
    setOpenGroupInfo(true)
    onPopoverClose()
  }

  const handleAddConnectionModalOpen = () => {
    setOpenGroupInfo(false)
    setOpenAddConnectionModal(true)
    onPopoverClose()
  }

  const handleDeleteGroup = () => {
    if (!isAdmin) return
    dispatch(startPageLoader())
    deleteChatGroup(channelId)
      .unwrap()
      .then(() => {
        dispatch(endSingleOrGroupChat())
        dispatch(refetchChannelList())
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  return (
    <Box>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isAdmin && (
          <Typography
            alignItems='center'
            sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
            onClick={handleAddConnectionModalOpen}
            color='white'
          >
            <img src={personIcon} height={20} alt='Flag post' style={{ paddingRight: '10px' }} />
            Add Connect to Group
          </Typography>
        )}

        <Typography
          alignItems='center'
          sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
          onClick={handleGroupModalOpen}
          color='white'
        >
          <img src={infoIcon} height={20} alt='Flag post' style={{ paddingRight: '10px' }} />
          Group Info
        </Typography>

        {isAdmin && (
          <Typography
            alignItems='center'
            sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
            onClick={handleDeleteGroup}
            color='white'
          >
            <img src={deleteIcon} height={20} alt='Flag post' style={{ paddingRight: '10px' }} />
            Delete Group
          </Typography>
        )}
      </Popover>

      <CustomizedDialogBox open={openGroupInfo} onClose={() => setOpenGroupInfo(false)}>
        <GroupInfo onAddConnectionClick={handleAddConnectionModalOpen} />
      </CustomizedDialogBox>

      <CustomizedDialogBox
        open={openAddConnectionModal}
        onClose={() => setOpenAddConnectionModal(false)}
      >
        <AddConnectionToGroup onClose={() => setOpenAddConnectionModal(false)} />
      </CustomizedDialogBox>
    </Box>
  )
}

export default GroupSetting
