import React from 'react'
import { Grid, Avatar, Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from './index.module.scss'

dayjs.extend(relativeTime)

type UserAvatarProps = {
  name: string
  profilePic: string | null
  createdAt: string
  vairant?: 'normal' | 'small'
}
const UserAvatar = ({ name, profilePic, createdAt, vairant = 'normal' }: UserAvatarProps) => {
  return (
    <Grid container alignItems='center' >
      <Grid item>
        <Avatar
          alt={name}
          src={profilePic || 'broken-image.png'}
          className={vairant === 'normal' ? styles.feedAvatar : styles.feedAvatarSmall}
        />
      </Grid>
      <Grid item pl={1}>
        <Typography variant={vairant === 'normal' ? 'h5' : 'subtitle1'}>{name}</Typography>
        <Typography variant='subtitle2' fontSize='0.7rem'>
          {dayjs(createdAt).fromNow()}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default UserAvatar
