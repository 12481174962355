import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import styles from './index.module.scss'
import { Box, Dialog, Typography } from '@mui/material'
import brokenImage from 'src/assets/images/broken-image.png'
import eyeCloseIcon from 'src/assets/icons/eye-close.png'
type ImageCarouselProps = {
  activeImageIndex: number
  images: { id: number; image: string; annotations?: string }[]
  isPhotoBlur: boolean
}
const ImageCarousel = ({ activeImageIndex, images, isPhotoBlur }: ImageCarouselProps) => {
  const [open, setOpen] = React.useState(false)
  const [activeImage, setActiveImage] = useState('')
  const handleClickOpen = (image: string) => {
    setActiveImage(image)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Carousel
          className={styles.carousel}
          navButtonsAlwaysInvisible
          autoPlay={false}
          cycleNavigation={true}
          swipe={true}
          indicators={false}
          animation='fade'
          fullHeightHover={false}
          changeOnFirstRender
          // activeIndicatorIconButtonProps={{
          //   style: {
          //     color: '#34D695',
          //   },
          // }}
          height='350px'
          //   indicatorContainerProps={{
          //     style: {
          //       margin: '0px',
          //       padding: '0px',
          //     },
          //   }}
          index={activeImageIndex}
        >
          {images.map((image) => {
            return (
              <Box
                key={image.id}
                sx={{
                  height: '100%',
                  
                }}
                className='annotation-tooltip-wrapper'
              >
                <img
                  onClick={() => handleClickOpen(image.image)}
                  src={image.image}
                  style={{
                    height: '95%',
                    // width: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    cursor:'pointer'
                  }}
                  onError={(evt) => {
                    evt.currentTarget.src = brokenImage
                  }}
                  className={isPhotoBlur ? styles.blurImage : ''}
                />
                {image.annotations && (
                  <div className='annotation-tooltiptext'>{image.annotations}</div>
                )}
              </Box>
            )
          })}
        </Carousel>

        {isPhotoBlur && (
          <Box className={styles.blurImageText}>
            <Typography variant='h5'>
              <img src={eyeCloseIcon} />
            </Typography>
            <Typography variant='h5'>Graphic content</Typography>
            <Typography variant='subtitle1'>
              This image contains content <br /> that some people may find
              <br /> upsetting
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ overflow: 'hidden', height: '100vh', widht: '100vw' }}>
          <img
            src={activeImage}
            style={{
              height: '100%',
              width: '100%',
              borderRadius: '10px',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Dialog>
    </>
  )
}

export default ImageCarousel
