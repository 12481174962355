import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, Typography } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2, mt: 5, position: 'relative' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 0,
            color: 'red',
          }}
          size='large'
        >
          <CloseIcon fontSize='large' />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

type CustomizedDialogBoxProps = {
  open: boolean
  title?: string
  onClose: () => void
  onSubmit?: () => void
  children: React.ReactElement
  loading?: boolean
}
export default function CustomizedDialogBox({
  open,
  onClose,
  title,
  children,
  onSubmit,
  loading = false,
}: CustomizedDialogBoxProps) {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      fullWidth
    >
      {!!title && (
        <BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
          <Typography variant='h4'> {title}</Typography>
        </BootstrapDialogTitle>
      )}
      <DialogContent sx={{border:'1px solid grey', borderRadius:'8px'}}>{children}</DialogContent>
      {!!onSubmit && typeof onSubmit === 'function' && (
        <DialogActions>
          <Button
            type='button'
            onClick={onSubmit}
            variant='contained'
            color='success'
            fullWidth
            disabled={loading}
            className={loading ? 'buttonDisabled' : ''}
          >
            {loading ? <CircularProgress size={25} /> : 'Submit'}
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  )
}
