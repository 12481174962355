import React from 'react'
import Badge from '@mui/material/Badge'

type CarouselIndicator = {
  imagesCount: number
  onIndexChange: (index: number) => void
  activeIndex: number
}

const CarouselIndicator = ({ imagesCount, onIndexChange, activeIndex }: CarouselIndicator) => {
  const indicators = []

  if (imagesCount <= 3) {
    for (let i = 0; i < imagesCount; i++) {
      indicators.push(
        <span
          key={i}
          style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
          onClick={() => onIndexChange(i)}
        >
          <Badge
            variant='dot'
            badgeContent={' '}
            color={i === activeIndex ? 'primary' : 'secondary'}
          />
        </span>,
      )
    }

    return <div>{indicators}</div>
  }

  if (activeIndex > 0) {
    indicators.push(
      <span style={{ marginLeft: '5px', marginRight: '5px' }}>
        <Badge
          variant='dot'
          slotProps={{
            badge: {
              style: {
                minWidth: '6px',
                height: '6px',
              },
            },
          }}
          badgeContent={' '}
          color={'secondary'}
        />
      </span>,
    )
  }

  if (activeIndex === 0) {
    // if activeindex is 0
    indicators.push(
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(0)}
      >
        <Badge variant='dot' badgeContent={' '} color={'primary'} />
      </span>,
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(1)}
      >
        <Badge variant='dot' badgeContent={' '} color={'secondary'} />
      </span>,
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(2)}
      >
        <Badge variant='dot' badgeContent={' '} color={'secondary'} />
      </span>,
    )
  } else if (activeIndex === imagesCount - 1) {
    // if activeindex is last index
    indicators.push(
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(imagesCount - 3)}
      >
        <Badge variant='dot' badgeContent={' '} color={'secondary'} />
      </span>,
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(imagesCount - 2)}
      >
        <Badge variant='dot' badgeContent={' '} color={'secondary'} />
      </span>,
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(imagesCount - 1)}
      >
        <Badge variant='dot' badgeContent={' '} color={'primary'} />
      </span>,
    )
  } else {
    // if activeindex is middle
    indicators.push(
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(activeIndex - 1)}
      >
        <Badge variant='dot' badgeContent={' '} color={'secondary'} />
      </span>,
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(activeIndex)}
      >
        <Badge variant='dot' badgeContent={' '} color={'primary'} />
      </span>,
      <span
        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
        onClick={() => onIndexChange(activeIndex + 1)}
      >
        <Badge variant='dot' badgeContent={' '} color={'secondary'} />
      </span>,
    )
  }


  if (activeIndex < imagesCount - 1) {
    indicators.push(
      <span style={{ marginLeft: '5px', marginRight: '5px' }}>
        <Badge
          variant='dot'
          slotProps={{
            badge: {
              style: {
                minWidth: '6px',
                height: '6px',
              },
            },
          }}
          badgeContent={' '}
          color={'secondary'}
        />
      </span>,
    )
  }
  return <div>{indicators}</div>
}

export default CarouselIndicator
