export function dataURLtoFile(dataurl: string, filename: string): File | null {
  const arr = dataurl.split(',')
  if (arr.length) {
    const mime = arr[0].match(/:(.*?);/)?.[1]
    const bstr = atob(arr[arr.length - 1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }
  return null
}

export async function preFetchImages(imagesSrc: string[]) {
  const promises = await imagesSrc.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        resolve(1)
      }
      img.onerror = () => {
        reject(0)
      }
    })
  })

  await Promise.all(promises)
}

export function passwordValidation(password: string): string {
  let errMsg = ''
  const uppercaseRegExp = /(?=.*?[A-Z])/
  const lowercaseRegExp = /(?=.*?[a-z])/
  const digitsRegExp = /(?=.*?[0-9])/
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/
  const minLengthRegExp = /.{8,}/

  const passwordLength = password.length
  const uppercasePassword = uppercaseRegExp.test(password)
  const lowercasePassword = lowercaseRegExp.test(password)
  const digitsPassword = digitsRegExp.test(password)
  const specialCharPassword = specialCharRegExp.test(password)
  const minLengthPassword = minLengthRegExp.test(password)
  if (passwordLength === 0) {
    errMsg = 'Password is empty'
  } else if (!uppercasePassword) {
    errMsg = 'Password should contain at least one Uppercase'
  } else if (!lowercasePassword) {
    errMsg = 'Password should contain at least one Lowercase'
  } else if (!digitsPassword) {
    errMsg = 'Password should contain at least one digit'
  } else if (!specialCharPassword) {
    errMsg = 'Password should contain at least one Special Characters'
  } else if (!minLengthPassword) {
    errMsg = 'Password should contain at least minumum 8 characters'
  } else {
    errMsg = ''
  }
  return errMsg
}

// eslint-disable-next-line camelcase
export function secondsTo_ms_format(s: number): string {
  s = Math.round(s)
  const m = Math.floor(s / 60)

  s -= m * 60

  let minStr = m.toString()
  let secStr = s.toString()

  if (m < 10) {
    minStr = '0' + m.toString()
  }

  if (s < 10) {
    secStr = '0' + s.toString()
  }

  return `${minStr}:${secStr}`
}

export async function downloadFileFromUrl(fileUrl: string, fileName: string, isComment = false) {
  try {
    // const image = await fetch(fileUrl)
    if (isComment) {
      const image = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      const image = await fetch(fileUrl)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    // const imageBlog = await image.blob()
    // const imageURL = URL.createObjectURL(imageBlog)

    // const link = document.createElement('a')
    // link.href = imageURL
    // link.download = fileName
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
  } catch (error: any) {
    console.log(error)
  }
}

export function convertBase64ToFile(dataurl: string, filename: string): File | null {
  const arr = dataurl.split(',')
  if (arr.length) {
    const mimeArr = arr[0].match(/:(.*?);/)
    if (mimeArr?.length) {
      const mime = mimeArr[1]
      const bstr = atob(arr[arr.length - 1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
  }

  return null
}

// Convert file to base64 string

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const base64String = reader.result.split(',')[1] // Extract base64 part
        resolve(base64String)
      } else {
        reject(new Error('Invalid result type'))
      }
    }
    reader.onerror = reject
  })
