import React from 'react'
import AppBar from '@mui/material/AppBar'

import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { drawerWidth } from 'src/utils/constants'
import { useDispatch } from 'react-redux'
import { handleDrawerToggle } from 'src/store/features/common/commonSlice'

interface HeaderProps {
  children?: React.ReactElement[] | React.ReactElement
}

const Header = ({ children }: HeaderProps) => {
  const dispatch = useDispatch()

  return (
    <AppBar
      className='appHeader'
      position='fixed'
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      {Array.isArray(children) ? (
        <>
          <Toolbar
            sx={{
              alignItems: 'baseline',
            }}
          >
            <IconButton
              aria-label='open drawer'
              edge='start'
              onClick={() => dispatch(handleDrawerToggle())}
              sx={{ mr: 2, display: { md: 'none' }, color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            {children && children[0]}
          </Toolbar>
          {children[1]}
        </>
      ) : (
        <Toolbar
          sx={{
            alignItems: 'baseline',
          }}
        >
          <IconButton
            aria-label='open drawer'
            edge='start'
            onClick={() => dispatch(handleDrawerToggle())}
            sx={{ mr: 2, display: { md: 'none' }, color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
          {children}
        </Toolbar>
      )}
    </AppBar>
  )
}

export default Header
