import React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import theme from './utils/setup/theme'
import router from './utils/setup/router'
import store  from './store/index'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
      </Provider>
    </ThemeProvider>
  )
}

export default App
