import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import ConnectionRequestComponent from 'src/Component/ConnectionRequestComponent'
import { ConnectionRequestI } from 'src/api/userProfile/types'
import {
  useAcceptConnectionRequestMutation,
  useRejectConnectionRequestMutation,
} from 'src/api/userProfile'
import { errorToast } from 'src/utils/toastService'

type ConnectionRequests = {
  data?: ConnectionRequestI[]
  refetchData: () => void
}
const ConnectionRequests = ({ data, refetchData }: ConnectionRequests) => {
  const [actionId, setActionId] = useState(0)
  const [acceptRequest, { isLoading: isAcceptLoading }] = useAcceptConnectionRequestMutation()
  const [rejectRequest, { isLoading: isRejectLoading }] = useRejectConnectionRequestMutation()

  if (!data || data?.length === 0) {
    return (
      <Box textAlign='center' p={3}>
        <Typography variant='h5'> No connection requests found </Typography>
      </Box>
    )
  }

  const handleAcceptRequest = (requestId: number, toUserId: number) => {
    setActionId(requestId)
    acceptRequest({
      requestId,
      toUserId,
    })
      .unwrap()
      .then((res) => {
        refetchData()
      })
      .catch((err) => {
        console.log(err)
        errorToast('Unable to accept request.')
        refetchData()
      })
      .finally(() => {
        setActionId(0)
      })
  }

  const handleRejectRequest = (requestId: number, toUserId: number) => {
    setActionId(requestId)
    rejectRequest({
      requestId,
      toUserId,
    })
      .unwrap()
      .then((res) => {
        refetchData()
      })
      .catch((err) => {
        console.log(err)
        errorToast('Unable to reject request.')
        refetchData()
      })
      .finally(() => {
        setActionId(0)
      })
  }
  return (
    <Box>
      {data.map((request) => {
        return (
          <ConnectionRequestComponent
            key={request.id}
            title={request.from_user_data.title}
            firstName={request.from_user_data.first_name}
            lastName={request.from_user_data.last_name}
            profilePic={request.from_user_data.selfie || ''}
            createdAt={request.created_at}
            onAccept={() => handleAcceptRequest(request.id, request.to_user)}
            onReject={() => handleRejectRequest(request.id, request.to_user)}
            isLoading={actionId === request.id && (isAcceptLoading || isRejectLoading)}
          />
        )
      })}
    </Box>
  )
}

export default ConnectionRequests
