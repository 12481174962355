import { createSlice } from '@reduxjs/toolkit'

export type UserI = {
  id: number
  firstName: string
  lastName: string
  profilePic: string | null
  isAdmin?: boolean
}
type InitialStateType = {
  fromUser: UserI | null
  toUsersArr: UserI[]
  channelId: string
  chatInfo: {
    name: string
    icon: string
  } | null
  chatType: 'single' | 'group' | ''
  refetchChannelCounter: number
}

const initialState: InitialStateType = {
  fromUser: null,
  toUsersArr: [],
  channelId: '',
  chatInfo: null,
  chatType: '',
  refetchChannelCounter: 0,
}

type initiateSingleChatPayloadI = {
  fromUser: UserI
  toUser: UserI
  channelId: InitialStateType['channelId']
}

type initiateGroupChatPayloadI = {
  fromUser: UserI
  toUsersArr: UserI[]
  channelId: InitialStateType['channelId']
  groupInfo: InitialStateType['chatInfo']
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    initiateSingleChat(state, { payload }: { payload: initiateSingleChatPayloadI; type: string }) {
      state.chatType = 'single'
      state.chatInfo = {
        name: payload.toUser.firstName + payload.toUser.lastName,
        icon: payload.toUser.profilePic || '',
      }
      state.channelId = payload.channelId
      state.fromUser = payload.fromUser
      state.toUsersArr = [payload.toUser]
    },
    endSingleOrGroupChat(state) {
      state.chatType = ''
      state.chatInfo = null
      state.channelId = ''
      state.fromUser = null
      state.toUsersArr = []
    },

    initiateGroupChat(state, { payload }: { payload: initiateGroupChatPayloadI; type: string }) {
      state.chatType = 'group'
      state.chatInfo = payload.groupInfo
      state.channelId = payload.channelId
      state.fromUser = payload.fromUser
      state.toUsersArr = [...payload.toUsersArr]
    },

    markUserAsAdmin(state, { payload }: { payload: number; type: string }) {
      state.toUsersArr = state.toUsersArr.map((user) => {
        if (user.id === payload) {
          user.isAdmin = true
        }
        return user
      })
    },

    setToUsersArr(
      state,
      { payload }: { payload: initiateGroupChatPayloadI['toUsersArr']; type: string },
    ) {
      state.toUsersArr = [...payload]
    },

    refetchChannelList(state) {
      state.refetchChannelCounter += 1
    },

    setChatInfo(state, { payload }: { payload: InitialStateType['chatInfo']; type: string }) {
      state.chatInfo = payload
    },
  },
})

export const {
  initiateSingleChat,
  endSingleOrGroupChat,
  initiateGroupChat,
  markUserAsAdmin,
  setToUsersArr,
  refetchChannelList,
  setChatInfo,
} = chatSlice.actions

export default chatSlice.reducer
