import React from 'react'
type IconProps = {
  color?: string
}
const UserProfileIcon = ({ color = '#909090' }: IconProps) => {
  return (
    <svg width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.85953 5.06311C4.84278 5.56936 4.92591 6.07395 5.10417 6.54807C5.28243 7.02219 5.55233 7.45656 5.89846 7.82638C6.24459 8.19619 6.66018 8.49421 7.12148 8.70342C7.58279 8.91263 8.08078 9.02892 8.58703 9.04567C9.09328 9.06242 9.59787 8.97929 10.072 8.80103C10.5461 8.62277 10.9805 8.35287 11.3503 8.00674C11.7201 7.66061 12.0181 7.24503 12.2273 6.78372C12.4366 6.32242 12.5529 5.82442 12.5696 5.31817C12.5863 4.81192 12.5032 4.30733 12.325 3.83321C12.1467 3.35908 11.8768 2.92471 11.5307 2.5549C11.1845 2.18508 10.769 1.88706 10.3076 1.67786C9.84634 1.46865 9.34835 1.35235 8.8421 1.33561C8.33585 1.31886 7.83126 1.40199 7.35713 1.58025C6.88301 1.75851 6.44864 2.02841 6.07882 2.37454C5.70901 2.72067 5.41099 3.13625 5.20178 3.59756C4.99258 4.05886 4.87628 4.55686 4.85953 5.06311Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.4286 19.3335C15.7966 17.8112 14.7264 16.5101 13.3532 15.5947C11.9801 14.6793 10.3657 14.1907 8.71429 14.1907C7.06283 14.1907 5.44847 14.6793 4.07534 15.5947C2.70221 16.5101 1.63198 17.8112 1 19.3335'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UserProfileIcon
