import React from 'react'
import { Box, Typography, Chip } from '@mui/material'

type AboutSectionProps ={
  shortBio: string
  speciality: string
  medicalSchool: string
  residency: string
  fellowship: string
  boardCertification: {
    name: string;
    // number: string
  }
}
const AboutSection = ({
  shortBio,
  speciality,
  medicalSchool,
  residency,
  fellowship,
  boardCertification,
}: AboutSectionProps) => {
  return (
    <Box p={2}>
      <Box>
        <Typography variant='h5' fontWeight={600} pb={1}>
          Short Bio
        </Typography>
        <Typography variant='subtitle2'>
          {shortBio || 'NA'}
        </Typography>
      </Box>

      <Box>
        <Typography variant='h5' mt={5} fontWeight={600} pb={1}>
          Specialty
        </Typography>
        <Box>
          <Chip label={speciality || 'NA'} />
        </Box>
      </Box>

      <Box>
        <Typography variant='h5' mt={5} fontWeight={600} pb={1}>
          Graduate School
        </Typography>
        <Typography variant='subtitle2'>
          <ul
            style={{
              paddingLeft: '16px',
              margin: 0,
            }}
          >
            <li>{medicalSchool || 'NA'} </li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant='h5' mt={5} fontWeight={600} pb={1}>
          Residency
        </Typography>
        <Typography variant='subtitle2'>
          <ul
            style={{
              paddingLeft: '16px',
              margin: 0,
            }}
          >
            <li>{residency || 'NA'}</li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant='h5' mt={5} fontWeight={600} pb={1}>
          Fellowship
        </Typography>
        <Typography variant='subtitle2'>
          <ul
            style={{
              paddingLeft: '16px',
              margin: 0,
            }}
          >
            <li>{fellowship || 'NA'}</li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant='h5' mt={5} fontWeight={600} pb={1}>
          Board Certifications
        </Typography>
        <Typography variant='subtitle2'>
          <ul
            style={{
              paddingLeft: '16px',
              margin: 0,
            }}
          >
            <li> {boardCertification.name || 'NA'} </li>
            {/* <li> {boardCertification.number || 'NA'} </li> */}
          </ul>
        </Typography>
      </Box>
    </Box>
  )
}

export default AboutSection
