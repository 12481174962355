import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BASE_PATH } from 'src/utils/constants'

export const getTermAndConditionContent = createAsyncThunk(
  'staticPageContent/term-and-condition',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_PATH}/api/v1/termsandconditions/`)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const getPrivacyPolicyContent = createAsyncThunk(
  'staticPageContent/privacy-policy',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_PATH}/api/v1/privacypolicy/`)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
