import { Box, IconButton, setRef, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import styles from './index.module.scss';
import ExpandablePostDescription from './ExpandablePostDescription';
import { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
type LongDescriptionViewProps = {
  content: { id: number; title: string; description: string }[];
};
const LongDescriptionView = ({ content }: LongDescriptionViewProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onRefresh = height => {
    // console.log('height', height);
  };

  const handleNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % content.length);
  };

  const handlePrev = () => {
    setActiveIndex(prevIndex => (prevIndex - 1 + content.length) % content.length);
  };
  return (
    <>
      <Box className={styles['carousel-container']} sx={{ position: 'relative' }}>
        <Carousel
          className={styles.descriptionCarousel}
          autoPlay={false}
          navButtonsAlwaysInvisible={true}
          cycleNavigation={false}
          swipe={true}
          indicators={true}
          animation="slide"
          fullHeightHover={true}
          changeOnFirstRender
          onChange={index => setActiveIndex(index)}
          index={activeIndex}
        >
          {content.map((item, index) => {
            return (
              <Box key={String(item.id)} className={`annotation-tooltip-wrapper carousel-slide-${index}`}>
                <Typography variant="h5">{item.title}</Typography>
                <ExpandablePostDescription
                  initiallyOpen
                  onHeightChange={onRefresh}
                  text={item.description}
                  maxLength={200}
                />
              </Box>
            );
          })}
        </Carousel>
        <IconButton
          onClick={handlePrev}
          className={styles['nav-button']}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            zIndex: 1,
            color: 'white',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          className={styles['nav-button']}
          sx={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            zIndex: 1,
            color: 'white',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default LongDescriptionView;
