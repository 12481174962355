import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'src/store/features/auth/authSlice'
import forgotPasswordReducer from 'src/store/features/forgotPassword/forgotPasswordSlice'
import staticPageContentReducer from 'src/store/features/staticPageContent/staicPageContentSlice'
import commonReducer from 'src/store/features/common/commonSlice'
import chatReducer from 'src/store/features/chat/chatSlice'

// rtk
import feedApi from 'src/api/feed'
import createFeed from 'src/api/createFeed'
import supportAndFeedback from 'src/api/supportAndFeedback'
import userProfileApi from 'src/api/userProfile'
import chatApi from 'src/api/chat'

const store = configureStore({
  reducer: {
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    staticPageContent: staticPageContentReducer,
    common: commonReducer,
    chat: chatReducer,

    [feedApi.reducerPath]: feedApi.reducer,
    [createFeed.reducerPath]: createFeed.reducer,
    [supportAndFeedback.reducerPath]: supportAndFeedback.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(feedApi.middleware)
      .concat(createFeed.middleware)
      .concat(supportAndFeedback.middleware)
      .concat(userProfileApi.middleware)
      .concat(chatApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
