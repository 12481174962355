import React, { useState } from 'react'
import { LoginSocialGoogle, LoginSocialApple } from 'reactjs-social-login'
import { Button, Box, Avatar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { API_BASE_PATH, routeUrls, userRoles } from 'src/utils/constants'
import { registerAppleUser, registerGoogleUser } from 'src/store/features/auth/authActions'
import { AppDispatch, RootState } from 'src/store'
import { useSelector, useDispatch } from 'react-redux'
import SocialIconGoogle from 'src/assets/icons/SocialIconGoogle.svg'
import SocialIconApple from 'src/assets/icons/socailLoginApple.png'
import { errorToast, successToast } from 'src/utils/toastService'

const googleClientID = '631770886449-mfupvpgaj7fmblkmoiqubrpa91ige5s0.apps.googleusercontent.com'
const appleClientID = 'com.sapienthealth.services'

type SocialLoginProps = {
  userCategory: string | null
}

const SocialLogin = ({ userCategory }: SocialLoginProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error } = useSelector((state: RootState) => state.auth)

  const responseGoogle = (response: any) => {
    if (response?.data?.access_token) {
      dispatch(
        registerGoogleUser({
          accessToken: response?.data?.access_token,
          category: userCategory,
        }),
      ).then((response: any) => {
        if (!response.error && response?.payload?.user) {
          if (
            !response.payload.user.is_signup_completed &&
            response.payload.user.category === userRoles.healthCare
          )
            navigate(routeUrls.completeHealthcareUserRegistration)
        }
        if (response.error) {
          errorToast(response.payload)
          navigate(routeUrls.landingPage)
        }
      })
    }
  }

  const responseApple = (response: any) => {
    console.log(response)
    if (response?.data?.authorization?.id_token) {
      dispatch(
        registerAppleUser({
          idToken: response?.data?.authorization?.id_token,
          category: userCategory,
        }),
      ).then((response: any) => {
        if (!response.error && response?.payload?.user) {
          if (
            !response.payload.user.is_signup_completed &&
            response.payload.user.category === userRoles.healthCare
          )
            navigate(routeUrls.completeHealthcareUserRegistration)
        }
        if (response.error) {
          errorToast(response.payload)
          navigate(routeUrls.landingPage)
        }
      })
    }
  }
  return (
    <>
      <Box>
        <LoginSocialGoogle
          client_id={googleClientID}
          scope='openid profile email'
          discoveryDocs='claims_supported'
          onResolve={responseGoogle}
          onReject={(err: any) => {
            console.log(err)
          }}
        >
          <Button
            type='button'
            variant='contained'
            color='secondary'
            fullWidth
            sx={{
              color: 'background: rgba(10, 20, 24, 1)',
              border: '1px solid rgba(255, 254, 254, 1)',
              background: 'rgba(255, 254, 254, 1)',
              borderRadius: '8px',
              fontWeight: 'bold',
            }}
            startIcon={
              <Avatar
                src={SocialIconGoogle}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            }
          >
            Continue with Google
          </Button>
        </LoginSocialGoogle>
      </Box>
      <Box mt={1}>
        <LoginSocialApple
          client_id={appleClientID}
          redirect_uri={`${API_BASE_PATH}/accounts/apple/login/callback/`}
          scope='name email'
          onResolve={responseApple}
          onReject={(err: any) => {
            console.log(err)
          }}
        >
          <Button
            type='button'
            variant='contained'
            color='secondary'
            fullWidth
            sx={{
              color: 'rgba(255, 254, 254, 1)',
              border: '1px solid rgba(255, 254, 254, 1)',
              background: 'transparent',
              borderRadius: '8px',
              fontWeight: 'bold',
              ':hover': {
                background: 'transparent',
              },
            }}
            startIcon={
              <Avatar
                src={SocialIconApple}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            }
          >
            Continue with Apple
          </Button>
        </LoginSocialApple>
      </Box>
    </>
  )
}

export default SocialLogin
