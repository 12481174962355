import React, { useState, useRef } from 'react'
import InputBase from '@mui/material/InputBase'
import { Button, Typography, FormControl } from '@mui/material'
import { styled } from '@mui/system'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
const IconButton = styled(Button)(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
  color: ${theme.palette.mode === 'dark' ? '#CDD2D7' : '#3E5060'};
  border-radius:10px
  `,
)

const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

interface InputFieldtProps {
  name: string
  type: string
  id?: string
  value?: any
  placeholder?: string
  onChange?: any
  multiline?: boolean
  rows?: number
  required?: boolean
  autoComplete?: string
  margin?: 'dense' | 'normal' | 'none'
  disabled?: boolean
  regex?: RegExp
  inputStyles?: React.CSSProperties
  max?: string
}

const InputField = (props: InputFieldtProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const inputElm = useRef<HTMLInputElement>(null)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowDatePicker = () => {
    inputElm?.current?.showPicker()
  }

  const handleMouseDownDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleChange = (e: any) => {
    if (props.regex) {
      e.target.value = e.target.value.replace(props.regex, '')
    }
    props.onChange(e)
  }

  const { disabled = false } = props
  return (
    <FormControl variant='standard' fullWidth margin={props.margin}>
      <InputBase
        inputRef={inputElm}
        endAdornment={
          props.type === 'password' ? (
            <InputAdornment
              sx={{
                position: 'absolute',
                right: 0,
                background: '#142036',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : props.type === 'date' ? (
            <InputAdornment
              sx={{
                position: 'absolute',
                right: 0,
                background: '#142036',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='open date picker'
                onClick={handleClickShowDatePicker}
                onMouseDown={handleMouseDownDatePicker}
              >
                <KeyboardArrowDownIcon color='success' />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        name={props.name}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={handleChange}
        type={showPassword ? 'text' : props.type}
        multiline={!!props.multiline}
        rows={props.rows || 0}
        required={!!props.required}
        autoComplete={props.autoComplete}
        disabled={disabled}
        slotProps={{
          input: {
            style: {
              WebkitTextFillColor: disabled ? 'gray' : 'initial',
              ...props.inputStyles,
            },
            ...(() => {
              const obj:any = {}
              if (props.type === 'date') {
                obj.max = props.max
              }
              return {...obj}
            })(),
          },
        }}
      />
    </FormControl>
  )
}

export default InputField
