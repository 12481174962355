import React from 'react'
import styles from './index.module.scss'
import { Grid, Divider, Typography, Box } from '@mui/material'
type CustomStepperProps = {
  length: number
  active: number
  title: string
  onStepClick: (step: number) => void
}
const CustomStepper = ({ length, active, title, onStepClick }: CustomStepperProps) => {
  const renderSteps = () => {
    const divs = []
    for (let i = 1; i <= length; i++) {
      divs.push(
        <Grid
          item
          key={i}
          flexGrow={1}
          m={1}
          onClick={() => onStepClick(i)}
          sx={{ cursor: 'pointer' }}
        >
          {i <= active ? (
            <Divider className={styles.activeStep} />
          ) : (
            <Divider className={styles.inactiveStep} />
          )}
        </Grid>,
      )
    }
    return divs
  }

  return (
    <Box>
      <Typography textAlign='left'>{title}</Typography>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        {renderSteps()}
      </Grid>
    </Box>
  )
}

export default CustomStepper
