import React from 'react'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import { routeUrls } from 'src/utils/constants'

const PasswordChangedSuccessfully = () => {
  return (
    <Box>
      <Box className={styles.title} pb={10} >
        <Typography variant='h3'> Reset Successfully.</Typography>
        <Typography variant='subtitle2'>
        Your password has been reset succesfully. <br/> Now you can  <Link to={routeUrls.login}> Login</Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordChangedSuccessfully
