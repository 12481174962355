import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { generateOtpApi, verifyOtpApi, resetPasswordpApi } from './forgotPasswordAction'

type InitialStateType = {
  loading: boolean
  token: string
  email: string
  error: string | null
  success: boolean
}

const initialState: InitialStateType = {
  loading: false,
  token: '', // for storing the JWT
  email: '',
  error: null,
  success: false, // for monitoring the registration process.
}

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setEmailReducer(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setTokenReducer(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setErrorReducer(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateOtpApi.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(generateOtpApi.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.success = true
      })
      .addCase(generateOtpApi.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })


      .addCase(verifyOtpApi.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(verifyOtpApi.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.success = true
      })
      .addCase(verifyOtpApi.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })

      .addCase(resetPasswordpApi.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(resetPasswordpApi.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.success = true
      })
      .addCase(resetPasswordpApi.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

export const { setEmailReducer, setTokenReducer, setErrorReducer } = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer
