/* eslint-disable camelcase */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useListCommentsQuery } from 'src/api/feed';
import { UpdatedFeed } from 'src/Component/Feed';
import DetailedFeed from 'src/Component/Feed/DetailedFeed';
import Header from 'src/Layout/HomeLayout/Header';
import { RootState } from 'src/store';
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice';
import styles from './index.module.scss';

const PostDetail = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { isLoading: isCommentsLoading, data: commentData, refetch } = useListCommentsQuery(Number(params?.postId));

  const handleUpdateFeed = (updatedFeed: UpdatedFeed) => {
   refetch()
  };

  useEffect(() => {
    if (isCommentsLoading) {
      dispatch(startPageLoader());
    } else {
      dispatch(stopPageLoader());
    }
  }, [isCommentsLoading]);

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box>
      <Header>
        <Box width="100%" p={3} pb={0} mb={2}>
          <Box mt={1} display="flex" alignItems="center">
            <IconButton onClick={handleBackClick}>
              <ArrowBackIcon style={{ color: 'white' }} />
            </IconButton>
            <Typography variant="subtitle1" fontSize="24px">
              Feed Details and Comments
            </Typography>
          </Box>
        </Box>
      </Header>

      <Box>
        <Grid container mt={3} justifyContent="center">
          <Box className={styles.wrapper}>
            {commentData?.post_obj && (
              <DetailedFeed
                onUpdateFeed={handleUpdateFeed}
                key={commentData.post_obj.id}
                id={commentData.post_obj.id}
                author={{
                  id: commentData.post_obj.author.id,
                  name: commentData.post_obj.author.name,
                  profilePic: commentData.post_obj.author.profile_picture,
                }}
                isPrivateForAllConnections={commentData.post_obj.is_public_for_all_connections}
                isPrivateForSelectedConnections={commentData.post_obj.is_public_for_added_connections}
                images={commentData.post_obj.images}
                likeCount={commentData.post_obj.like_count}
                createdAt={commentData.post_obj.created_at}
                isReacted={commentData.post_obj.is_reacted}
                isSaved={commentData.post_obj.is_saved}
                title={commentData.post_obj.title}
                description={commentData.post_obj.details}
                tags={commentData.post_obj.tags}
                isPhotoBlur={false}
                specialty={commentData.post_obj.speciality_details}
                focusArea={commentData.post_obj.sub_speciality_details}
                isChatVisible={!!commentData.post_obj.is_connected && commentData.post_obj.author.id !== userInfo?.id}
                isLongDescription={commentData.post_obj.is_long_description}
                sections={commentData.post_obj.sections}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default PostDetail;
