import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BASE_PATH } from 'src/utils/constants'


type generateOtpPayload = {
    email : string
}

export const generateOtpApi = createAsyncThunk(
  'forgotpassword/generateOtp',
  async (payload: generateOtpPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response =  await axios.post(`${API_BASE_PATH}/api/v1/createotp/`, payload , config)
      return response.data
    } catch (error: any) {
      console.log('error', error);
     
      // return custom error message from backend if present
      if (error.response && error.response.data.email?.[0]) {
        return rejectWithValue(error.response.data.email.join(','))
      } else if(error?.response?.data?.message){
        return rejectWithValue(error.response.data.message)
      }else {
        return rejectWithValue(error.message)
      }
    }
  },
)


type verifyOtpPayload = {
    email : string;
    otp: string;
}

export const verifyOtpApi = createAsyncThunk(
    'forgotpassword/verifyOtp',
  async (payload: verifyOtpPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response =  await axios.post(`${API_BASE_PATH}/api/v1/verifyotp/`, payload , config)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

type resetPasswordPayload = {
    email : string;
    password: string;
    token: string;
}

export const resetPasswordpApi = createAsyncThunk(
    'forgotpassword/resetPassword',
  async (payload: resetPasswordPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${payload.token}`
        },
      }
      const response =  await axios.post(`${API_BASE_PATH}/api/v1/resetpassword/`, {
        email: payload.email,
        password: payload.password
      } , config)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)


