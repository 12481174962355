import React from 'react'
import { Box, Typography, Grid, IconButton, CircularProgress } from '@mui/material'
import groupIcon from 'src/assets/icons/group-icon.png'
import { myConnection } from 'src/api/createFeed/types'
import ConnectionRow from './ConnectionRow'
import { useCreateChannelMutation } from 'src/api/chat'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { initiateSingleChat, refetchChannelList } from 'src/store/features/chat/chatSlice'

type OneToOneChatProps = {
  isFetchingConnections: boolean
  connectionsArr: myConnection[]
  onClose: () => void
  onGroupChatClick: () => void
}
const OneToOneChat = ({
  isFetchingConnections,
  connectionsArr,
  onClose,
  onGroupChatClick,
}: OneToOneChatProps) => {
  const [createChannel, { isLoading: isCreateChannelLoading }] = useCreateChannelMutation()
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch<AppDispatch>()
  const handleStartChatWithConnection = (userId: number) => {
    if (userInfo) {
      createChannel([userInfo.id, userId]).then((res: any) => {
        if (res?.data?.data) {
          const fromUser = res.data.data.users.find((user: any) => user.id == userInfo.id) || null
          const toUser = res.data.data.users.find((user: any) => user.id != userInfo.id) || null

          dispatch(
            initiateSingleChat({
              fromUser: {
                id: fromUser.id,
                firstName: fromUser.first_name,
                lastName: fromUser.last_name,
                profilePic: fromUser.profile_picture,
              },
              toUser: {
                id: toUser.id,
                firstName: toUser.first_name,
                lastName: toUser.last_name,
                profilePic: toUser.profile_picture,
              },
              channelId: res.data.data.channel_id,
            }),
          )
          dispatch(refetchChannelList())
          onClose()
        }
      })
    }
  }

  return (
    <Box p={2}>
      <Typography variant='subtitle1' fontWeight={400} fontSize={24}>
        New Chat
      </Typography>

      <Grid
        container
        alignItems='center'
        p={2}
        sx={{ cursor: 'pointer' }}
        onClick={onGroupChatClick}
      >
        <Grid item>
          <IconButton sx={{ padding: 0 }}>
            <img src={groupIcon} />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography variant='subtitle1' fontWeight={600} fontSize={14} pl={2}>
            New Group
          </Typography>
        </Grid>
      </Grid>

      <Typography variant='subtitle1' fontWeight={600} fontSize={16} color='gray'>
        Connections
      </Typography>

      <Box>
        {isFetchingConnections || isCreateChannelLoading ? (
          <Grid container justifyContent='center'>
            <CircularProgress />
          </Grid>
        ) : (
          connectionsArr.map((connection) => (
            <ConnectionRow
              userId={connection.id}
              key={connection.id}
              name={connection.name}
              profilePic={connection.profile_picture}
              onClick={handleStartChatWithConnection}
            />
          ))
        )}
      </Box>
    </Box>
  )
}

export default OneToOneChat
