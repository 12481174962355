/* eslint-disable no-undef */
import React, { useEffect } from 'react'

// eslint-disable-next-line react/prop-types
const ReactVouched = ({ id, config }) => {
  useEffect(() => {
    const vouched = Vouched({ ...config })
    console.log(id)
    vouched.mount(`#${id}`)
  })
  return null
}

export default ReactVouched
