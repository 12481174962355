import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  getPrimarySpecialities,
  loginUser,
  registerHealthUser,
  registerCompleteHealthUser,
  registerNonHealthUser,
  // logoutUser,
  registerGoogleUser,
  registerAppleUser,
} from './authActions'
import { localStorageKeys, userRoles, userRolesTypes } from 'src/utils/constants'

type InitialStateType = {
  isAuthenticated: boolean
  isSignupCompleted: boolean
  role: userRolesTypes | null
  loading: boolean
  token: string | null
  userInfo: {
    id: number
    email: string
    name: string | null | undefined
    is_signup_completed: boolean | null
  } | null
  error: string | null
  success: boolean
  primarySpeciality: {
    loading: boolean
    data: {
      id: number
      name: string
    }[]
    error: string | null
  }
}

const initialState: InitialStateType = {
  loading: false,
  token: localStorage.getItem(localStorageKeys.accessToken) || null, // for storing the JWT
  userInfo: (() => {
    const user = localStorage.getItem(localStorageKeys.user) || null
    if (user) {
      try {
        return JSON.parse(user)
      } catch (error) {
        return null
      }
    }
    return null
  })(), // for user object
  error: null,
  success: false, // for monitoring the registration process.
  isAuthenticated: !!(
    localStorage.getItem(localStorageKeys.accessToken) &&
    localStorage.getItem(localStorageKeys.user)
  ),
  isSignupCompleted: !!(localStorage.getItem(localStorageKeys.isSignupCompleted) === 'true'),
  primarySpeciality: {
    loading: false,
    error: null,
    data: [],
  },
  role: (() => {
    const role = localStorage.getItem(localStorageKeys.role)
    if (role) {
      if (role === userRoles.healthCare) {
        return 'healthcare'
      }
      if (role === userRoles.nonHealthCare) {
        return 'nonhealthcare'
      }
    }
    return null
  })(),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthErrorReducer(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    doLogout(state) {
      localStorage.removeItem(localStorageKeys.accessToken)
      localStorage.removeItem(localStorageKeys.user)
      localStorage.removeItem(localStorageKeys.role)
      localStorage.removeItem(localStorageKeys.isSignupCompleted)
      state.loading = false
      state.success = false
      state.token = null
      state.role = null
      state.userInfo = null
      state.error = null
      state.isAuthenticated = false
      state.isSignupCompleted = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.role = action.payload.user.category
        state.userInfo = action.payload.user
        state.error = null
        state.isAuthenticated = true
        state.isSignupCompleted = !!action.payload.user.is_signup_completed
        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(action.payload.user))
        localStorage.setItem(localStorageKeys.role, action.payload.user.category)
        localStorage.setItem(
          localStorageKeys.isSignupCompleted,
          JSON.stringify(!!action.payload.user.is_signup_completed),
        )
      })
      .addCase(loginUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })

      .addCase(registerGoogleUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })
      .addCase(registerGoogleUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.role = action.payload.user.category
        state.userInfo = action.payload.user
        state.error = null
        state.isAuthenticated = action.payload.user.is_signup_completed
        state.isSignupCompleted = action.payload.user.is_signup_completed

        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(action.payload.user))
        localStorage.setItem(localStorageKeys.role, action.payload.user.category)
        localStorage.setItem(
          localStorageKeys.isSignupCompleted,
          JSON.stringify(action.payload.user.is_signup_completed),
        )
      })
      .addCase(registerGoogleUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })



      .addCase(registerAppleUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })
      .addCase(registerAppleUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.role = action.payload.user.category
        state.userInfo = action.payload.user
        state.error = null
        state.isAuthenticated = action.payload.user.is_signup_completed
        state.isSignupCompleted = action.payload.user.is_signup_completed

        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(action.payload.user))
        localStorage.setItem(localStorageKeys.role, action.payload.user.category)
        localStorage.setItem(
          localStorageKeys.isSignupCompleted,
          JSON.stringify(action.payload.user.is_signup_completed),
        )
      })
      .addCase(registerAppleUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })



      .addCase(registerNonHealthUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })
      .addCase(registerNonHealthUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.userInfo = action.payload.user
        state.role = action.payload.user.category
        state.error = null
        state.isAuthenticated = true
        state.isSignupCompleted = !!action.payload.user.is_signup_completed

        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(action.payload.user))
        localStorage.setItem(localStorageKeys.role, action.payload.user.category)
        localStorage.setItem(
          localStorageKeys.isSignupCompleted,
          JSON.stringify(!!action.payload.user.is_signup_completed),
        )
      })
      .addCase(registerNonHealthUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })

      .addCase(getPrimarySpecialities.pending, (state) => {
        state.primarySpeciality.loading = true
        state.primarySpeciality.error = null
        state.primarySpeciality.data = []
      })
      .addCase(getPrimarySpecialities.fulfilled, (state, action) => {
        state.primarySpeciality.loading = false
        state.primarySpeciality.error = null
        state.primarySpeciality.data = action.payload
      })
      .addCase(getPrimarySpecialities.rejected, (state, action: any) => {
        state.primarySpeciality.loading = false
        state.primarySpeciality.error = action.payload
        state.primarySpeciality.data = []
      })

      .addCase(registerHealthUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })
      .addCase(registerHealthUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.userInfo = action.payload.user
        state.role = action.payload.user.category
        state.error = null
        state.isAuthenticated = true
        state.isSignupCompleted = true

        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(action.payload.user))
        localStorage.setItem(localStorageKeys.role, action.payload.user.category)
        localStorage.setItem(
          localStorageKeys.isSignupCompleted,
          JSON.stringify(action.payload.user.is_signup_completed),
        )
      })
      .addCase(registerHealthUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false

        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })

      .addCase(registerCompleteHealthUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        // localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })
      .addCase(registerCompleteHealthUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.token = localStorage.getItem(localStorageKeys.accessToken)
        state.userInfo = action.payload.user
        state.role = action.payload.user.category
        state.error = null
        state.isAuthenticated = true
        state.isSignupCompleted = action.payload.user.is_signup_completed || false

        // localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(action.payload.user))
        localStorage.setItem(localStorageKeys.role, action.payload.user.category)
        localStorage.setItem(
          localStorageKeys.isSignupCompleted,
          JSON.stringify(action.payload.user.is_signup_completed),
        )
      })
      .addCase(registerCompleteHealthUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        state.isSignupCompleted = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.isSignupCompleted)
      })

      // .addCase(logoutUser.pending, (state) => {
      //   state.loading = true
      // })
      // .addCase(logoutUser.fulfilled, (state, action) => {
      //   localStorage.removeItem(localStorageKeys.accessToken)
      //   localStorage.removeItem(localStorageKeys.user)
      //   localStorage.removeItem(localStorageKeys.role)
      //   localStorage.removeItem(localStorageKeys.isSignupCompleted)
      //   state.loading = false
      //   state.success = false
      //   state.token = null
      //   state.role = null
      //   state.userInfo = null
      //   state.error = null
      //   state.isAuthenticated = false
      //   state.isSignupCompleted = false
      // })
      // .addCase(logoutUser.rejected, (state, action: any) => {
      //   state.loading = false
      // })
  },
})
export const { setAuthErrorReducer, doLogout } = authSlice.actions

export default authSlice.reducer
