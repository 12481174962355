import React, { useEffect, useState } from 'react'
import Header from 'src/Layout/HomeLayout/Header'
import { Box, Grid, Typography, Tabs, Tab, Divider } from '@mui/material'
import SearchBar from 'src/Component/SearchBar'
import PrivateFeedList from './PrivateFeedList'
import PublicFeedList from './PublicFeedList'

import styles from './index.module.scss'

const MyPosts = () => {

  const [activeTab, setActiveTab] = useState<0 | 1>(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab !== null ? parseInt(savedTab, 10) as 0 | 1 : 0;
  });

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab.toString());
  }, [activeTab]);
  const [searchValue, setSearchValue] = useState<string>('')

  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    setActiveTab(newValue)
  }

  const tabs = ['Public', 'Private']

  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Grid container justifyContent='center'>
            <Box className={styles.wrapper}>
              <SearchBar onChangeWithDebounce={setSearchValue} />
            </Box>
          </Grid>
        </Box>

        <Box width='100%' pr={3} pl={3}>
          <Box mt={1}>
            <Typography variant='subtitle1' fontSize='24px'>
              My Posts
            </Typography>
          </Box>

          <Grid container justifyContent='center'>
            <Box className={styles.wrapper}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
                variant='fullWidth'
                textColor='primary'
                indicatorColor='secondary'
              >
                {tabs.map((tab, i) => {
                  return (
                    <Tab
                      key={tab}
                      label={tab}
                      sx={{ color: activeTab === i ? 'white' : '#909090' }}
                    />
                  )
                })}
              </Tabs>
              <Divider />
            </Box>
          </Grid>
        </Box>
      </Header>
      <Box>
        <Grid container mt={12} justifyContent='center'>
          <Box className={styles.wrapper}>
            {activeTab === 0 ? (
              <PublicFeedList searchValue={searchValue} />
            ) : (
              <PrivateFeedList searchValue={searchValue} />
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default MyPosts
