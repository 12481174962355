import React from 'react'
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Badge,
  IconButton,
  Chip,
  Button,
  CircularProgress,
} from '@mui/material'
import styles from './index.module.scss'
import editIcon from 'src/assets/icons/edit-profile.png'
import pencilIcon from 'src/assets/icons/pencil.png'
import { useSendConnectionRequestMutation } from 'src/api/userProfile'
import { useNavigate } from 'react-router-dom'
import { routeUrls, userRoles } from 'src/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { useCreateChannelMutation } from 'src/api/chat'
import { initiateSingleChat } from 'src/store/features/chat/chatSlice'

type BasicInfoProps = {
  userId: number
  profilePic: string | null
  title: string | null
  firstName: string
  lastName: string
  email: string
  phone: string
  hideEdit?: boolean
  isPublicProfile: boolean
  isOwnProfile: boolean
  isConnected: boolean
  isConnectionPending?: boolean
  refetchData?: () => void
}
const BasicInfo = ({
  userId,
  profilePic,
  title = '',
  firstName = '',
  lastName = '',
  email,
  phone,
  hideEdit = false,
  isPublicProfile,
  isOwnProfile,
  isConnected,
  isConnectionPending = false,
  refetchData,
}: BasicInfoProps) => {
  const { role, userInfo } = useSelector((state: RootState) => state.auth)
  const name = `${title || ''} ${firstName} ${lastName}`
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const [sendConnectionRequest, { isLoading }] = useSendConnectionRequestMutation()
  const [createChannel, { isLoading: isCreateChannelLoading }] = useCreateChannelMutation()

  const handleSendConnectionRequest = () => {
    sendConnectionRequest(userId)
      .unwrap()
      .then((res) => {
        if (typeof refetchData === 'function') {
          refetchData()
        }
      })
  }

  const navigateToEditProfile = () => {
    if (role === userRoles.healthCare) {
      navigate(routeUrls.editMyyProfile)
    }
    if (role === userRoles.nonHealthCare) {
      navigate(routeUrls.nonHealthCareUser.editMyProfile)
    }
  }

  const handleStartChat = () => {
    if (userInfo?.id && userId) {
      createChannel([userInfo.id, userId]).then((res: any) => {
        if (res?.data?.data) {
          const fromUser = res.data.data.users.find((user: any) => user.id == userInfo.id) || null
          const toUser = res.data.data.users.find((user: any) => user.id != userInfo.id) || null

        
          navigate(routeUrls.chat)
          setTimeout(() => {
            dispatch(
              initiateSingleChat({
                fromUser: {
                  id: fromUser.id,
                  firstName: fromUser.first_name,
                  lastName: fromUser.last_name,
                  profilePic: fromUser.profile_picture,
                },
                toUser: {
                  id: toUser.id,
                  firstName: toUser.first_name,
                  lastName: toUser.last_name,
                  profilePic: toUser.profile_picture,
                },
                channelId: res.data.data.channel_id,
              }),
            )
          }, 1000);
        }
      })
    }
  }
  return (
    <Box className={styles.topSegmantWrapper}>
      <Grid container alignItems='center'>
        <Grid item>
          <Badge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Avatar
              src={profilePic || 'broken-image.png'}
              variant='circular'
              sx={{
                width: 144,
                height: 144,
                border: '5px solid rgba(52, 214, 149, 1)',
              }}
            />
          </Badge>
        </Grid>
        <Grid item pl={4} className={styles.userBasicDetails} >
          <Typography variant='h4'>{name}</Typography>

          {isPublicProfile && (
            <>
              <Typography variant='subtitle2'> {email} </Typography>
              <Typography variant='subtitle2'> {phone} </Typography>
            </>
          )}

          {!isPublicProfile && (isOwnProfile || isConnected) && role === userRoles.healthCare && (
            <>
              <Typography variant='subtitle2' pt={1}>
                {' '}
                <Chip label={email || 'NA'} />{' '}
              </Typography>
              <Typography variant='subtitle2' pt={1}>
                {' '}
                <Chip label={phone || 'NA'} />{' '}
              </Typography>
            </>
          )}

          {!isOwnProfile &&
            !isConnected &&
            !isConnectionPending &&
            role === userRoles.healthCare && (
              <Button
                sx={{ marginTop: '15px', fontWeight: 'bold' }}
                variant='contained'
                fullWidth
                onClick={handleSendConnectionRequest}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={25} /> : 'Connect'}
              </Button>
            )}

          {!isOwnProfile &&
            !isConnected &&
            isConnectionPending &&
            role === userRoles.healthCare && (
              <Button sx={{ marginTop: '15px', fontWeight: 'bold' }} variant='contained' fullWidth>
                Pending
              </Button>
            )}

          {!isOwnProfile && isConnected && role === userRoles.healthCare && (
            <Button
              color='success'
              sx={{ marginTop: '15px' }}
              variant='contained'
              fullWidth
              onClick={handleStartChat}
              disabled={isCreateChannelLoading}
              className={isCreateChannelLoading ? 'buttonDisabled' : ''}
            >
              {isCreateChannelLoading ? <CircularProgress size={25} /> : 'Chat'}
            </Button>
          )}
        </Grid>
      </Grid>
      {!hideEdit && (
        <Box className={styles.editProfileButtonWrapper}>
          <IconButton
            onClick={navigateToEditProfile}
            sx={{
              padding: '5.5px',
            }}
          >
            <img src={pencilIcon} height={'21px'} width={'21px'} />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default BasicInfo
