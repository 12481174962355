import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Box } from '@mui/material'
import Modal from '@mui/material/Modal'
import AddIcon from 'src/assets/icons/addFileIcon.png'
import ReactVouched from './ReactVouched'
import { VOUCHED_PUBLIC_KEY } from '../../utils/constants'

const modalStyle = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  width: '100%',
  height: '100%',
}

const StyledVouchedVerification = styled('label')({
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  background: '#142036',
  borderRadius: '4px',
})

interface VouchedVefiricationProps {
  onSuccess: (job: any) => void
  onFailure: (errorMsg: string) => void
  title: string
  discription: string
  name: string
  validationId: string
}

const VouchedVerification: React.FC<VouchedVefiricationProps> = ({
  onSuccess,
  onFailure,
  title,
  discription,
  name,
  validationId,
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <StyledVouchedVerification>
      <Box p={2}>
        <Typography textAlign='left' variant='h5' mb={1}>
          {title}
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            border: '1px dashed #8D9199',
            borderRadius: '4px',
          }}
          p={3}
        >
          <Typography
            mb={2}
            pt={1}
            variant='subtitle2'
            component='p'
            sx={{ color: 'rgba(235, 235, 245, 0.6)' }}
          >
            {discription}
          </Typography>
          <img src={AddIcon} height={30} width={30} alt='add' onClick={() => handleOpen()} />
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <ReactVouched
            id='vouched-element'
            config={{
              verification: {
                // verify the user's information
                //    firstName: 'Arjun',
                //    lastName: 'Singha',
              },

              appId: VOUCHED_PUBLIC_KEY,

              // mobile handoff
              crossDevice: false,
              crossDeviceQRCode: false,
              crossDeviceSMS: false,
              onInit: (init: any) => {
                // console.log('initialization token: ', init?.token)
                // console.log('initialization job: ', init?.job)
              },

              onSubmit: (submit: any) => {
                // callback executed after every time Job was posted
              },

              // called when the verification is completed.
              onDone: (job: { token: any; errors: any }) => {
                // token used to query jobs

                if (job?.errors.length > 0) {
                  onFailure('Validation Failed')
                } else {
                  onSuccess(job)
                }
                setTimeout(() => {
                  handleClose()
                }, 500)
              },
              onSurveyDone: (job: any) => {
                // callback when survey is submitted
              },

              onCamera: (camera: any) => {
                // callback executed after attempt to find camera device
              },

              onCameraEvent: (cameraEvent: any) => {
                // callback executed after DOM changes to Camera
              },
              // theme
              theme: {
                name: 'avant',
              },
            }}
          />
          <div id='vouched-element'></div>
        </Box>
      </Modal>
    </StyledVouchedVerification>
  )
}

export default VouchedVerification
