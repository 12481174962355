/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  API_BASE_PATH,
  VISION_API_ENDPOINT,
  localStorageKeys,
  userRoles,
} from 'src/utils/constants'

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password }: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/login/`,
        { username: email, password },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

type registerNonHealthUserPayload = {
  first_name: string
  last_name: string
  email: string
  password: string
  phone?: string
  dob?: string
}
export const registerNonHealthUser = createAsyncThunk(
  'auth/register/non-health',
  async (payload: registerNonHealthUserPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/customsignup/`,
        {
          ...payload,
          category: userRoles.nonHealthCare,
        },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const getPrimarySpecialities = createAsyncThunk(
  'auth/get-primary-specialites',
  async (payload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.get(`${API_BASE_PATH}/api/v1/speciality/`, config)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// type registerHealthUserPayload = {
//   first_name: string
//   last_name: string
//   email: string
//   password: string
//   phone: string
//   primary_speciality: number
//   gov_id_number:string;
//   gov_id_proof_front: File
//   gov_id_proof_back: File;
//   medical_licence_number: string
//   medical_licence_proof: File
//   npi_number: string
//   selfie: File
// }

export const registerHealthUser = createAsyncThunk(
  'auth/register/health',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const response = await axios.post(`${API_BASE_PATH}/api/v1/customsignup/`, payload, config)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const registerUser = createAsyncThunk(
  'register',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const response = await axios.post(`${API_BASE_PATH}/api/v1/signuprequest/`, payload, config)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const registerGoogleUser = createAsyncThunk(
  'auth/google-login',
  async ({ accessToken, category }: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/modules/social-auth/google/login/`,
        // eslint-disable-next-line camelcase
        { access_token: accessToken, category },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const registerAppleUser = createAsyncThunk(
  'auth/apple-login',
  async ({ idToken, category }: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/modules/social-auth/apple/login/`,
        // eslint-disable-next-line camelcase
        { id_token: idToken, access_token: idToken, category },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const registerCompleteHealthUser = createAsyncThunk(
  'auth/register/complete-health',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Token ' + localStorage.getItem(localStorageKeys.accessToken),
        },
      }
      const response = await axios.post(`${API_BASE_PATH}/api/v1/complete-signup/`, payload, config)
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// export const logoutUser = createAsyncThunk('auth/logout', async (payload, { rejectWithValue }) => {
//   try {
//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Token ' + localStorage.getItem(localStorageKeys.accessToken),
//       },
//     }
//     const response = await axios.post(`${API_BASE_PATH}/rest-auth/logout/`, {}, config)
//     return response.data
//   } catch (error: any) {
//     // return custom error message from backend if present
//     if (error.response && error.response.data.message) {
//       return rejectWithValue(error.response.data.message)
//     } else {
//       return rejectWithValue(error.message)
//     }
//   }
// })

export const verifyMedicalLicenseApi = (base64: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(VISION_API_ENDPOINT, {
        requests: [
          {
            image: {
              content: base64,
            },

            features: [
              {
                type: 'DOCUMENT_TEXT_DETECTION',
                maxResults: 1,
              },
            ],
          },
        ],
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
