import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Box, Typography, Button, CircularProgress, Divider } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import ImageCarousel from 'src/Component/ImageCarousel'
import AppIcon from 'src/assets/images/AppIcon.png'
import InputField from 'src/Component/InputField'

import styles from './index.module.scss'
import { routeUrls } from 'src/utils/constants'

import { loginUser } from 'src/store/features/auth/authActions'
import { AppDispatch, RootState } from 'src/store'
import { setAuthErrorReducer } from 'src/store/features/auth/authSlice'
import SocialLogin from 'src/Component/SocialLogin'

const Login = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error } = useSelector((state: RootState) => state.auth)
  const [form, setForm] = useState({
    userEmail: '',
    userPassword: '',
  })

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleLogin = (e: React.SyntheticEvent) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email: form.userEmail,
        password: form.userPassword,
      }),
    )
  }

  useEffect(() => {
    dispatch(setAuthErrorReducer(''))
  }, [])
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' height='100vh'>
      <Grid item xs={12} sm={12} md={6}>
        <ImageCarousel />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <img src={AppIcon} alt='Icon' height={'150px'} width='169.85px' />
          <Box className={styles.formWrapper}>
            <form onSubmit={handleLogin}>
              <Box p={10}>
                <Box>
                  {' '}
                  <InputField
                    name='userEmail'
                    id='userEmail'
                    autoComplete='new-password'
                    type='text'
                    placeholder='Email'
                    value={form.userEmail}
                    onChange={onInputChange}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <InputField
                    name='userPassword'
                    id='userPassword'
                    type='password'
                    autoComplete='new-password'
                    placeholder='Password'
                    value={form.userPassword}
                    onChange={onInputChange}
                    required
                  />
                  <Typography variant='subtitle2' color='red' textAlign='center'>
                    {' '}
                    {error}{' '}
                  </Typography>
                </Box>

                <Box mt={1}>
                  <Typography variant='subtitle2'>
                    <Link to={routeUrls.forgotPassword}>Forgot Password?</Link>
                  </Typography>
                </Box>

                <Box mt={5}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='success'
                    fullWidth
                    disabled={loading}
                    className={loading ? 'buttonDisabled' : ''}
                  >
                    {loading ? <CircularProgress size={25} /> : 'Login'}
                  </Button>
                </Box>
                <Box pt={1} pb={1} pl={10} pr={10}>
                  <Divider>or</Divider>
                </Box>

                <Box p={1}>
                  <SocialLogin userCategory={null} />
                </Box>
                {/* 
                <Box mt={1} textAlign='center'>
                  <Typography variant='subtitle2' color='success'>
                    Don&apos;t have an account?<Link to={routeUrls.landingPage}>Sign Up</Link>
                  </Typography>
                </Box> */}
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Login
