import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
    },
    secondary: {
      main: '#F4F4F4',
      light: '#F4F4F4',
      dark: '#F4F4F4',
    },
    success: {
      main: '#34D695',
      light: '#34D695',
      dark: '#34D695',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Publico Text Web',
      color: 'white',
      fontSize: '2rem',
    },
    h2: {
      fontFamily: 'Publico Text Web',
      color: 'white',
      fontSize: '1.75rem',
    },
    h3: {
      fontFamily: 'Publico Text Web',
      color: 'white',
      fontSize: '1.50rem',
    },
    h4: {
      fontFamily: 'Publico Text Web',
      color: 'white',
      fontSize: '1.25rem',
    },
    h5: {
      fontFamily: 'Publico Text Web',
      color: 'white',
      fontSize: '1rem',
    },
    h6: {
      fontFamily: 'Publico Text Web',
      color: 'white',
      fontSize: '0.75rem',
    },
    subtitle1: {
      fontFamily: 'Nunito',
      color: '#FFFEFE',
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    subtitle2: {
      fontFamily: 'Nunito',
      color: '#FFFEFE',
      fontWeight: 200,
      fontSize: '0.85rem',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white',
          borderRadius: 8,
          backgroundColor: '#142036',
          border: '1px solid #8D9199',
          fontSize: 16,
          width: '100%',
          padding: '10px 12px',
          fontFamily: 'Nunito',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Nunito',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#8D9199',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #919191',
        },
        withChildren: {
          borderTop: 'none',
          ':before': {
            borderTop: '1px solid #919191',
          },
          ':after': {
            borderTop: '1px solid #919191',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: 'white',
          borderRadius: 8,
          backgroundColor: '#142036',
          border: '1px solid #8D9199',
          fontSize: 16,
          width: '100%',
          padding: '10px 12px',
          fontFamily: 'Nunito',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
        },
        multiple: {
          color: 'white',
          borderRadius: 8,
          backgroundColor: '#142036',
          border: '1px solid #8D9199',
          fontSize: 16,
          width: '100%',
          padding: '10px 12px',
          fontFamily: 'Nunito',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#909090',
          fontSize: '0.9rem',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#34D695',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Nunito',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#34D695',
        },
        colorSecondary: {
          backgroundColor: '#909090',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          background: '#142036',
          color: 'white',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: '#0A1519',
          border: '1px solid grey',
          borderRadius: '8px, 0px, 8px, 8px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#0A1519',
          borderRadius: '8px, 0px, 8px, 8px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#8D9199',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'white',
          background: 'rgba(144, 144, 144, 0.5)',
          borderRadius: '4px',
          fontWeight: 400,
          fontSize: '12px',
        },
        colorPrimary: {
          background: '#123C32',
          color: '#34D695',
        },
      },
    },
  },
})

export default theme
