import React from 'react'
import Carousel from 'react-material-ui-carousel'

import { Typography, Box } from '@mui/material'
import login1 from 'src/assets/images/login1.png'
import login2 from 'src/assets/images/login2.png'
import login3 from 'src/assets/images/login3.png'
import styles from './index.module.scss'
const ImageCarousel = () => {
  return (
    <Carousel
    
      className={styles.carousel}
      navButtonsAlwaysInvisible
      cycleNavigation={true}
      autoPlay={true}
      swipe={false}
      indicators={true}
      animation='fade'
      fullHeightHover={false}
      changeOnFirstRender
      index={1}
    >
           <Box>
        <img src={login3} alt='Let’s Get Started' />
        <Box>
          <Typography variant='h3'>Crowdsource</Typography>
          <Typography variant='subtitle2' >
            Exchange knowledge with peers to explore novel diagnostic and treatment strategies.
          </Typography>
        </Box>
      </Box>

      <Box>
        <img src={login1} alt='Create' />
        <Box>
          <Typography variant='h3'>Create</Typography>
          <Typography variant='subtitle2' >
          Create HIPAA compliant posts that can be viewed by a verified group of Healthcare professionals.
      
          </Typography>
        </Box>
      </Box>

      <Box>
        <img src={login2} alt='Collaborate' />
        <Box>
          <Typography variant='h3'>Collaborate</Typography>
          <Typography variant='subtitle2' >
          A social platform to facilitate case discussion among healthcare professionals.
          </Typography>
        </Box>
      </Box>

   
    </Carousel>
  )
}

export default ImageCarousel
