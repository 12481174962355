import React, { useState } from 'react'
import { Box, Button, CircularProgress, Grid, Popover, Typography } from '@mui/material'
import dangerIcon from 'src/assets/icons/danger-icon.png'
import CustomizedDialogBox from 'src/Component/CustomizedDialogBox'
import DeleteIcon from '@mui/icons-material/Delete'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputField from 'src/Component/InputField'
import { errorToast, successToast } from 'src/utils/toastService'
import { useReportFeedMutation } from 'src/api/supportAndFeedback'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { useDeleteMyFeedMutation } from 'src/api/createFeed'
import { useLazyGetPrivateFeedQuery, useLazyGetPublicFeedQuery } from 'src/api/feed'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'

type ReportFeedModalProps = {
  isPopoverOpen: boolean
  anchorEl: HTMLButtonElement | null
  onPopoverClose: () => void
  reportFeedId: number
  authorId: number
  refetchData: () => void
}

const reportReasons = [
  'Spam',
  'Hatred and bullying',
  'Self-harm',
  'Violent, gory and harmful content',
  'Illegal activities (e.g. drug uses)',
  'Deceptive content',
  'Copyright and trademark infringement',
]
const ReportFeedModal = ({
  isPopoverOpen,
  anchorEl,
  onPopoverClose,
  reportFeedId,
  authorId,
  refetchData,
}: ReportFeedModalProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [reportReason, setReportReason] = useState<string>('')
  const [otherReason, setOtherReason] = useState<string>('')
  const [reportFeed, { isLoading }] = useReportFeedMutation()
  const navigate = useNavigate()

  // const [fetchAllFeed, {refetchPublicFeed}] = useLazyGetPublicFeedQuery()
  // const [fetchFeeds, refetchPrivateFeed] = useLazyGetPrivateFeedQuery()

  const [deleteFeed, { isLoading: loading }] = useDeleteMyFeedMutation()

  const { role, userInfo } = useSelector(
    (state: RootState) => state.auth as { role: string; userInfo: any },
  )
  console.log('reportFeedId UserId', userInfo?.id)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  console.log('reportFeedId', reportFeedId)
  const handleOpenDeleteConfirmation = () => {
    setOpenDelete(true)
    onPopoverClose()
  }

  const onDeleteConfirm = () => {
    deleteFeed(reportFeedId).then(() => {
      refetchData()
      // refetchPublicFeed
      // refetchPrivateFeed()
      setOpenDelete(false)
    })
  }

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportReason((event.target as HTMLInputElement).value)
    setOtherReason('')
  }

  const handleModaOpen = () => {
    setOpen(true)
    onPopoverClose()
  }

  const goToEditFeed = () => {
    navigate(routeUrls.editFeed.replace(':feedId', reportFeedId.toString()))
  }

  const onOtherReasonInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(e.target.value)
    setReportReason('')
  }
  const handleSubmit = () => {
    if (!reportReason && !otherReason) {
      errorToast('Please mention the reporting reason.')
      return
    }
    reportFeed({
      feedId: reportFeedId,
      reason: reportReason || otherReason,
    })
      .unwrap()
      .then((res) => {
        successToast('Feed reported successfully.')
        setOpen(false)
      })
      .catch((err: string) => {
        errorToast(err)
      })
  }

  React.useEffect(() => {
    setOtherReason('')
    setReportReason('')
  }, [open])
  return (
    <Box>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {userInfo?.id != authorId && (
          <Typography
            alignItems="center"
            sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
            color="red"
            onClick={handleModaOpen}
          >
            {' '}
            <img src={dangerIcon} height={25} alt="Flag post" style={{ paddingRight: '10px' }} /> Flag Post{' '}
          </Typography>
        )}

        {userInfo?.id == authorId && (
          <Typography
            alignItems="center"
            sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
            color="white"
            onClick={goToEditFeed}
          >
            <EditIcon /> &nbsp; Edit Post
          </Typography>
        )}

        {userInfo?.id == authorId && (
          <Typography
            alignItems="center"
            sx={{ p: 2, display: 'flex', cursor: 'pointer' }}
            color="red"
            onClick={handleOpenDeleteConfirmation}
          >
            <DeleteIcon /> &nbsp; Delete Feed
          </Typography>
        )}
      </Popover>

      <CustomizedDialogBox open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box>
          <Typography variant="h3" textAlign="center" mb={5}>
            {' '}
            Are you sure to delete this feed?
          </Typography>
          <Grid container>
            <Grid xs={6} p={2}>
              <Button
                type="button"
                onClick={onDeleteConfirm}
                variant="contained"
                color="success"
                fullWidth
                disabled={loading}
                className={loading ? 'buttonDisabled' : ''}
              >
                {loading ? <CircularProgress size={25} /> : 'Confirm'}
              </Button>
            </Grid>
            <Grid xs={6} p={2}>
              <Button type="button" onClick={() => setOpenDelete(false)} variant="contained" color="error" fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomizedDialogBox>

      <CustomizedDialogBox
        title="Why are you reporting this?"
        open={open}
        onClose={() => setOpen(false)}
        loading={isLoading}
        onSubmit={handleSubmit}
      >
        <Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="flag-feed-radio-buttons-group-label"
              value={reportReason}
              onChange={handleReasonChange}
              name="radio-buttons-group"
            >
              {reportReasons.map((reason, index) => {
                return (
                  <FormControlLabel
                    sx={{ paddingTop: '10px' }}
                    key={index}
                    value={reason}
                    control={<Radio />}
                    label={<Typography variant="subtitle2">{reason}</Typography>}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <Box pt={3}>
            <InputField
              name="description"
              id="description"
              type="text"
              placeholder="Other"
              value={otherReason}
              onChange={onOtherReasonInput}
              required
              multiline
              rows={4}
            />
          </Box>
        </Box>
      </CustomizedDialogBox>
    </Box>
  );
}

export default ReportFeedModal
