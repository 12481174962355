import React from 'react'
import { Box, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type FeedMenuProps = {
  onMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
}
const FeedMenu = ({ onMenuOpen }: FeedMenuProps) => {
  return (
    <Box>
      <IconButton onClick={onMenuOpen}>
        <MoreVertIcon color='primary' />
      </IconButton>
    </Box>
  )
}

export default FeedMenu
