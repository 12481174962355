import { createBrowserRouter, RouteObject } from 'react-router-dom'

import LoginLayout from 'src/Layout/LoginLayout'
import Login from 'src/Page/Login'
import { routeUrls } from '../constants'
import ForgotPassword from 'src/Page/ForgotPassword'
import HealthCareUserLayout from 'src/Layout/HomeLayout/HealthCareUserLayout'
import NonHealthCareUserLayout from 'src/Layout/HomeLayout/NonHealthCareUserLayout'

import Home from 'src/Page/Home'
import LandingPage from 'src/Page/LandingPage'
import NonHealthcareUserRegistration from 'src/Page/Register/NonHealthcareUserRegistration'
import TermAndCondition from 'src/Page/Register/TermAndCondition'
import PrivacyPolicy from 'src/Page/Register/PrivacyPolicy'
import HealthcareUserRegistration from 'src/Page/Register/HealthcareUserRegistration'
import CompleteHealthcareUserRegistration from 'src/Page/Register/CompleteHealthCareUserRegistration'
import MyPosts from 'src/Page/MyPosts'
import CreateFeed from 'src/Page/CreateFeed'
import SupportAndFeedback from 'src/Page/SupportAndFeedback'
import MyProfile from 'src/Page/MyProfile'
import MySavedFeeds from 'src/Page/MySavedFeeds'
import ViewProfile from 'src/Page/ViewProfile'
import EditProfile from 'src/Page/MyProfile/EditProfile'
import Settings from 'src/Page/Settings'

// Non HealtCare user
import NonHealthCareUserHome from 'src/Page/NonHealthCareUser/Home'
import NonHealthCareUserMyProfile from 'src/Page/NonHealthCareUser/MyProfile'
import NonHealthCareUserEditProfile from 'src/Page/NonHealthCareUser/MyProfile/EditProfile'
import ChatFeature from 'src/Page/ChatFeature'
import HealthcareUserEndorse from 'src/Page/Register/HealthcareUserEndorse'
import PostDetail from 'src/Page/PostDetail'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LoginLayout />,
    children: [
      {
        index: true,
        // element: <LandingPage />,
        element: <Login />,
      },
      {
        path: routeUrls.login,
        element: <Login />,
      },
      {
        path: routeUrls.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: routeUrls.nonHealthcareUserRegistration,
        element: <NonHealthcareUserRegistration />,
      },
      {
        path: routeUrls.completeHealthcareUserRegistration,
        element: <CompleteHealthcareUserRegistration />,
      },
      {
        path: routeUrls.healthcareUserRegistration,
        element: <HealthcareUserRegistration />,
      },
      {
        path: routeUrls.healthcareUserRegistrationEndorse,
        element: <HealthcareUserEndorse />,
      },
      {
        path: routeUrls.termAndCondition,
        element: <TermAndCondition />,
      },
      {
        path: routeUrls.privacyPolicy,
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: routeUrls.home,
    element: <HealthCareUserLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: routeUrls.postDetail,
        element: <PostDetail />,
      },
      {
        path: routeUrls.myPosts,
        element: <MyPosts />,
      },

      {
        path: routeUrls.mySavedFeeds,
        element: <MySavedFeeds />,
      },
      {
        path: routeUrls.createFeed,
        element: <CreateFeed />,
      },
      {
        path: routeUrls.editFeed,
        element: <CreateFeed />,
      },
      {
        path: routeUrls.supportAndFeedback,
        element: <SupportAndFeedback />,
      },
      {
        path: routeUrls.myProfile,
        element: <MyProfile />,
      },
      {
        path: routeUrls.editMyyProfile,
        element: <EditProfile />,
      },
      {
        path: routeUrls.viewProfile,
        element: <ViewProfile />,
      },
      {
        path: routeUrls.settings,
        element: <Settings />,
      },
      {
        path: routeUrls.chat,
        element: <ChatFeature />,
      },
    ],
  },

  {
    path: routeUrls.nonHealthCareUser.home,
    element: <NonHealthCareUserLayout />,
    children: [
      {
        index: true,
        element: <NonHealthCareUserHome />,
      },
      {
        path: routeUrls.nonHealthCareUser.postDetail,
        element: <PostDetail />,
      },
      {
        path: routeUrls.nonHealthCareUser.supportAndFeedback,
        element: <SupportAndFeedback />,
      },
      {
        path: routeUrls.nonHealthCareUser.myProfile,
        element: <NonHealthCareUserMyProfile />,
      },
      {
        path: routeUrls.nonHealthCareUser.editMyProfile,
        element: <NonHealthCareUserEditProfile />,
      },
      {
        path: routeUrls.nonHealthCareUser.viewOthersProfile,
        element: <ViewProfile />,
      },
      {
        path: routeUrls.nonHealthCareUser.settings,
        element: <Settings />,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default router
