import React, { useState } from 'react'
import {
  Box,
  Grid,

  Typography,
  CircularProgress,
  Button,
} from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import ConnectionRow from '../NewChatPopUp/OneToOneChat/ConnectionRow'
import { useAddUserToGroupMutation } from 'src/api/chat'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { setToUsersArr } from 'src/store/features/chat/chatSlice'
import { useGetMyConnectionsQuery } from 'src/api/createFeed'
import { successToast } from 'src/utils/toastService'
type AddConnectionToGroupProps = {
  onClose: () => void
}
const AddConnectionToGroup = ({ onClose }: AddConnectionToGroupProps) => {
  const { data = [], isLoading: isFetchingConnections } = useGetMyConnectionsQuery()
  const {  toUsersArr, fromUser, channelId } = useSelector(
    (state: RootState) => state.chat,
  )
  const [selectedConnections, setSelectedConnections] = useState<Map<number, number>>(new Map())
  const dispatch = useDispatch<AppDispatch>()

  const [addUserToGroup] = useAddUserToGroupMutation()

  const connectionsArr = data.filter((connection) => {
    return !toUsersArr.find((user) => user.id === connection.id)
  })

  const handleCheckboxClick = (userId: number) => {
    const _selectedConnections = new Map(selectedConnections)
    if (_selectedConnections.has(userId)) {
      _selectedConnections.delete(userId)
    } else {
      _selectedConnections.set(userId, 1)
    }

    setSelectedConnections(_selectedConnections)
  }

  const handleSubmit = () => {
    const newGroupUsers = Array.from(selectedConnections.keys())
    dispatch(startPageLoader())
    addUserToGroup({
      userIds: newGroupUsers,
      channelId,
    })
      .unwrap()
      .then((res: any) => {
        console.log(res)
        if (res.data.users && res.data.admins && fromUser) {
          dispatch(
            setToUsersArr(
              res.data.users.filter((toUser:any) => toUser.id !== fromUser.id).map((toUser: any) => {
                return {
                  id: toUser.id,
                  firstName: toUser.first_name,
                  lastName: toUser.last_name,
                  profilePic: toUser.profile_picture,
                  isAdmin: !!res.data.admins.find((id: number) => id === toUser.id),
                }
              }),
            ),
          )
          onClose()
          successToast('Users added successfully.')
        }
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }
  return (
    <Box p={2}>
      <Typography variant='subtitle1' fontWeight={400} fontSize={24}>
        Add Connect to Group
      </Typography>

      <>
        <Typography variant='subtitle1' fontWeight={600} fontSize={16} color='gray'>
          Connections
        </Typography>
        <Box
          sx={{
            overflowY: 'scroll',
            height: 370,
          }}
        >
          {isFetchingConnections ? (
            <Grid container justifyContent='center'>
              <CircularProgress />
            </Grid>
          ) : (
            connectionsArr.map((connection) => (
              <ConnectionRow
                userId={connection.id}
                key={connection.id}
                name={connection.name}
                profilePic={connection.profile_picture}
                onClick={handleCheckboxClick}
                showCheckbox
                checked={selectedConnections.has(connection.id)}
              />
            ))
          )}
          {!isFetchingConnections && connectionsArr.length === 0 && (
            <Grid color='white' mt={3} container justifyContent='center'>
              No connection found
            </Grid>
          )}
        </Box>
      </>

      <Grid container>
        <Grid xs={6} p={1}>
          <Button
            onClick={handleSubmit}
            type='button'
            variant='contained'
            color='success'
            fullWidth
            sx={{ height: '48px' }}
          >
            Save
          </Button>
        </Grid>

        <Grid xs={6} p={1}>
          <Button
            onClick={onClose}
            type='button'
            variant='outlined'
            color='error'
            fullWidth
            sx={{ border: 'none', height: '48px' }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddConnectionToGroup
