import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import Header from 'src/Layout/HomeLayout/Header'
import RecentChats from './RecentChats'
import ChatWindow from './ChatWindow'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { endSingleOrGroupChat } from 'src/store/features/chat/chatSlice'
const ChatFeature = () => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    return () => {      
      dispatch(endSingleOrGroupChat())
    }
  }, [])
  return (
    <Box>
      <Header />

      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <RecentChats />

        <ChatWindow />
      </Box>
    </Box>
  )
}

export default ChatFeature
