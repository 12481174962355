import React, { useState } from 'react'
import { Box, Typography, Grid, Tabs, Tab, Divider, CircularProgress } from '@mui/material'
import Header from 'src/Layout/HomeLayout/Header'
import styles from './index.module.scss'

import AboutSection from '../../Component/UserProfile/AboutSection'
import ConnectionRequests from '../../Component/UserProfile/ConnectionRequests'
import UserPosts from '../../Component/UserProfile/UserPosts'
import BasicInfo from 'src/Component/UserProfile/BasicInfo'
import { useGetConnectionRequestQuery, useGetUserProfileByIdQuery } from 'src/api/userProfile'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'src/store'

import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'

const MyProfile = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(0)
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const { data: userProfileInfo, isLoading: isProfileInfoLoading } = useGetUserProfileByIdQuery(
    userInfo?.id || 0,
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const {
    data: connectionsList,
    isFetching: isConnectionListLoading,
    refetch: refetchConnectionList,
  } = useGetConnectionRequestQuery()

  const handleTabChange = (event: React.SyntheticEvent, newValue: 0 | 1 | 2) => {
    setActiveTab(newValue)
  }

  const tabs = ['About', 'Posts', 'Requests']

  if (isProfileInfoLoading || isConnectionListLoading) {
    return (
      <Box textAlign='center'>
        <CircularProgress />{' '}
      </Box>
    )
  }

  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Box mt={1}>
            <Typography variant='subtitle1' fontSize='24px'>
              Profile
            </Typography>
          </Box>
        </Box>
      </Header>

      <Box mt={3}>
        <Grid container justifyContent='center'>
          <Box className={styles.formWrapper}>
            <Box>
              {userProfileInfo && (
                <BasicInfo
                  userId={userInfo?.id || 0}
                  profilePic={userProfileInfo.selfie}
                  title={userProfileInfo.title}
                  firstName={userProfileInfo.first_name}
                  lastName={userProfileInfo.last_name}
                  email={userProfileInfo.email}
                  phone={userProfileInfo.phone}
                  isPublicProfile={userProfileInfo.is_public_profile}
                  isOwnProfile
                  isConnected={false}
                />
              )}

              <Box mt={2}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  centered
                  variant='fullWidth'
                  textColor='primary'
                  indicatorColor='secondary'
                >
                  {tabs.map((tab, i) => {
                    return (
                      <Tab
                        key={tab}
                        label={tab}
                        sx={{ color: activeTab === i ? '#FFFEFE !important' : '#909090' }}
                      />
                    )
                  })}
                </Tabs>
                <Divider />
              </Box>
            </Box>
            <Box>
              {activeTab === 0 && userProfileInfo && (
                <AboutSection
                  speciality={userProfileInfo.primary_speciality}
                  shortBio={userProfileInfo.short_bio}
                  medicalSchool={userProfileInfo.medical_school}
                  residency={userProfileInfo.residency}
                  boardCertification={{
                    name: userProfileInfo.board_certification_name || '',
                    // number: userProfileInfo.board_certification_number || '',
                  }}
                  fellowship={userProfileInfo.fellowship}
                />
              )}
              {activeTab === 1 && <UserPosts userId={userInfo?.id || 0} />}
              {activeTab === 2 && (
                <ConnectionRequests data={connectionsList} refetchData={refetchConnectionList} />
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default MyProfile
