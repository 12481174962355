import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import { Box, IconButton } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AppIcon from 'src/assets/images/AppIcon.png'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

type HeaderProps = {
  hideLogin?: boolean
  showBackButton?: boolean
}

function Header({ hideLogin = false, showBackButton = false }: HeaderProps) {
  const navigate = useNavigate()

  const goToLoginPage = () => {
    navigate(routeUrls.login)
  }
  return (
    <Box>
      <AppBar className={styles.header}>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1, paddingLeft: '40px' }}>
            <img src={AppIcon} alt='Icon' height={70} />
            {showBackButton && (
              <Box>
                <IconButton
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <KeyboardArrowLeft color='secondary' fontSize='large' />
                </IconButton>
              </Box>
            )}
          </Typography>
          {!hideLogin && (
            <Button
              onClick={goToLoginPage}
              type='button'
              sx={{ width: '150px' }}
              variant='contained'
              color='success'
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default Header
